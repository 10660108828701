import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Upload,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as OwnersActions from '@actions/owners';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwners: false,
    ownersById: {},
    errorfetchingOwners: null,
    createNewOwner: false,
    createNewOwnerIsCompany: null,

    fileList: [],
    ...state,
  };
}

class AddOwnerForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    ownershipOwnersIds: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
    ownershipOwnersIds: [],
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onOk = this.onOk.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwners: true });
      this.props.fetchOwners().then((responseData) => {
        this.setState({
          isFetchingOwners: false,
          ownersById: {
            ...ArrayUtils.arrayToObject(responseData.owners),
          },
        })
      }).catch((error) => {
        this.setState({
          isFetchingOwners: false,
          errorfetchingOwners: error,
        })
      })
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }


  onOk() {
    const { fileList } = this.state;
    this.props.form.setFieldsValue({
      br_file: (fileList.length > 0) ? fileList[0] : null,
    })
    this.props.onOk();
  }

  renderAddFromPortfolioForm() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 17, offset: 1 },
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label="Owner"
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("owner_id", {
              rules: [
                { required: true, message: "Please select owner!" },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder="Please select owner"
                loading={ this.state.isFetchingOwners }
                notFoundContent={
                  this.state.isFetchingOwners ? <Spin size="small" /> : null
                }
              >
                {
                  Object.values(this.state.ownersById).map((owner) => (
                    <Select.Option
                      key={ owner.id.toString() }
                      disabled={ this.props.ownershipOwnersIds.includes(owner.id) }
                    >
                      { owner.name }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
      </Form>
    )
  }

  renderCreateNewOwnerForm() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14, offset: 1 },
    };

    const items = [
      {
        label: "Name",
        shouldRender: true,
        render: () => getFieldDecorator("name", {
          initialValue: null,
          rules: [{ required: true, message: "Please input official owner name!" }],
        })(<Input placeholder="Name" />),
      }, {
        label: "Owner type",
        shouldRender: true,
        render: () => getFieldDecorator("is_company", {
          rules: [{ required: true, message: "Please select owner type!" }],
        })(
          <Radio.Group
            onChange={ (e) => this.setState({ createNewOwnerIsCompany: e.target.value }) }
          >
            <Radio key="false" value="false">
              Individual
            </Radio>
            <Radio key="true" value="true">
              Company
            </Radio>
          </Radio.Group>
        ),
      }, {
        label: "BR Number",
        shouldRender: (this.state.createNewOwnerIsCompany === "true"),
        render: () => getFieldDecorator("br_cert_number", {
          rules: [{ required: false, message: "Please input Business Registration number!" }],
        })(
          <Input placeholder="BR Number"/>
        ),
      }, {
        label: "BR Expiry Date",
        shouldRender: (this.state.createNewOwnerIsCompany === "true"),
        render: () => getFieldDecorator("br_expiry_date", {
          rules: [{ required: false, message: "Please input Business Registration expiration!" }],
        })(
          <DatePicker format={"YYYY/MM/DD"} />
        ),
      }, {
        label: "ID Number",
        shouldRender: (this.state.createNewOwnerIsCompany === "false"),
        render: () => getFieldDecorator("id_card_number", {
          rules: [{ required: false, message: "Please input ID number!" }],
        })(
          <Input placeholder="ID Number"/>
        ),
      }, {
        label: "Correspondence Address",
        shouldRender: true,
        render: () => getFieldDecorator("correspondence_address", {
          rules: [{ required: true, message: "Please input correspondence address!" }],
        })(
          <Input.TextArea rows={ 4 } placeholder="Correspondence Address" />
        ),
      }, {
        label: "Contact Details",
        shouldRender: true,
        render: () => getFieldDecorator("contact_details", {
          rules: [{ required: true, message: "Please input contact details!" }],
        })(
          <Input.TextArea rows={ 4 } placeholder="Contact Details" />
        ),
      }, {
        label: "Business Registration",
        shouldRender: (this.state.createNewOwnerIsCompany === "true"),
        render: () => (
          <div className="dropbox">
            {getFieldDecorator("br_file", {
              getValueFromEvent: this.normFile,
              rules: [
                { required: false, message: 'Please select images!' },
              ],
            })(
              <Upload
                multiple={ false }
                onRemove={
                  (file) => {
                    this.setState({ fileList: [] });
                  }
                }
                beforeUpload={
                  (file) => {
                    this.setState(state => ({
                      fileList: [file],
                    }));
                    return false;
                  }
                }
                fileList={ this.state.fileList }
                name="br_file"
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </Upload>
            )}
          </div>
        ),
      },
    ]

    return (
      <Form layout="vertical">
        {
          items.filter(({ shouldRender }) => shouldRender).map((item, index) => (
            <Form.Item
              key={ index.toString() }
              label={ item.label }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              { item.render() }
            </Form.Item>
          ))
        }
      </Form>
    )
  }

  renderForm() {
    return this.state.createNewOwner ? this.renderCreateNewOwnerForm() : this.renderAddFromPortfolioForm();
  }

  render() {
    return (
      <Modal
        visible={ this.props.visible }
        title="Add Registered Owner"
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm() }
        {
          this.state.createNewOwner ? <a onClick={ () => this.setState({ createNewOwner: false }) }>Go back</a> : <span>Don't see the owner? <a onClick={ () => this.setState({ createNewOwner: true }) }>Click Here</a></span>
        }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwners: (params) => {
      return (dispatch(OwnersActions.fetchOwners(params)));
    },
  };
}

const ConnectedAddOwnerForm = connect(mapStateToProps, mapDispatchToProps)(AddOwnerForm);

const WrappedAddOwnerForm = Form.create({
  name: "add_owner_form_in_modal"
})(ConnectedAddOwnerForm);

export default withTranslation('common')(WrappedAddOwnerForm);
