import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Col,
  Icon,
  message,
  Row,
  Table,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as DocumentsActions from '@actions/documents';
import CreateForm from '@components/Documents/CreateForm';
import UpdateForm from '@components/Documents/UpdateForm';
import DocumentsTable from '@components/Documents/List';
import FilterForm from '@components/Documents/FilterForm';
import DocumentShow from '@components/Documents/Show';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isCreating: false,
    isFetching: false,
    itemsById: {},
    errorCreating: null,
    errorFetching: null,

    // modals
    selectedItemId: null,
    createModalVisible: false,
    showUpdatingModal: false,

    // search params
    filteredInfo: {
      address: '',
      displayName: '',
      selectedDocumentTypes: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      columnKey: 'date',
      order: 'descend',
    },
    ...state,
  }
}

class Documents extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onDestroyDocument = this.onDestroyDocument.bind(this);
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.setState({ selectedItemId: null });
      this.fetchDocuments();
    }
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    }, this.fetchDocuments);
  }

  handleListChange = (pagination, filters, sorter) => {
    this.setState({
      pagination,
      sorter,
    }, this.fetchDocuments)
  }

  async fetchDocuments(params = {}) {
    if (this.props.ownershipId) {
      params = {
        ...params,
        ownership_id: this.props.ownershipId,
      }
    }
    this.setState({
      isFetching: true,
      itemsById: {},
      selectedItemId: null,
    });
    try {
      let responseData = await this.props.fetchDocuments({
        page: this.state.pagination.current,
        results: this.state.pagination.pageSize,
        sortField: this.state.sorter.columnKey,
        sortOrder: this.state.sorter.order,
        filter: this.state.filteredInfo,
        ...params,
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.documents),
        },
        pagination: {
          ...this.state.pagination,
          total: responseData.pagy ? responseData.pagy.count : responseData.documents.length,
        },
      });
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      });
    }
  }

  onSelectItem(itemId) {
    this.setState({ selectedItemId: itemId });
  }

  async onCreateSubmit() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      const ownershipId = this.props.ownershipId ? this.props.ownershipId : values["ownership_id"];
      if (!ownershipId) {
        return
      }
      this.setState({ isCreating: true });
      const responseData = await this.props.createDocument(ownershipId, values);
      const newDocument = responseData.document;
      this.setState({
        isCreating: false,
        createModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [newDocument.id]: newDocument,
        },
      });
      message.success(this.props.t("Documents.messages.AddDocument.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      });
      message.error(this.props.t("Documents.messages.AddDocument.Fail"));
      return null
    }
  }

  async onUpdateSubmit() {
    let document = this.getSelectedDocument();
    if (!document) {
      return
    }
    const { form } = this.updateFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [document.id]: {
            ...document,
            isUpdating: true,
          },
        },
      });
      const responseData = await this.props.updateDocument(document.id, values);
      const updatedDocument = responseData.document;
      this.setState({
        showUpdatingModal: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedDocument.id]: updatedDocument,
        },
      });
      message.success(this.props.t("Documents.messages.EditDocument.Success"));
      return responseData;
    } catch (error) {
      document = this.getSelectedDocument();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [document.id]: {
            ...document,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      message.error(this.props.t("Documents.messages.EditDocument.Fail"));
      return;
    }
  }

  onDestroyDocument(document) {
    this.setState({
      itemsById: {
        ...this.state.itemsById,
        [document.id]: {
          ...this.state.itemsById[document.id],
          isDestroying: true,
          errorDestroying: null,
        },
      },
    });

    return this.props.destroyDocument(document.id, document.ownership_id).then((responseData) => {
      let itemsById = { ...this.state.itemsById };
      delete itemsById[document.id];
      this.setState({
        itemsById,
      })
      message.success(`${this.props.t("Documents.messages.DeleteDocument.Success")}: ${document.doc_type_display_name}`)
      return responseData;
    }).catch((error) => {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [document.id]: {
            ...this.state.itemsById[document.id],
            isDestroying: false,
            errorDestroying: error,
          },
        },
      });
      message.error(`${this.props.t("Documents.messages.DeleteDocument.Fail")}: ${document.doc_type_display_name}`)
    })
  }

  getSelectedDocument() {
    return this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
  }

  renderShow() {
    const document = this.getSelectedDocument();
    if (!document) {
      return null;
    }

    return (
      <Table
        columns={[{
          title: this.props.t("Documents.Details"),
          dataIndex: 'dummyVal',
          key: 'dummyVal',
          align: 'center',
          filterDropdown: ({ setSelectedKeys }) => (<div></div>),
          filterIcon: filtered => <Icon type="close" />,
          onFilter: (value, record) => true,
          onFilterDropdownVisibleChange: (visible) => this.setState({ selectedItemId: null }),
        }]}
        dataSource={ [] }
        locale={{ emptyText: (
          <DocumentShow
            document={ document }
            onClickEdit={ () => this.setState({ showUpdatingModal: true }) }
            onDestroy={ () => this.onDestroyDocument(document) }
          />
        )}}
      />
    )
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  render() {
    const dataSource = Object.values(this.state.itemsById);
    const showComponent = this.renderShow();
    const showPropertyName = !this.props.ownershipId;
    const selectedDocument = this.getSelectedDocument();
    return (
      <div>
        <CreateForm
          wrappedComponentRef={ this.saveCreateFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false })}
          onOk={ this.onCreateSubmit }
          ownershipId={ this.props.ownershipId }
          confirmLoading={ this.state.isCreating }
        />
        <UpdateForm
          wrappedComponentRef={ this.saveUpdateFormRef }
          document={ selectedDocument }
          visible={ !!selectedDocument && this.state.showUpdatingModal }
          onCancel={ () => this.setState({ showUpdatingModal: false }) }
          onOk={ this.onUpdateSubmit }
          confirmLoading={
            (selectedDocument && selectedDocument.isUpdating)
          }
        />
        <FilterForm
          showPropertyName={ showPropertyName }
          filteredInfo={ this.state.filteredInfo }
          loading={ false }
          onSubmit={ this.handleFilterFormSubmit }
        />
        <Button
          type="primary" icon="file-add"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ createModalVisible: true })}
        >
          { this.props.t("Documents.actions.AddDocument") }
        </Button>
        <Row>
          <Col span={ (showComponent === null) ? 24 : 15 }>
            <DocumentsTable
              showPropertyName={ showPropertyName }
              loading={ this.state.isFetching }
              dataSource={ dataSource }
              pagination={{
                ...this.state.pagination,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                showSizeChanger: true,
              }}
              filteredInfo={ this.state.filteredInfo }
              sorter={ this.state.sorter }
              onChange={ this.handleListChange }
              onRow={ (record, rowIndex) => {
                return {
                  onClick: (event) => this.setState({ selectedItemId: record.id }),
                }
              }}
            />
          </Col>
          <Col span={ (showComponent === null) ? 0 : (24 - 15) }>
            { showComponent }
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    createDocument: (ownershipId, values) => {
      return dispatch(DocumentsActions.createDocument(ownershipId, values));
    },
    updateDocument: (documentId, params = {}) => {
      const { date, ...rest } = params;
      return dispatch(DocumentsActions.updateDocument(documentId, {
        ...rest,
        date: date ? date.format('YYYY-MM-DD') : null,
      }));
    },
    fetchDocuments: (params = {}) => {
      return dispatch(DocumentsActions.fetchDocuments(params));
    },
    destroyDocument: (documentId, ownershipId) => {
      return dispatch(DocumentsActions.destroyDocument(documentId, ownershipId));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Documents));
