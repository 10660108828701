import React from 'react';

import ReactQuill, { Quill } from 'react-quill';
let Block = Quill.import("blots/block")
Block.tagName = "DIV"
Quill.register(Block, true)

import 'react-quill/dist/quill.snow.css';

export default class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactQuill
        { ...this.props }
      />
    )
  }
}
