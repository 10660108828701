import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';

import {
  ThemeColors,
} from '@constants/Theme';

const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDirty: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
    this.checkBoxIsTrue = this.checkBoxIsTrue.bind(this);
    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
    this.validateToNextPassword = this.validateToNextPassword.bind(this);
  }

  handleSubmit(e) {
    this.setState({
      isLoading: true,
    });
    this.props.form.validateFields((err, values) => {
      if (err) {
        e.preventDefault();
        this.setState({
          isLoading: false,
        });
        return;
      }
    });
  }

  onChangeText(key, e) {
    this.setState({
      [key]: e.target.value,
    })
  }

  handleConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords do not match!');
    } else {
      callback();
    }
  }

  validateToNextPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  }

  checkBoxIsTrue(rule, value, callback) {
    if (!value) {
      callback('Please agree to the agreement');
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form onSubmit={this.handleSubmit} action="/users" method="POST">
        <input type="hidden" name="authenticity_token" value={ csrfToken } />
        <input type="hidden" name="user[email]" value={ this.state.email } />
        <input type="hidden" name="user[password]" value={ this.state.password } />
        <input type="hidden" name="user[password_confirmation]" value={ this.state.password_confirmation } />
        <input type="hidden" name="user[first_name]" value={ this.state.first_name } />
        <input type="hidden" name="user[last_name]" value={ this.state.last_name } />
        <input type="hidden" name="user[phone_number]" value={ this.state.phone_number } />

        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="E-mail"
        >
          {getFieldDecorator('email', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please input your email!' },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { type: 'email', message: 'Please input a valid email!' },
              ],
            }],
          })(
            <Input
              size="large"
              onChange={ (e) => this.onChangeText('email', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="Password"
        >
          {getFieldDecorator('password', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please input your password!' },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { min: 6, message: 'Password must be at least 6 characters!' },
                { validator: this.validateToNextPassword },
              ],
            }],
          })(
            <Input
              type="password"
              onChange={ (e) => this.onChangeText('password', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="Confirm Password"
        >
          {getFieldDecorator('password_confirmation', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please confirm your password!', },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { validator: this.compareToFirstPassword },
              ],
            }],
          })(
            <Input
              type="password"
              onBlur={this.handleConfirmBlur}
              onChange={ (e) => this.onChangeText('password_confirmation', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="First name"
        >
          {getFieldDecorator('first_name', {
            rules: [{
              required: true, message: 'Please input your first name.',
            }],
          })(
            <Input
              onChange={ (e) => this.onChangeText('first_name', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="Last name"
        >
          {getFieldDecorator('last_name', {
            rules: [{
              required: true, message: 'Please input your last name.',
            }],
          })(
            <Input
              onChange={ (e) => this.onChangeText('last_name', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          {...formItemLayout}
          label="Phone Number"
        >
          {getFieldDecorator('phone_number', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
          })(
            <Input
              onChange={ (e) => this.onChangeText('phone_number', e) }
            />
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          {getFieldDecorator('agreement', {
            valuePropName: 'checked',
            rules: [
              { validator: this.checkBoxIsTrue },
            ],
          })(
            <Checkbox>I have read the <a href="https://www.mypropty.com/privacy-policy">agreement</a></Checkbox>
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={ this.state.isLoading }>Register</Button>
        </Form.Item>
        <Row type="flex" justify="space-between">
          <Link to="/login">Login</Link>
          <Link to="/forgot_password">Forgot password</Link>
        </Row>
      </Form>
    );
  }
}

const WrappedSignUpForm = Form.create({ name: 'sign_up' })(SignUpForm);

export default class SignUpScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row
        type="flex" justify="center" align="middle"
        style={{ height: '100%', marginTop: 30, marginBottom: 30 }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 20, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          lg={{ span: 12, offset: 0 }}
          style={{
            backgroundColor: 'white',
            borderColor: ThemeColors.darkBlue,
            borderStyle: 'solid',
            borderWidth: 1,
            padding: 30,
          }}
        >
          <h2>Sign up</h2>
          <WrappedSignUpForm />
        </Col>
      </Row>
    )
  }
}
