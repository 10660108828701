export const ThemeColors = {
  black: 'rgba(0, 0, 0, .65)',
  green: '#3f8600',
  red: '#cf1322',
  orange: 'rgb(248, 172, 47)',
  babyBlue: 'rgb(230, 247, 255)', // matches selected blue color
  darkBlue: 'rgb(1, 21, 40)', // matches the header
}

export const LayoutStyle = {
  routerContentStyle: {
    height: '100vh',
    backgroundColor: 'rgb(238, 240, 244)',
  },
  siderTitle: {
    paddingLeft: 24,
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 0,
  },
}
