import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  message,
} from 'antd';

import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as TasksActions from '@actions/tasks';
import TasksList from '@components/Tasks/List';
import TaskShow from '@components/Tasks/Show';
import CreateForm from '@components/Tasks/CreateForm';
import UpdateForm from '@components/Tasks/UpdateForm';
import FilterForm from '@components/Tasks/FilterForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isCreating: false,
    isFetching: false,
    itemsById: {},
    errorCreating: null,
    errorFetching: null,

    // modals
    selectedItemId: null,
    createModalVisible: false,

    // search params
    filteredInfo: {
      status: 'pending',
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
    },
    ...state,
  }
}

class Tasks extends React.Component {
  static proptypes = {
    ownershipId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchTasks = this.fetchTasks.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onDeleteTaskAttachment = this.onDeleteTaskAttachment.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchTasks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.fetchTasks();
    }
  }

  handleListChange = (pagination, filters, sorter) => {
    this.setState({
      pagination,
      sorter,
    }, this.fetchTasks)
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    }, this.fetchTasks);
  }

  async fetchTasks(params = {}) {
    if (this.props.ownershipId) {
      params = {
        ...params,
        ownership_id: this.props.ownershipId,
      };
    }
    this.setState({ isFetching: true });
    try {
      let responseData = await this.props.fetchTasks({
        page: this.state.pagination.current,
        results: this.state.pagination.pageSize,
        sortField: this.state.sorter.columnKey,
        sortOrder: this.state.sorter.order,
        filter: this.state.filteredInfo,
        ...params,
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.tasks),
        },
      })
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      });
      return null;
    }
  }

  getSelectedTask() {
    const { selectedItemId, itemsById } = this.state;
    if (!selectedItemId) {
      return null
    }
    const selectedItem = itemsById[selectedItemId];
    return selectedItem ? selectedItem : null;
  }

  async onCreateSubmit() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      const ownershipId = this.props.ownershipId ? this.props.ownershipId : values["ownership_id"];
      if (!ownershipId) {
        return
      }
      this.setState({ isCreating: true });
      let responseData = await this.props.createTask(ownershipId, values);
      const newTask = responseData.task;
      this.setState({
        isCreating: false,
        createModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [newTask.id]: newTask,
        },
      });
      message.success(this.props.t("Tasks.messages.CreateTask.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      });
      return null;
    }
  }

  async onUpdateSubmit() {
    const { form } = this.updateFormRef.props;
    let selectedTask = this.getSelectedTask();
    if (!selectedTask) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedTask.id]: {
            ...selectedTask,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.updateTask(selectedTask.id, selectedTask.ownership_id, values);
      const updatedTask = responseData.task;
      this.setState({
        selectedItemId: null,
        itemsById: {
          ...this.state.itemsById,
          [updatedTask.id]: updatedTask,
        },
      });
      message.success(this.props.t("Tasks.messages.UpdateTask.Success"));
      return responseData;
    } catch (error) {
      selectedTask = this.getSelectedTask();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedTask.id]: {
            ...selectedTask,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      return null;
    }
  }

  async onDestroyTask(taskId) {
    const task = this.state.itemsById[taskId];
    if (!task) {
      return;
    }
    try {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [taskId]: {
            ...task,
            isDestroying: true,
          },
        },
      })
      let responseData = await this.props.destroyTask(taskId);
      let itemsById = { ...this.state.itemsById };
      delete itemsById[taskId];
      this.setState({
        itemsById,
      })
      message.success(`${this.props.t("Tasks.messages.DeleteTask.Success")}: ${task.name}`);
      return responseData;
    } catch (error) {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [taskId]: {
            ...task,
            isDestroying: false,
            errorDestroying: error,
          },
        },
      });
      message.error(`${this.props.t("Tasks.messages.DeleteTask.Fail")}: ${task.name}`)
      return
    }
  }

  async onDeleteTaskAttachment(taskId, ownershipId, attachmentId) {
    const task = this.state.itemsById[taskId];
    if (!task) {
      return;
    }
    try {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [taskId]: {
            ...task,
            isUpdating: true,
          },
        },
      })
      let responseData = await this.props.deleteTaskAttachment(taskId, ownershipId, attachmentId);
      const updatedTask = responseData.task;
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [updatedTask.id]: updatedTask,
        },
      });
      message.success(this.props.t("Tasks.messages.DeleteTaskAttachment.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [taskId]: {
            ...task,
            isUpdating: false,
            updateError: error,
          },
        },
      });
      message.error(this.props.t("Tasks.messages.DeleteTaskAttachment.Fail"));
      return null;
    }
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  getFilteredResults(items) {
    const { filteredInfo } = this.state;
    return items.filter((item) => {
      if (filteredInfo && filteredInfo.status) {
        if (item.status !== filteredInfo.status) {
          return false;
        }
      }
      return true;
    })
  }

  render() {
    const selectedTask = this.getSelectedTask();
    const dataSource = this.getFilteredResults(Object.values(this.state.itemsById));
    return (
      <div>
        <CreateForm
          wrappedComponentRef={ this.saveCreateFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false })}
          onOk={ this.onCreateSubmit }
          ownershipId={ this.props.ownershipId }
          confirmLoading={ this.state.isCreating }
        />
        <UpdateForm
          wrappedComponentRef={ this.saveUpdateFormRef }
          task={ selectedTask }
          visible={ !!selectedTask }
          onCancel={ () => this.setState({ selectedItemId: null }) }
          onOk={ this.onUpdateSubmit }
          confirmLoading={
            (selectedTask && selectedTask.isUpdating)
          }
        />
        <FilterForm
          filteredInfo={ this.state.filteredInfo }
          loading={ this.state.isFetching }
          onSubmit={ this.handleFilterFormSubmit }
        />
        <Button
          type="primary" icon="plus"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ createModalVisible: true, selectedItemId: null })}
        >
          { this.props.t("Tasks.actions.CreateTask") }
        </Button>
        {
          this.state.errorFetching && (<span style={{ color: 'red' }}>Unable to retrieve tasks. <a onClick={ () => this.fetchTasks() }>Try again.</a></span>)
        }
        <TasksList
          tableLayout={ 'fixed' }
          showPropertyName={ !this.props.ownershipId }
          expandedRowRender={
            record => (
              <TaskShow
                task={ record }
                deleteTaskAttachment={ (attachmentId) => this.onDeleteTaskAttachment(record.id, record.ownership_id, attachmentId) }
              />
            )
          }
          loading={ this.state.isFetching }
          dataSource={ dataSource }
          pagination={ this.state.pagination }
          sorter={ this.state.sorter }
          onChange={ this.handleListChange }
          onSelectUpdateItem={ (record) => this.setState({ createModalVisible: false, selectedItemId: record.id }) }
          onDestroyTask={ (record) => this.onDestroyTask(record.id) }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchTasks: (params = {}) => {
      return dispatch(TasksActions.fetchTasks(params))
    },
    createTask: (ownershipId, params) => {
      const { files, deadline, ...rest } = params;
      return dispatch(TasksActions.createTask(ownershipId, {
        ...rest,
        deadline: deadline ? deadline.format('YYYY-MM-DD') : null,
        'files[]': files,
      }));
    },
    updateTask: (taskId, ownershipId, params) => {
      const { files, deadline, ...rest } = params;
      return dispatch(TasksActions.updateTask(taskId, ownershipId, {
        ...rest,
        deadline: deadline ? deadline.format('YYYY-MM-DD') : null,
        'files[]': files,
      }));
    },
    destroyTask: (taskId) => {
      return dispatch(TasksActions.destroyTask(taskId));
    },
    deleteTaskAttachment: (taskId, ownershipId, attachmentId) => {
      return dispatch(TasksActions.deleteTaskAttachment(taskId, ownershipId, attachmentId));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Tasks));
