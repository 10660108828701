import * as ActionTypes from '@actions/types';
import * as ArrayUtils from '@utils/array';

export const initialState = {
  isAuthenticated: false,
  me: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case `${ActionTypes.UPDATE_USER_PROFILE}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
      };
    case `${ActionTypes.UPDATE_USER_PROFILE}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        me: action.responseData.me,
      };
    case `${ActionTypes.UPDATE_USER_PROFILE}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
      };
    default:
      return state;
  }
}
