import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from "antd";

import moment from "moment";
import { withTranslation } from 'react-i18next';

import * as DocumentActions from '@actions/ownerships';

class UpdateForm extends React.Component {
  static propTypes = {
    document: PropTypes.shape({
      id: PropTypes.number.isRequired,
      date: PropTypes.string,
      description: PropTypes.string,
    }),
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      return
    }
  }

  renderForm() {
    const { document } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 }
    };
    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          { document.ownership_name }
          <br />
          { document.ownership_address }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentType") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('doc_type', {
            initialValue: document.type,
            rules: [{
              required: true,
              message: `${ this.props.t("Documents.messages.Form.PleaseSelectDocumentType") }!`,
            }],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={ this.props.t("Documents.messages.Form.PleaseSelectDocumentType") }
            >
              {
                Object.values(this.props.DocumentTypeOptions).sort((a, b) => (a.text.localeCompare(b.text))).map((docTypeOption) => (
                  <Select.Option
                    key={ docTypeOption.value }
                  >
                    { docTypeOption.text }
                  </Select.Option>
                ))
              }
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentName") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('doc_name', {
            initialValue: document.doc_name,
            rules: [
              { required: false, message: `${ this.props.t("Documents.messages.Form.PleaseInputDocumentName") }!` },
            ],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentDate") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('date', {
            initialValue: document.date ? moment(document.date) : null,
            rules: [
              { required: false, message: `${ this.props.t("Documents.messages.Form.PleaseSelectDocumentDate") }!` },
            ],
          })(
            <DatePicker format={"YYYY/MM/DD"} />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Description") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('description', {
            initialValue: document.description,
            rules: [
            ],
          })(
            <Input.TextArea rows={ 4 } placeholder={ this.props.t("shared.Description") } />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { document } = this.props;
    if (!document) {
      return null;
    }

    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.t("Documents.actions.EditDocument") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.props.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm() }
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    DocumentTypeOptions: state.documentsReducer.DocumentTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedUpdateForm = connect(mapStateToProps, mapDispatchToProps)(UpdateForm);

const WrappedUpdateForm = Form.create({
  name: "documents_update_form_in_modal"
})(ConnectedUpdateForm);

export default withTranslation('common')(WrappedUpdateForm);
