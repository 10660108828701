import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Checkbox,
  Form,
  Icon,
  Input,
  Row,
} from 'antd';

import {
  ThemeColors,
} from '@constants/Theme';

const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: '',
      password: '',
      remember_me: true,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
  }

  handleSubmit(e) {
    this.setState({
      isLoading: true,
    });
    this.props.form.validateFields((err, values) => {
      if (err) {
        e.preventDefault();
        this.setState({
          isLoading: false,
        });
        return;
      }
    });
  }

  onChangeText(key, e) {
    this.setState({
      [key]: e.target.value,
    })
  }

  onChangeCheckbox(key, e) {
    this.setState({
      [key]: e.target.checked,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const redirect = `/users/sign_in?${ this.props.locationState ? `redirect=${this.props.locationState.from.pathname}` : '' }`;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form" action={redirect} method="POST">
        <input type="hidden" name="authenticity_token" value={ csrfToken } />
        <input type="hidden" name="user[email]" value={ this.state.email } />
        <input type="hidden" name="user[password]" value={ this.state.password } />
        <input type="hidden" name="user[remember_me]" value={ this.state.remember_me ? "1" : "0" } />

        <Form.Item
          hasFeedback
        >
          {getFieldDecorator('email', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please input your email!' },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { type: 'email', message: 'Please input a valid email!' },
              ],
            }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              size="large"
              placeholder="Email"
              onChange={ (e) => this.onChangeText('email', e) }
            />
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password!' }],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              size="large"
              type="password"
              placeholder="Password"
              onChange={ (e) => this.onChangeText('password', e) }
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: this.state.remember_me,
          })(
            <Checkbox
              onChange={ (e) => this.onChangeCheckbox('remember_me', e) }
            >
              Remember me
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={ this.state.isLoading }>
            Log in
          </Button>
        </Form.Item>
        <Row type="flex" justify="space-between">
          <Link to="/sign_up">Sign up</Link>
          <Link to="/forgot_password">Forgot password</Link>
        </Row>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

export default class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const locationState = this.props.location.state
    return (
      <Row
        type="flex" justify="center" align="middle"
        style={{ height: '90%' }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 20, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          lg={{ span: 12, offset: 0 }}
          style={{
            backgroundColor: 'white',
            borderColor: ThemeColors.darkBlue,
            borderStyle: 'solid',
            borderWidth: 1,
            padding: 30,
          }}
        >
          <h2>Log in</h2>
          <WrappedLoginForm locationState={locationState} />
        </Col>
      </Row>
    )
  }
}
