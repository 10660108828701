import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  Icon,
  Menu,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';

import { ThemeColors } from '@constants/Theme';
import * as DateUtils from '@utils/date';

class AccountEntriesTable extends React.Component {
  static propTypes = {
    showPropertyName: PropTypes.bool,
    onItemAction: PropTypes.func
  }

  static defaultProps = {
    showPropertyName: false,
    onItemAction: (record, actionKey) => {},
  }

  constructor(props) {
    super(props);
  }

  renderActionMenu(record) {
    let items = [{
      key: "addPayment",
      shouldRender: (record) => (record.reconciled_amount !== record.amount),
      renderItem: (record) => <span>{ this.props.t("AccountEntries.actions.AddPayment") }</span>,
    }, {
      key: "attachDocument",
      shouldRender: (record) => true,
      renderItem: (record) => <span>{ this.props.t("Documents.actions.AttachDocument") }</span>,
    }, {
      key: "previewInvoice",
      shouldRender: (record) => record.preview_invoice_url,
      renderItem: (record) => <span>{ this.props.t("AccountEntries.actions.PreviewInvoice") }</span>,
    }, {
      key: "sendInvoice",
      shouldRender: (record) => false && record.can_send_invoice,
      renderItem: (record) => <span>Send Invoice</span>,
    }, {
      key: "previewOutstandingRentalReminder",
      shouldRender: (record) => record.preview_rental_reminder_url,
      renderItem: (record) => <span>{ this.props.t("AccountEntries.actions.PreviewRentReminder") }</span>,
    }, {
      key: "sendPaymentReminder",
      shouldRender: (record) => false && record.can_send_payment_reminder,
      renderItem: (record) => (
        <Tooltip placement="left" title="Send a whatsapp reminder to tenant">
          <span>Send Payment Reminder</span>
        </Tooltip>
      ),
    }, {
      key: "markAsVoid",
      shouldRender: (record) => (record.state === "unpaid"),
      renderItem: (record) => <span>{ this.props.t("AccountEntries.actions.MarkAsVoid") }</span>,
      disabled: (record.reconciled_amount !== 0),
    }, {
      key: "previewReceipt",
      shouldRender: (record) => record.preview_receipt_url,
      renderItem: (record) => <span>{ this.props.t("AccountEntries.actions.PreviewReceipt") }</span>,
    }, {
      key: "sendReceipt",
      shouldRender: (record) => false && (record.can_send_receipt),
      renderItem: (record) => <span>Send Receipt</span>,
    }];


    return (
      <Menu
        onClick={ ({ key }) => this.props.onItemAction(record, key) }
      >
        {
          items.filter(({ shouldRender }) => !!shouldRender(record)).map(({ key, renderItem, disabled = false }) => (
            <Menu.Item key={ key } disabled={ disabled }>
              { renderItem(record ) }
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  render() {
    const { sorter, pagination, filteredInfo, ...rest } = this.props;
    let columns = [{
      title: this.props.t("shared.Date"),
      dataIndex: 'due_date',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.due_date !== b.due_date) {
          return (moment(a.due_date) - moment(b.due_date));
        }
        const aStage = this.props.AccountEntryStatusOptions[a.state].stage || 0;
        const bStage = this.props.AccountEntryStatusOptions[b.state].stage || 0;
        return (aStage - bStage);
      },
      sortOrder: sorter.columnKey === 'date' && sorter.order,
      render: (date, record) => {
        const dueDate = DateUtils.formatDate(date);
        const periodStart = record.period_start_date ? DateUtils.formatDate(record.period_start_date) : null;
        const periodEnd = record.period_end_date ? DateUtils.formatDate(record.period_end_date) : null;
        return (
          <div>
            { DateUtils.formatDate(date) }
            <br></br>
            <span style={{ fontSize: 10 }}>{ periodStart && periodEnd && `${periodStart} - ${periodEnd}`}</span>
          </div>
        )
      },
    }, {
      title: this.props.t("shared.Type"),
      dataIndex: 'entry_type',
      key: 'type',
      render: (type) => this.props.AccountEntryTypeOptions[type].text,
    }, {
      title: this.props.t("shared.Amount"),
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => {
        if (a.amount !== b.amount) {
          return (a.amount - b.amount)
        }
        const aStage = this.props.AccountEntryStatusOptions[a.state].stage || 0;
        const bStage = this.props.AccountEntryStatusOptions[b.state].stage || 0;
        return (aStage - bStage);
      },
      sortOrder: sorter.columnKey === 'amount' && sorter.order,
      render: (amount, record) => {
        const color = (amount < 0) ? ThemeColors.red : ThemeColors.green;
        const outstandingAmount = (amount - record.reconciled_amount);
        return (
          <div>
            <p style={{ color, marginBottom: 0 }}>
              { numeral(amount).format('($0,0.[00])') }
            </p>
            {
              (outstandingAmount !== 0) ? (
                <p style={{ color, marginBottom: 0, fontSize: 10 }}>
                  { this.props.t("AccountEntries.OutstandingAmount") }: { numeral(outstandingAmount).format('($0,0.[00])') }
                </p>
              ) : null
            }
          </div>
        )
      }
    }, {
      title: this.props.t("shared.Status"),
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => {
        const aStage = this.props.AccountEntryStatusOptions[a.state].stage || 0;
        const bStage = this.props.AccountEntryStatusOptions[b.state].stage || 0;
        if (aStage !== bStage) {
          return (aStage - bStage);
        }
        return (moment(b.due_date) - moment(a.due_date))
      },
      sortOrder: sorter.columnKey === 'state' && sorter.order,
      render: (state, record) => {
        const aeStatusObject = this.props.AccountEntryStatusOptions[state];
        const color = aeStatusObject.color || 'red';
        const dueDate = moment(record.due_date);
        const now = moment();

        const invoicedSavedTag = (record.invoice_saved_at) ? (
          <React.Fragment>
            <br></br>
            <Tooltip placement="top" title={ `D/N: ${record.full_invoice_number}` }>
              <Tag color='blue'>
                { this.props.t("AccountEntries.InvoiceSaved") }
              </Tag>
            </Tooltip>
          </React.Fragment>
        ) : null
        const receiptedSavedTag = (record.receipt_saved_at) ? (
          <React.Fragment>
            <br></br>
            <Tooltip placement="top" title={ `D/N: ${record.full_invoice_number}` }>
              <Tag color='purple'>
                { this.props.t("AccountEntries.ReceiptSaved") }
              </Tag>
            </Tooltip>
          </React.Fragment>
        ) : null
        return (
          <div>
            <div style={{ display: 'inline-flex' }}>
              <Tag color={ color }>
                { this.props.t(aeStatusObject.ttextKey) }
              </Tag>
              {
                ((aeStatusObject.value == 'unpaid') && (dueDate < now)) ? (
                  <p style={{ margin: 0, color: color }}>
                    { now.to(dueDate, true) }
                  </p>
                ) : null
              }
            </div>
            { invoicedSavedTag }
            { receiptedSavedTag }
          </div>
        )
      },
    }, {
      title: this.props.t("shared.Action"),
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={ () => this.renderActionMenu(record) }>
          <Button>
            <Icon type="menu" /><Icon type="down" />
          </Button>
        </Dropdown>
      ),
    }];

    if (this.props.showPropertyName) {
      columns.splice(1, 0, {
        title: this.props.t("Properties.Property"),
        dataIndex: 'ownership.name',
        key: 'property',
        // TODO: https://ant.design/components/table/
        // customized filter panel (allows you to use a search input)
        render: (name, record)  => (
          <Link to={ `/properties/${record.ownership_id}` }>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[ (filteredInfo && filteredInfo.displayName) ? filteredInfo.displayName : '' ]}
              autoEscape
              textToHighlight={ record.ownership.name }
            />
          </Link>
        ),
      });
    }

    return (
      <Table
        rowKey={ record => record.id.toString() }
        columns={ columns }
        sorter={ sorter }
        pagination={{
          ...pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: true,
        }}
        { ...rest }
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryStatusOptions: state.accountEntriesReducer.AccountEntryStatusOptions,
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AccountEntriesTable));
