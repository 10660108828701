import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchListings = (params = {}) => {
  return Api.get(
    'listings',
    ActionTypes.FETCH_LISTINGS,
    params,
  )
}

export const createListing = (ownershipId, params = {}) => {
  return Api.postUpload(
    'listings',
    ActionTypes.CREATE_LISTING,
    {
      ...params,
      ownership_id: ownershipId,
    },
    {
      ownershipId,
    }
  )
}

export const broadcastListing = (listingId, params = {}) => {
  return Api.post(
    `listings/${listingId}/broadcast_listing`,
    ActionTypes.BROADCAST_LISTING,
    params,
    {},
  )
}

export const updateListing = (listingId, params = {}) => {
  return Api.putUpload(
    `listings/${listingId}`,
    ActionTypes.UPDATE_LISTING,
    params,
    {},
  )
}

export const closeListing = (listingId, params = {}) => {
  return Api.put(
    `listings/${listingId}/close_listing`,
    ActionTypes.CLOSE_LISTING,
    params,
    {},
  )
}
