import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import { ThemeColors } from '@constants/Theme';
import * as OwnershipActions from '@actions/ownerships';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnerships: false,
    ownershipsById: {},
    errorFetchingOwnerships: null,
    selectedType: null,
    ...state,
  };
}

class CreateForm extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwnerships: true });
      let params = (this.props.ownershipId) ? { ownership_id: this.props.ownershipId } : {};
      this.props.fetchOwnershipsWithManagers(params).then((responseData) => {
        this.setState({
          isFetchingOwnerships: false,
          ownershipsById: {
            ...ArrayUtils.arrayToObject(responseData.ownerships),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnerships: false,
          errorFetchingOwnerships: error,
        })
      });
    }
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    return (
      <Form>
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("ownership_id", {
              rules: [{
                required: true,
                message: `${ this.props.t("Properties.messages.Form.PleaseSelectProperty") }!`,
              }],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("Properties.messages.Form.PleaseSelectProperty") }
                loading={ this.state.isFetchingOwnerships }
                showSearch={ (Object.values(this.state.ownershipsById).length > 5) }
                filterOption={ (inputValue, option) => {
                  const ownershipId = parseInt(option.key);
                  const ownership = this.state.ownershipsById[ownershipId];
                  if (ownership.name.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  if (ownership.address.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  return false;
                }}
                notFoundContent={
                  this.state.isFetchingOwnerships ? <Spin size="small" /> : null
                }
              >
                {
                  Object.values(this.state.ownershipsById).map((ownership) => (
                    <Select.Option
                      key={ ownership.id.toString() }
                    >
                      { ownership.name }. { ownership.address }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.EntryType") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("entry_type", {
            rules: [{
              required: true,
              message: `${ this.props.t("messages.Form.PleaseSelectType") }!`,
            }]
          })(
            <Select
              style={{ width: "100%" }}
              placeholder={ this.props.t("messages.Form.PleaseSelectType") }
              onSelect={ (key) => {
                this.setState({ selectedType: this.props.AccountEntryTypeOptions[key] })
              }}
            >
              {Object.values(this.props.AccountEntryTypeOptions).filter((option) => !option.belongs_to_tenancy).map(entryType => (
                <Select.Option key={entryType.value}>
                  {entryType.text}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.Direction") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('direction', {
            rules: [{
              required: true,
              message: `${ this.props.t("AccountEntries.messages.Form.PleaseInputPaymentDirection") }!`,
            }],
          })(
            <Radio.Group buttonStyle='solid'>
              <Radio.Button value="1">{ this.props.t("AccountEntries.IncomeReceivable") }</Radio.Button>
              <Radio.Button value="-1">{ this.props.t("AccountEntries.ExpensePayable") }</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Amount") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('amount', {
            rules: [{
              required: true,
              message: `${ this.props.t("AccountEntries.messages.Form.PleaseInputAmount") }!`,
            }],
          })(
            <InputNumber
              style={{ width: '100%', minWidth: '150px' }}
              step={ 0.01 }
              min={ 0 }
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.DueDate") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("due_date", {
            rules: [{
              required: true,
              message: `${ this.props.t("AccountEntries.messages.Form.PleaseInputDueDate") }!`,
            }],
          })(
            <DatePicker format={"YYYY/MM/DD"} />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.Period") }
          style={{ margin: 0, paddingBottom: 14 }}
           {...formItemLayout}
        >
          {getFieldDecorator('account_period', {
            rules: [{
              type: 'array',
              required: this.state.selectedType && this.state.selectedType.require_period,
              message: `${ this.props.t("AccountEntries.messages.Form.PleaseSelectPeriod") }!`,
            }],
          })(
            <DatePicker.RangePicker />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Notes") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('notes', {
          })(
            <Input.TextArea rows={ 5 } />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.t("AccountEntries.actions.AddAccountEntry") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.props.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm() }
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipsWithManagers: (params) => {
      return (dispatch(OwnershipActions.fetchOwnershipsWithManagers(params)));
    },
  };
}

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

const WrappedCreateForm = Form.create({
  name: "account_entry_creation_form_in_modal"
})(ConnectedCreateForm);

export default withTranslation('common')(WrappedCreateForm);
