import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';

import { withTranslation } from 'react-i18next';

import '@components/FilterForm.scss';
import * as ArrayUtils from '@utils/array';
import * as PropertyAgentActions from '@actions/propertyAgents';

const RESET_STATE = (state = {}) => {
  return {
    isFetching: false,
    itemsById: {},
    errorFetching: null,

    selectedRowKeys: [],
    remarksOption: null,

    filteredInfo: {
      companyName: '',
      selectedNeighbourhoods: [],
    },

    ...state,
  }
}

class WhatsappBroadcastForm extends React.Component {
  static propTypes = {
    listing: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchPropertyAgents = this.fetchPropertyAgents.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.getFilteredResults = this.getFilteredResults.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.setState({ selectedRowKeys: [], remarksOption: null });
      return
    } else if (!prevProps.visible && this.props.visible) {
      this.fetchPropertyAgents();
    }
  }

  async fetchPropertyAgents() {
    this.setState({ isFetching: true })
    try {
      let responseData = await this.props.fetchPropertyAgents();
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.property_agents),
        },
      })
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
      })
    }
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  };

  onChangeFilter(key, value) {
    this.setState({
      filteredInfo: {
        ...this.state.filteredInfo,
        [key]: value,
      },
    });
  }

  getFormItems() {
    const items = [{
      label: this.props.t("Listings.AgentEventsTable.Agency"),
      shouldRender: true,
      span: 12,
      renderChildren: () => (
        <Input
          placeholder={ this.props.t("Listings.AgencyName") }
          onChange={ (e) => this.onChangeFilter('companyName', e.target.value) }
        />
      ),
    }, {
      label: "Region",
      shouldRender: true,
      span: 12,
      renderChildren: () => (
        <Select
          mode="multiple"
          placeholder="All"
          value={ this.state.filteredInfo.selectedNeighbourhoods }
          onChange={ (values) => this.onChangeFilter('selectedNeighbourhoods', values) }
          filterOption={ (inputValue, option) => {
            const neighbourhoodId = parseInt(option.key);
            const neighbourhood = this.props.NeighbourhoodOptions[neighbourhoodId];
            if (neighbourhood.name.toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }
            return false;
          }}
        >
          {
            Object.values(this.props.NeighbourhoodOptions).map((neighbourhood) => (
              <Select.Option key={ neighbourhood.id }>
                { neighbourhood.name }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }]

    const formItemLayout = {
      labelCol: {
        lg: { span: 6, },
        xl: { span: 5  },
      },
      wrapperCol: {
        lg: { span: 18 },
        xl: { span: 19 },
      },
    };
    return items.filter(({ shouldRender }) => { return shouldRender }).map((item, index) => {
      return (
        <Col span={ item.span || 8 } key={ index.toString() }>
          <Form.Item
            label={ item.label }
            { ...formItemLayout }
            style={{ marginBottom: 10 }}
          >
            { item.renderChildren() }
          </Form.Item>
        </Col>
      )
    });
  }

  renderFilterForm() {
    return (
      <div style={{ marginBottom: 10 }}>
        <Form className="ant-advanced-search-form">
          <Row
            gutter={ 24 }
            justify="space-between"
          >
            { this.getFormItems() }
          </Row>
        </Form>
      </div>
    )
  }

  getFilteredResults(items) {
    const { filteredInfo } = this.state;
    return items.filter((agent) => {
      if (!filteredInfo) { return true };
      if (filteredInfo.companyName) {
        if (agent.agency && agent.agency.name
          && !agent.agency.name.toLowerCase().includes(filteredInfo.companyName.toLowerCase())
        ) {
          return false
        }
      }
      if (filteredInfo.selectedNeighbourhoods && filteredInfo.selectedNeighbourhoods.length > 0) {
        const selectedIds = filteredInfo.selectedNeighbourhoods;
        const actualIds = agent.neighbourhoods.map((n) => n.id.toString());
        const intersection = selectedIds.filter(value => actualIds.includes(value))
        if (intersection.length === 0) {
          return false;
        }
      }
      return true
    })
  }

  renderAdditionFormItems() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Listings.BroadcastRemarks.Title") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          <Radio.Group
            onChange={ (e) => this.setState({ remarksOption: e.target.value }) }
            value={ this.state.remarksOption }
          >
            <Radio.Button value='new-listing'>
              { this.props.t("Listings.BroadcastRemarks.NewListing") }
            </Radio.Button>
            <Radio.Button value='updated-details'>
              { this.props.t("Listings.BroadcastRemarks.UpdatedDetails") }
            </Radio.Button>
            <Radio.Button value='price-reduction'>
              { this.props.t("Listings.BroadcastRemarks.PriceReduction") }
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { selectedRowKeys, remarksOption } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const dataSource = this.getFilteredResults(Object.values(this.state.itemsById));
    return (
      <Modal
        width="70%"
        visible={ this.props.visible }
        title={ this.props.t("Listings.actions.BroadcastListing") }
        okText={ this.props.t("actions.Submit") }
        okButtonProps={{
          disabled: (selectedRowKeys.length === 0),
        }}
        onCancel={ this.props.onCancel }
        onOk={ () => { return this.props.onOk(selectedRowKeys, remarksOption) }}
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderFilterForm() }
        <Table
          rowKey={ record => record.id.toString() }
          loading={ this.state.isFetching }
          rowSelection={ rowSelection }
          columns={[{
            title: this.props.t("Listings.AgentEventsTable.Agent"),
            dataIndex: 'name',
            key: 'name',
          }, {
            title: this.props.t("Listings.AgentEventsTable.Agency"),
            dataIndex: 'agency.name',
            key: 'agency.name',
          }, {
            title: 'Regions',
            dataIndex: 'neighbourhoods',
            key: 'neighbourhoods',
            render: (neighbourhoods, record) => {
              return (
                <div style={{ display: 'flex' }}>
                  {
                    neighbourhoods.map((neighbourhood) => (
                      <Tooltip
                        key={ neighbourhood.id.toString() }
                        placement="top"
                        title={ neighbourhood.name }
                      >
                        <Tag color="blue" style={{ marginRight: 10 }}>
                          { neighbourhood.abbreviation }
                        </Tag>
                      </Tooltip>
                    ))
                  }
                </div>
              )
            },
          }]}
          dataSource={ dataSource }
        />
        { this.renderAdditionFormItems() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    NeighbourhoodOptions: state.constantsReducer.neighbourhoodsById,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchPropertyAgents: (params = {}) => {
      return (dispatch(PropertyAgentActions.fetchPropertyAgents(params)));
    },
  };
}

const ConnectedWhatsappBroadcastForm = connect(mapStateToProps, mapDispatchToProps)(WhatsappBroadcastForm);

const WrappedWhatsappBroadcastForm = Form.create({
  name: "listings_broadcast_form_in_modal"
})(ConnectedWhatsappBroadcastForm);

export default withTranslation('common')(WrappedWhatsappBroadcastForm);
