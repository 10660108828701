import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Radio,
  Spin,
} from 'antd';

import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';
import * as AccountEntryActions from '@actions/accountEntries';
import PreviewPdfModal from '@components/AccountEntries/PreviewPdfModal';

const RESET_STATE = (state = {}) => {
  return {
    viewing: null,

    ...state,
  }
}

const ViewingOptions = {
  last_saved: {
    value: "last_saved",
    ttextKey: "AccountEntries.DocumentGeneration.LastSavedVersion",
    disabled: (item) => !item.last_receipt_url,
  },
  generate_new: {
    value: "generate_new",
    ttextKey: "AccountEntries.DocumentGeneration.NewVersion",
    disabled: (item) => false,
  },
}

class PreviewReceiptModal extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
  }

  static defaultProps = {
    visible: false,
    onCancel: () => {},
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onSaveNewVersion = this.onSaveNewVersion.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) {
      if (this.props.item && this.props.item.last_receipt_url) {
        this.setState({ viewing: ViewingOptions.last_saved.value })
      } else {
        this.setState({ viewing: ViewingOptions.generate_new.value })
      }
    }
  }

  async onSaveNewVersion() {
    try {
      let responseData = await this.props.onSaveNewVersion();
      this.setState({ viewing: ViewingOptions.last_saved.value })
    } catch (error) {
      return
    }
  }

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }
    const previewUrl = item.preview_receipt_url;
    if (!previewUrl) {
      return null;
    }

    const entryType = this.props.AccountEntryTypeOptions[item.entry_type].text;
    const dueDate = DateUtils.formatDate(item.due_date);
    const title = `${ this.props.t("AccountEntries.PreviewReceiptFor") }: ${item.ownership.name} ${entryType} - ${dueDate}`

    const displayUrl = (this.state.viewing === ViewingOptions.last_saved.value) ? item.last_receipt_url : previewUrl;
    return (
      <PreviewPdfModal
        title={ title }
        visible={ this.props.visible }
        onCancel={ this.props.onCancel }
        pdfUrl={ displayUrl }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 5, width: '80%' }}>
            <div>
              <span style={{ marginRight: 10 }}>{ this.props.t("AccountEntries.DocumentGeneration.Viewing") }:</span>
              <Radio.Group
                value={ this.state.viewing }
                onChange={ (e) => this.setState({ viewing: e.target.value }) }
              >
                {
                  Object.values(ViewingOptions).map((option) => (
                    <Radio.Button
                      key={ option.value }
                      value={ option.value }
                      disabled={ option.disabled(item) }
                    >
                      { this.props.t(option.ttextKey) }
                    </Radio.Button>
                  ))
                }
              </Radio.Group>
            </div>
            <Button
              type="primary"
              disabled={ (this.state.viewing === ViewingOptions.last_saved.value) }
              loading={ item.isUpdating }
              onClick={ this.onSaveNewVersion }
            >
              { this.props.t("AccountEntries.DocumentGeneration.SaveNewVersion") }
            </Button>
          </div>
        </div>
      </PreviewPdfModal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PreviewReceiptModal));
