import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as OwnershipActions from '@actions/ownerships';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnerships: false,
    ownershipsById: {},
    errorFetchingOwnerships: null,
    ...state,
  };
}

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwnerships: true });
      let params = { ownership_id: this.props.ownershipId };
      this.props.fetchOwnershipsWithManagers(params).then((responseData) => {
        this.setState({
          isFetchingOwnerships: false,
          ownershipsById: {
            ...ArrayUtils.arrayToObject(responseData.ownerships),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnerships: false,
          errorFetchingOwnerships: error,
        });
      });
    }
  }

  render() {
    const { visible, onCancel, onOk, confirmLoading, form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <Modal
        visible={ visible }
        title={ this.props.t("Tenancies.actions.AddTenancy") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ onCancel }
        onOk={ onOk }
        confirmLoading={ confirmLoading }
      >
        <Form layout="vertical">
          <Form.Item
            label={ this.props.t("Properties.Property") }
            {...formItemLayout}
          >
              {getFieldDecorator("ownership_id", {
                rules: [
                  { required: true, message: `${ this.props.t("Properties.messages.Form.PleaseSelectProperty") }!` },
                ],
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder={ this.props.t("Properties.messages.Form.PleaseSelectProperty") }
                  loading={ this.state.isFetchingOwnerships }
                  showSearch={ (Object.values(this.state.ownershipsById).length > 5) }
                  filterOption={ (inputValue, option) => {
                    const ownershipId = parseInt(option.key);
                    const ownership = this.state.ownershipsById[ownershipId];
                    if (ownership.name.toLowerCase().includes(inputValue.toLowerCase())) {
                      return true;
                    }
                    if (ownership.address.toLowerCase().includes(inputValue.toLowerCase())) {
                      return true;
                    }
                    return false;
                  }}
                  notFoundContent={
                    this.state.isFetchingOwnerships ? <Spin size="small" /> : null
                  }
                >
                  {
                    Object.values(this.state.ownershipsById).map((ownership) => (
                      <Select.Option
                        key={ ownership.id.toString() }
                      >
                        { ownership.name }. { ownership.address }
                      </Select.Option>
                    ))
                  }
                </Select>
              )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenancyPeriod") }
             {...formItemLayout}
          >
            {getFieldDecorator('contract_period', {
              rules: [{ type: 'array', required: true, message: 'Please select dates!' }],
            })(
              <DatePicker.RangePicker />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.RentFreePeriod") }
            {...formItemLayout}
          >
            {getFieldDecorator('rent_free_period', {
              rules: [{ type: 'array', required: false, message: 'Please select dates!' }],
            })(
              <DatePicker.RangePicker />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantName") }
            {...formItemLayout}
            >
            {getFieldDecorator("official_tenant_name", {
              rules: [{ required: true, message: "Please input registered tenant name" }],
            })(<Input placeholder={ this.props.t("Tenancies.TenantName") } />)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantEmail") }
            {...formItemLayout}
            >
            {getFieldDecorator("official_tenant_email", {
              rules: [{ required: false, message: "Please input registered tenant email" }],
            })(<Input placeholder={ this.props.t("Tenancies.TenantEmail") } />)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantPhone") }
            {...formItemLayout}
            >
            {getFieldDecorator("official_tenant_phone_number", {
              rules: [{ required: false, message: "Please input registered tenant phone number" }],
            })(<Input placeholder={ this.props.t("Tenancies.TenantPhone") } />)}
          </Form.Item>
          <Form.Item
            label={ `${this.props.t("Tenancies.TenantCorrespondenceAddress") } (${this.props.t("Tenancies.IfDifferentToPropertyAddress")})` }
            {...formItemLayout}
          >
            {getFieldDecorator("correspondence_address", {
              rules: [{ required: false, message: "Please input registered tenant correspondence address" }],
            })(
              <Input.TextArea
                rows={ 3 }
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.PaymentDate") }
            {...formItemLayout}
          >
            {getFieldDecorator('rent_due', {
              initialValue: 1,
              rules: [{ required: true, message: 'Please input payment due date' }],
            })(
              <InputNumber
                style={{ width: '100%', minWidth: '150px' }}
                min={1}
                max={31}
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.RentAmount") }
            {...formItemLayout}
          >
            {getFieldDecorator('rental_amount', {
                rules: [{ required: true, message: 'Please input rental amount' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("shared.SecurityDeposit") }
            {...formItemLayout}
          >
            {getFieldDecorator('security_deposit', {
                rules: [{ required: true, message: 'Please input security deposit' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenancyTerms.ManagementFee") }
            {...formItemLayout}
          >
            {getFieldDecorator('include_mgmt_fee', {
              rules: [{ required: true, message: 'Please select management fee' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenancyTerms.GovtRent") }
            {...formItemLayout}
          >
            {getFieldDecorator('include_govt_rent', {
              rules: [{ required: true, message: 'Please select govt rent' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenancyTerms.GovtRates") }
            {...formItemLayout}
          >
            {getFieldDecorator('include_govt_rates', {
              rules: [{ required: true, message: 'Please select govt rates' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            label={ this.props.t("shared.Notes") }
            {...formItemLayout}
          >
            {getFieldDecorator('notes', {
            })(
              <Input.TextArea rows={ 5 } />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipsWithManagers: (params) => {
      return (dispatch(OwnershipActions.fetchOwnershipsWithManagers(params)));
    },
  };
}

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

const WrappedCreateForm = Form.create({
  name: "tenancy_create_form_in_modal"
})(ConnectedCreateForm);

export default withTranslation('common')(WrappedCreateForm);
