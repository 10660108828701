import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Dropdown,
  Icon,
  Menu,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';
import { TaskCategoryOptions } from "@constants/models/Tasks";

class TasksList extends React.Component {
  static propTypes = {
    showPropertyName: PropTypes.bool,
    onSelectUpdateItem: PropTypes.func.isRequired,
  }

  static defaultProps = {
    showPropertyName: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    let { sorter } = this.props;

    let columns = [{
      title: this.props.t("Tasks.Category"),
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => (a.category.localeCompare(b.category)),
      sortOrder: sorter.columnKey === 'category' && sorter.order,
      render: (category) => TaskCategoryOptions[category].text,
    }, {
      title: this.props.t("Tasks.TaskName"),
      dataIndex: 'name',
      key: 'name',
    }, {
      title: this.props.t("Tasks.AssignedTo"),
      dataIndex: 'owned_by',
      key: 'owned_by',
      render: (value, record) => {
        return (
          <React.Fragment>
            <div>
              <Avatar
                style={{ marginRight: 5 }}
                icon="user"
                src={ value.avatar_url }
              />
              { value.display_name }
            </div>
            { record && record.assign_to_mypropty && (
              <div>
                with MyPropty Assistance
              </div>
            )}
          </React.Fragment>
        );
      },
    }, {
      title: this.props.t("Tasks.Deadline"),
      dataIndex: 'deadline',
      key: 'deadline',
      sorter: (a, b) => moment(a.deadline) - moment(b.deadline),
      sortOrder: sorter.columnKey === 'deadline' && sorter.order,
      render: (value) => {
        if (!value) {
          return null;
        }
        return (
          <Tooltip placement="top" title={ DateUtils.formatDate(value) }>
            <span>{ moment(value).fromNow() }</span>
          </Tooltip>
        )
      },
    }, {
      title: this.props.t("shared.Action"),
      key: 'action',
      render: (text, record) => {
        const menuItems = [{
          key: "updateTask",
          shouldRender: (record) => true,
          render: (record) => this.props.t("Tasks.actions.UpdateTask"),
        }, {
          key: "destroyTask",
          shouldRender: (record) => (record.status !== "completed"),
          render: (record) => (
            <Popconfirm
              title={ `${ this.props.t("Tasks.messages.Form.SureToDelete") }: ${record.name}?` }
              onConfirm={ () => {
                this.props.onDestroyTask(record);
              }}
            >
              { this.props.t("Tasks.actions.DeleteTask") }
            </Popconfirm>
          ),
        }];
        const menu = (
          <Menu
            onClick={ ({ key }) => {
              if (key === "updateTask") {
                this.props.onSelectUpdateItem(record);
              }
            }}
          >
            {
              menuItems.filter(({ shouldRender }) => shouldRender(record)).map((menuItem) => (
                <Menu.Item key={ menuItem.key }>
                  { menuItem.render(record) }
                </Menu.Item>
              ))
            }
          </Menu>
        )
        return (
          <Dropdown overlay={menu}>
            <Button>
              <Icon type="menu" /><Icon type="down" />
            </Button>
          </Dropdown>
        )
      }
    }];

    if (this.props.showPropertyName) {
      columns.splice(1, 0, {
        title: this.props.t("Properties.Property"),
        dataIndex: 'ownership_name',
        key: 'ownership_name',
        sorter: (a, b) => (a.ownership_name.localeCompare(b.ownership_name)),
        sortOrder: sorter.columnKey === 'ownership_name' && sorter.order,
        render: (value, record) => (
          <Link to={ `/properties/${record.ownership_id}` }>
            { value }
          </Link>
        )
      })
      // <Highlighter
      //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //   searchWords={[ (filteredInfo && filteredInfo.displayName) ? filteredInfo.displayName : '' ]}
      //   autoEscape
      //   textToHighlight={ value }
      // />
    }

    return (
      <Table
        { ...this.props }
        rowKey={ record => record.id }
        columns={ columns }
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TasksList));
