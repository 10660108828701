import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Popconfirm,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class DocumentShow extends React.Component {
  static propTypes = {
    document: PropTypes.shape({
      preview_url: PropTypes.string,
      url: PropTypes.string,
      ownership_name: PropTypes.string,
      doc_type_display_name: PropTypes.string,
      date: PropTypes.string,
      description: PropTypes.description,
    }),
    onClickEdit: PropTypes.func.isRequired,
    onDestroy: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onDestroy: () => {},
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { document } = this.props;
    return (
      <div>
        <img
          src={ document.preview_url }
          width="70%"
        />
        <div style={{ margin: '30px 0px' }}>
          <Button
            onClick={ () => window.open(document.url,'_blank') }
          >
            { this.props.t("Documents.actions.OpenDocument") }
          </Button>
          <Button
            onClick={ this.props.onClickEdit }
            icon="edit"
          >
            { this.props.t("Documents.actions.EditDocument") }
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={ () => {
              this.props.onDestroy();
            }}
          >
            <Button type="danger" loading={ document.isDestroying }>
              { this.props.t("actions.Delete") }
            </Button>
          </Popconfirm>
        </div>
        <div>
          <h4>{ document.ownership_name }: { document.doc_type_display_name }</h4>
        </div>
        <div>
          <h4>{ document.date && DateUtils.formatDate(document.date) }</h4>
        </div>
        <div>
          <p style={{ 'whiteSpace' : 'pre-line' }}>
            { document.description }
          </p>
        </div>
      </div>
    )
  }
}


export default withTranslation('common')(DocumentShow);
