import * as ArrayUtils from '@utils/array';
import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetching: false,
  owners: {},
  error: null,
  isCreating: false,
  creatingError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case `${ActionTypes.FETCH_OWNERS}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case `${ActionTypes.FETCH_OWNERS}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetching: false,
        error: null,
        owners: {
          ...state.owners,
          ...ArrayUtils.arrayToObject(action.responseData.owners),
        },
      };
    case `${ActionTypes.FETCH_OWNERS}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
