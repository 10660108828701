import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Upload,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';

const RESET_STATE = (state = {}) => {
  return {
    fileList: [],
    ...state,
  };
}

class UpdateForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onOk = this.onOk.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }


  onOk() {
    const { fileList } = this.state;
    this.props.form.setFieldsValue({
      br_file: (fileList.length > 0) ? fileList[0] : null,
    })
    this.props.onOk();
  }

  renderForm(owner) {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14, offset: 1 },
    };

    const items = [
      {
        label: this.props.t("Owners.OwnerName"),
        shouldRender: true,
        render: () => owner.name,
      }, {
        label: this.props.t("Owners.OwnerType"),
        shouldRender: true,
        render: () => owner.is_company ? this.props.t("Owners.Company") : this.props.t("Owners.Individual"),
      }, {
        label: this.props.t("Owners.BRNumber"),
        shouldRender: owner.is_company,
        render: () => getFieldDecorator("br_cert_number", {
          initialValue: owner.br_cert_number,
          rules: [{ required: false, message: "Please input Business Registration number!" }],
        })(
          <Input placeholder={ this.props.t("Owners.BRNumber") } />
        ),
      }, {
        label: this.props.t("Owners.BRExpiryDate"),
        shouldRender: owner.is_company,
        render: () => getFieldDecorator("br_expiry_date", {
          initialValue: owner.br_expiry_date ? moment(owner.br_expiry_date) : null,
          rules: [{ required: false, message: "Please input Business Registration expiration!" }],
        })(
          <DatePicker format={"YYYY/MM/DD"} />
        ),
      }, {
        label: this.props.t("Owners.IDNumber"),
        shouldRender: !owner.is_company,
        render: () => getFieldDecorator("id_card_number", {
          initialValue: owner.id_card_number,
          rules: [{ required: false, message: "Please input ID number!" }],
        })(
          <Input placeholder={ this.props.t("Owners.IDNumber") } />
        ),
      }, {
        label: this.props.t("Owners.CorrespondenceAddress"),
        shouldRender: true,
        render: () => getFieldDecorator("correspondence_address", {
          initialValue: owner.correspondence_address,
          rules: [{ required: true, message: "Please input correspondence address!" }],
        })(
          <Input.TextArea rows={ 4 } placeholder={ this.props.t("Owners.CorrespondenceAddress") } />
        ),
      }, {
        label: this.props.t("shared.ContactDetails"),
        shouldRender: true,
        render: () => getFieldDecorator("contact_details", {
          initialValue: owner.contact_details,
          rules: [{ required: true, message: "Please input contact details!" }],
        })(
          <Input.TextArea rows={ 4 } placeholder="Contact Details" />
        ),
      }, {
        label: this.props.t("Owners.BusinessRegistration"),
        shouldRender: owner.is_company,
        render: () => (
          <div className="dropbox">
            {getFieldDecorator("br_file", {
              getValueFromEvent: this.normFile,
              rules: [
                { required: false, message: 'Please select images!' },
              ],
            })(
              <Upload
                multiple={ false }
                onRemove={
                  (file) => {
                    this.setState({ fileList: [] });
                  }
                }
                beforeUpload={
                  (file) => {
                    this.setState(state => ({
                      fileList: [file],
                    }));
                    return false;
                  }
                }
                fileList={ this.state.fileList }
                name="br_file"
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </Upload>
            )}
          </div>
        ),
      }
    ]

    return (
      <Form layout="vertical">
        {
          items.filter(({ shouldRender }) => shouldRender).map((item, index) => (
            <Form.Item
              key={ index.toString() }
              label={ item.label }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              { item.render() }
            </Form.Item>
          ))
        }
      </Form>
    )
  }

  render() {
    if (!this.props.owner) {
      return null
    }
    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.t("Owners.actions.UpdateOwner") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm(this.props.owner) }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedUpdateOwnerForm = connect(mapStateToProps, mapDispatchToProps)(UpdateForm);

const WrappedUpdateOwnerForm = Form.create({
  name: "update_owner_form_in_modal"
})(ConnectedUpdateOwnerForm);

export default withTranslation('common')(WrappedUpdateOwnerForm);
