import React from 'react';
import PropTypes from 'prop-types';

import '@components/LayoutStyle.scss';
import Owners from '@components/Owners';

export default class OwnersMainView extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="main-full-view">
        <Owners />
      </div>
    )
  }
}
