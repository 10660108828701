import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Spin,
  Table,
} from 'antd';

import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class DocumentsTable extends React.Component {
  static propTypes = {
    showPropertyName: PropTypes.bool,
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    sorter: PropTypes.object,
    pagination: PropTypes.object,
  }

  static defaultProps = {
    showPropertyName: false,
    dataSource: [],
    loading: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { sorter, filteredInfo } = this.props;
    let columns = [{
      title: this.props.t("Documents.DocumentType"),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => (a.type.localeCompare(b.type)),
      sortOrder: sorter.columnKey === 'type' && sorter.order,
      render: (type, record) => {
        return record.doc_type_display_name;
      },
    }, {
      title: this.props.t("shared.Date"),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => {
        // if DESCENDING, null will be first
        if (!a.date) {
          return 1;
        } else if (!b.date) {
          return -1;
        }
        return moment(a.date) - moment(b.date)
      },
      sortOrder: sorter.columnKey === 'date' && sorter.order,
      render: (value) => value && DateUtils.formatDate(value),
    }];

    if (this.props.showPropertyName) {
      columns.splice(1, 0, {
        title: this.props.t("Properties.Property"),
        dataIndex: 'ownership_name',
        key: 'ownership_name',
        sorter: (a, b) => (a.ownership_name.localeCompare(b.ownership_name)),
        sortOrder: sorter.columnKey === 'ownership_name' && sorter.order,
        render: (value, record) => (
          <Link to={ `/properties/${record.ownership_id}` }>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[ (filteredInfo && filteredInfo.displayName) ? filteredInfo.displayName : '' ]}
              autoEscape
              textToHighlight={ value }
            />
          </Link>
        )
      })
    }

    return (
      <Table
        rowKey={ record => record.id }
        columns={ columns }
        { ...this.props }
      />
    )
  }
}

export default withTranslation('common')(DocumentsTable)
