import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchRepairsData = (params = {}) => {
  return Api.get(
    'market_data/repairs_data',
    ActionTypes.FETCH_REPAIRS_DATA,
    params,
  )
}
