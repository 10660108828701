import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Table,
  Tag,
} from 'antd';
import { withTranslation } from 'react-i18next';
import numeral from 'numeral';

import * as ArrayUtils from '@utils/array';
import * as MarketDataActions from '@actions/marketData';
import FilterForm from './FilterForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isFetching: false,
    itemsById: {},
    errorFetching: null,

    // search params
    filteredInfo: {
      selectedRepairsDataTags: [],
    },

    ...state,
  };
}

class RepairsData extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchRepairsData = this.fetchRepairsData.bind(this);
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchRepairsData();
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
    }, this.fetchRepairsData)
  }

  getFilterParams() {
    const { filteredInfo } = this.state;
    return {
      filter: {
        ...filteredInfo,
      },
    }
  }

  async fetchRepairsData(params = {}) {
    this.setState({ isFetching: true })
    try {
      let responseData = await this.props.fetchRepairsData({
        ...params,
        ...this.getFilterParams(),
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.repairs_data_items),
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      });
      return
    }
  }

  render() {
    const columns = [
      {
        title: "Description",
        dataIndex: "description",
      }, {
        title: "Amount",
        dataIndex: "amount",
        render: (amount) => numeral(amount).format('($0,0)'),
      }, {
        title: "Tags",
        dataIndex: "repairs_data_tags",
        render: (repairs_data_tags) => {
          return (
            <React.Fragment>
              {
                repairs_data_tags.map((tag) => (
                  <Tag color={ tag.color || "blue" } key={ tag.name }>
                    { tag.name }
                  </Tag>
                ))
              }
            </React.Fragment>
          )
        },
      }
    ]
    return (
      <div>
        <FilterForm
          filteredInfo={ this.state.filteredInfo }
          loading={ this.state.isFetching }
          onSubmit={ this.handleFilterFormSubmit }
          options={ this.props.RepairsDataTagOptions }
        />
        <Table
          rowKey={ record => record.id.toString() }
          columns={ columns }
          dataSource={ Object.values(this.state.itemsById) }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    RepairsDataTagOptions: state.constantsReducer.repairsDataTagsById,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchRepairsData: (params = {}) => {
      return dispatch(MarketDataActions.fetchRepairsData(params))
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(RepairsData));
