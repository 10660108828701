import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Alert,
  DatePicker,
  Form,
  Modal,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  ListingTypeOptions,
} from '@constants/models/Listing';
import * as ArrayUtils from '@utils/array';

const RESET_STATE = (state = {}) => {
  return {
    ...state,
  };
}

class CloseListingForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
  }

  renderForm() {
    const { listing } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { listing.ownership.name }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Properties.Address") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { listing.ownership.address }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingType") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { this.props.t(ListingTypeOptions[listing.listing_type].ttextKey) }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingName") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { listing.name }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingClosedOn") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("closed_at", {
            initialValue: listing.closed_at ? moment(listing.closed_at) : null,
          })(
            <DatePicker
              format={"YYYY/MM/DD"}
              disabledDate={ (current) => {
                // Can not select days before today and today
                return current > moment().endOf('day');
              }}
            />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { listing } = this.props;
    if (!listing) {
      return null;
    }
    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.t("Listings.actions.CloseListing") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.props.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        <Alert
          style={{ marginBottom: 20 }}
          type="warning"
          message={ this.props.t("Listings.messages.Form.AlertCloseListingWillBeUnavailable") }
          showIcon
        />
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedCloseListingForm = connect(mapStateToProps, mapDispatchToProps)(CloseListingForm);

const WrappedCloseListingForm = Form.create({
  name: "listings_close_form_in_modal"
})(ConnectedCloseListingForm);

export default withTranslation('common')(WrappedCloseListingForm);
