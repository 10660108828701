import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchOwners = (params = {}) => {
  return Api.get(
    'owners',
    ActionTypes.FETCH_OWNERS,
    params,
  );
}

export const updateOwner = (ownerId, params = {}) => {
  return Api.putUpload(
    `owners/${ownerId}`,
    ActionTypes.UPDATE_OWNER,
    params,
    {},
  )
}
