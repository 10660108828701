import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Spin,
} from 'antd';

import OwnerShow from '@components/Owners/Show';

export default class OwnerList extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    owners: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    loading: false,
    owners: [],
  }

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.owners.length == 0) {
      if (this.props.loading) {
        return (
          <Row type="flex" justify="center">
            <Spin />
          </Row>
        )
      }
      return (<span>No owner information has been provided.</span>)
    }

    return (
      <div>
        {
          this.props.owners.map((owner, index) => (
            <OwnerShow
              key={ index.toString() }
              owner={ owner }
              onClickEdit={ () => this.props.onClickEdit(owner.id) }
            />
          ))
        }
      </div>
    )
  }
}
