import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  message,
} from 'antd';

import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as OwnershipActions from '@actions/ownerships';
import * as OwnershipManagerActions from '@actions/ownershipManagers';
import OwnershipManagersList from '@components/OwnershipManagers/List';
import InviteForm from '@components/OwnershipManagers/InviteForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isInviting: false,
    isFetching: false,
    itemsById: {},
    errorInviting: null,
    errorFetching: null,

    // modals
    inviteModalVisible: false,

    ...state,
  }
}

class OwnershipManagers extends React.Component {
  static proptypes = {
    ownershipId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchOwnershipManagers = this.fetchOwnershipManagers.bind(this);
    this.onInviteSubmit = this.onInviteSubmit.bind(this);
    this.onDestroyOwnershipManager = this.onDestroyOwnershipManager.bind(this);
  }

  componentDidMount() {
    this.fetchOwnershipManagers(this.props.ownershipId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.setState({ itemsById: {} })
      this.fetchOwnershipManagers(this.props.ownershipId);
    }
  }

  async fetchOwnershipManagers(params = {}) {
    this.setState({ isFetching: true });
    try {
      let responseData = await this.props.fetchOwnershipManagers(this.props.ownershipId, {
        ...params,
      })
      this.setState({
        isFetching: false,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.ownership_managers),
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
      })
      return error;
    }
  }

  async onInviteSubmit() {
    const { form } = this.inviteFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({ isInviting: true });
      let responseData = await this.props.addOwnershipManager(this.props.ownershipId, values);
      form.resetFields();
      const newItem = responseData.ownership_manager;
      this.setState({
        inviteModalVisible: false,
        isInviting: false,
        itemsById: {
          ...this.state.itemsById,
          [newItem.id]: newItem,
        },
      });
      message.success(this.props.t("OwnershipDetailSections.Team.messages.AddTeamMember.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isInviting: false,
        errorInviting: error,
      });
      message.error(this.props.t("OwnershipDetailSections.Team.messages.AddTeamMember.Fail"));
      return null;
    }
  }

  onDestroyOwnershipManager(ownershipManagerId) {
    this.setState({
      itemsById: {
        ...this.state.itemsById,
        [ownershipManagerId]: {
          ...this.state.itemsById[ownershipManagerId],
          isDestroying: true,
        },
      },
    })
    return this.props.destroyOwnershipManager(ownershipManagerId).then((responseData) => {
      let itemsById = { ...this.state.itemsById };
      delete itemsById[ownershipManagerId];
      this.setState({
        itemsById: {
          ...itemsById,
        },
      });
      message.success(this.props.t("OwnershipDetailSections.Team.messages.RemoveTeamMember.Success"));
      return responseData;
    }).catch((error) => {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [ownershipManagerId]: {
            ...this.state.itemsById[ownershipManagerId],
            isDestroying: false,
          },
        },
      })
      message.error(this.props.t("OwnershipDetailSections.Team.messages.RemoveTeamMember.Fail"));
      return error;
    })
  }

  saveInviteFormRef = formRef => {
    this.inviteFormRef = formRef;
  }

  render() {
    const { ownershipId } = this.props;
    const dataSource = Object.values(this.state.itemsById);
    return (
      <div>
        <Button
          type="primary"
          icon="user-add"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ inviteModalVisible: true })}
        >
          { this.props.t("OwnershipDetailSections.Team.actions.InviteUser") }
        </Button>
        <Button
          icon="user-add"
          style={{ marginBottom: 14 }}
          onClick={ () => window.fcWidget.open({
            name: 'Inbox',
            replyText: `I would like to invite a user to [OID.${ownershipId}]. Their email is:`
          })}
        >
          { this.props.t("OwnershipDetailSections.Team.actions.InviteOutsideUser") }
        </Button>
        <InviteForm
          wrappedComponentRef={ this.saveInviteFormRef }
          ownershipId={ this.props.ownershipId }
          ownershipOwnershipManagerIds={ Object.values(this.state.itemsById).map((item) => item.user_id) }
          visible={ this.state.inviteModalVisible }
          confirmLoading={ this.state.isInviting }
          onCancel={ () => this.setState({ inviteModalVisible: false }) }
          onOk={ this.onInviteSubmit }
        />
        <OwnershipManagersList
          loading={ this.state.isFetching }
          dataSource={ dataSource }
          onDestroyOwnershipManager={ (ownershipManagerId) => this.onDestroyOwnershipManager(ownershipManagerId) }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipManagers: (params = {}) => {
      return dispatch(OwnershipActions.fetchOwnershipManagers(params))
    },
    addOwnershipManager: (ownershipId, params = {}) => {
      return dispatch(OwnershipActions.addOwnershipManager(ownershipId, params));
    },
    destroyOwnershipManager: (ownershipManagerId) => {
      return dispatch(OwnershipManagerActions.destroyOwnershipManager(ownershipManagerId));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OwnershipManagers));
