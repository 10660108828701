import React from 'react';
import PropTypes from 'prop-types';

import {
  Upload,
} from 'antd';

import Uploader from '@utils/uploader';

class AsyncUpload extends React.Component {
  static propTypes = {
    fileDataMap: PropTypes.object.isRequired,
    updateFileDataMap: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { fileDataMap, updateFileDataMap, ...rest} = this.props;
    return (
      <Upload
        { ...rest }
        onChange={
          ({ file }) => {
            if (fileDataMap[file.uid] === undefined) {
              return
            }
            let isFileUploading;
            if (file.status === "uploading") {
              isFileUploading = true;
            } else if (file.status === "done" || file.status === "error") {
              isFileUploading = false;
            }
            this.props.updateFileDataMap({
              ...this.props.fileDataMap,
              [file.uid]: {
                ...this.props.fileDataMap[file.uid],
                isFileUploading,
              },
            })
          }
        }
        onRemove={
          (file) => {
            const newFileDataMap = { ...this.props.fileDataMap };
            delete newFileDataMap[file.uid];
            this.props.updateFileDataMap(newFileDataMap);
          }
        }
        customRequest={ ({ file, onError, onSuccess, onProgress, data, filename, withCredentials, action, headers }) => {
          const onSuccessCallback = (res, file, blob) => {
            onSuccess(res, file);
            this.props.updateFileDataMap({
              ...this.props.fileDataMap,
              [file.uid]: {
                ...this.props.fileDataMap[file.uid],
                blobSignedId: blob.signed_id,
              },
            })
          }
          const uploader = new Uploader({
            file,
            onError,
            onProgress,
            onSuccess: onSuccessCallback,
          });
          uploader.uploadFile();
        }}
      >
        { this.props.children }
      </Upload>
    )
  }
}

export default AsyncUpload;
