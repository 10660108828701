import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Alert,
  Badge,
  Calendar,
  Col,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import './EventCalendar.scss';

import * as PortfolioActions from '@actions/portfolio';

const EVENT_COLOR_MAP = {
  task: 'orange',
  income: 'green',
  expense: 'red',
  br_registration: 'blue',
  tenancy_start: 'violet',
  tenancy_end: 'black',
}

class EventCalendar extends React.Component {
  static propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['task', 'income', 'expense', 'br_registration', 'tenancy_start', 'tenancy_end']).isRequired,
      content: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['pending', 'completed']).isRequired,
    })),
  }

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: 'all',
      },
      calendarValue: moment(),
      calendarSelectedValue: null,
      isFetching: false,
      events: [],
      errorFetching: null,
    };
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.fetchImportantDates = this.fetchImportantDates.bind(this);
    this.onCalendarPanelChange = this.onCalendarPanelChange.bind(this);
    this.onCalendarDateSelect = this.onCalendarDateSelect.bind(this);
    this.renderCustomHeader = this.renderCustomHeader.bind(this);
    this.renderStatusFilter = this.renderStatusFilter.bind(this);
    this.renderSelectedDateContents = this.renderSelectedDateContents.bind(this);
  }

  componentDidMount() {
    this.fetchImportantDates();
  }

  async fetchImportantDates(params = {}) {
    let { calendarValue } = this.state;
    params = {
      start_date: calendarValue.startOf('month').format('YYYY-MM-DD'),
      end_date: calendarValue.endOf('month').format('YYYY-MM-DD'),
      ...params,
    }
    this.setState({ isFetching: true, events: [], errorFetching: null });
    try {
      let responseData = await this.props.fetchImportantDates(params);
      this.setState({
        isFetching: false,
        events: responseData.events,
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        events: [],
      })
    }
  }

  onCalendarDateSelect(value) {
    this.setState({
      calendarValue: value,
      calendarSelectedValue: value,
    });
  }

  onCalendarPanelChange(value) {
    this.setState({
      calendarValue: value,
      calendarSelectedValue: null,
    }, this.fetchImportantDates);
  }

  onChangeFilter(key, value) {
    this.setState({
      filter: {
        ...this.state.filter,
        [key]: value,
      },
    })
  }

  dateCellRender(value) {
    const events = this.state.events.filter((event) => {
      if (event.date !== value.format("YYYY-MM-DD")) {
        return false;
      }
      const { filter } = this.state;
      return ((filter.status === 'all') || (filter.status === event.status));
    });
    return (
      <ul className="events">
        {
          events.map((item, index) => (
            <li key={`${item.type}_${index}`}>
              <Badge
                color={ EVENT_COLOR_MAP[item.type] }
                text={ `${item.event_holder}: ${item.content}` }
              />
            </li>
          ))
        }
      </ul>
    );
  }

  renderCustomHeader({ value, type, onChange, onTypeChange }) {
    const monthOptions = [];
    const yearOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const currentYear = moment().year();

    for (let index = 0; index < 12; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={ `${index}` }>
          { localeData.monthsShort(current.month(index)) }
        </Select.Option>
      )
    }

    for (let i = currentYear - 3; i < currentYear + 5; i ++) {
      yearOptions.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }
    return (
      <div style={{ padding: 10 }}>
        <Row type="flex" justify="space-between">
          <Col span={8}>
            { this.renderStatusFilter() }
          </Col>
          <Col span={8}>
            <Select
              size="large"
              className="my-year-select"
              onChange={newYear => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
              value={String(value.year())}
            >
              {yearOptions}
            </Select>
            <Select
              size="large"
              value={String(value.month())}
              onChange={selectedMonth => {
                const newValue = value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                onChange(newValue);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
          <Col span={8}>
            { this.renderSelectedDateContents() }
          </Col>
        </Row>
      </div>
    );
  }

  renderStatusFilter() {
    return (
      <Radio.Group
        onChange={ (e) => this.onChangeFilter('status', e.target.value) }
        value={ this.state.filter.status }
        size="large"
      >
        <Radio.Button value='pending'>
          { this.props.t("Tasks.StatusPending") }
        </Radio.Button>
        <Radio.Button value='completed'>
          { this.props.t("Tasks.StatusCompleted") }
        </Radio.Button>
        <Radio.Button value='all'>
          { this.props.t("shared.AllStatuses") }
        </Radio.Button>
      </Radio.Group>
    )
  }

  renderSelectedDateContents() {
    const { calendarSelectedValue } = this.state;
    if (!calendarSelectedValue) {
      return null;
    }
    const events = this.state.events.filter((event) => {
      if (event.date !== calendarSelectedValue.format("YYYY-MM-DD")) {
        return false;
      }
      const { filter } = this.state;
      return ((filter.status === 'all') || (filter.status === event.status));
    })
    return (
      <ul className="events">
        {
          events.map((item, index) => (
            <li key={`${item.type}_${index}`}>
              <span>
                <strong style={{ color: EVENT_COLOR_MAP[item.type] }}>
                  { item.type }
                </strong> {item.event_holder}: { item.content }
              </span>
            </li>
          ))
        }
      </ul>
    )
  }

  render() {
    return (
      <div>
        { this.state.isFetching && <Spin size="large" /> }
        { this.state.errorFetching && (
          <Alert
            message={ this.props.t("Portfolio.messages.LoadEvents.Fail") }
            description={ <a onClick={ () => this.fetchImportantDates() }>{ this.props.t("shared.TryAgain") }.</a> }
            type="error"
            showIcon
          />
        )}
        <Calendar
          dateCellRender={ (value) => this.dateCellRender(value) }
          headerRender={ this.renderCustomHeader }
          onSelect={ this.onCalendarDateSelect }
          onPanelChange={ this.onCalendarPanelChange }
          value={ this.state.calendarValue }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchImportantDates: (params = {}) => {
      return dispatch(PortfolioActions.fetchImportantDates(params));
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EventCalendar));
