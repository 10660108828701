import * as ArrayUtils from '@utils/array';
import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetching: false,
  tasks: {},
  error: null,
  isCreating: false,
  creatingError: null,
}

export default (state = initialState, action) => {
  let task, tasks;
  switch (action.type) {
    case `${ActionTypes.FETCH_TASKS}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case `${ActionTypes.FETCH_TASKS}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetching: false,
        tasks: {
          ...state.tasks,
          ...ArrayUtils.arrayToObject(action.responseData.tasks),
        },
        error: null,
      };
    case `${ActionTypes.FETCH_TASKS}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isCreating: true,
        creatingError: null,
      };
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_SUCCESS}`:
      task = action.responseData.task;
      return {
        ...state,
        isCreating: false,
        creatingError: null,
        tasks: {
          ...state.tasks,
          [task.id]: task,
        },
      };
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isCreating: false,
        creatingError: action.error,
      };
    case `${ActionTypes.UPDATE_TASK}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.DELETE_TASK_ATTACHMENT}_${ActionTypes.CALL_API_REQUEST}`:
      task = { ...state.tasks[action.metaData.taskId] };
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.metaData.taskId]: {
            ...task,
            id: action.metaData.taskId,
            isUpdating: true,
          },
        },
      };
    case `${ActionTypes.UPDATE_TASK}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.DELETE_TASK_ATTACHMENT}_${ActionTypes.CALL_API_SUCCESS}`:
      task = { ...action.responseData.task };
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [task.id]: task,
        },
      };
    case `${ActionTypes.UPDATE_TASK}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.DELETE_TASK_ATTACHMENT}_${ActionTypes.CALL_API_ERROR}`:
      task = { ...state.tasks[action.metaData.taskId] };
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.metaData.taskId]: {
            ...task,
            id: action.metaData.taskId,
            isUpdating: false,
            updateError: action.error,
          },
        },
      };
    default:
      return state;
  }
}
