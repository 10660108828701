import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetchingSummaryData: false,
  summaryData: {},
  errorFetchingSummaryData: null,
  isFetchingNetCashFlowData: false,
  netCashFlowData: {},
  errorFetchingNetCashFlowData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case `${ActionTypes.FETCH_PORTFOLIO_SUMMARY}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetchingSummaryData: true,
        errorFetchingSummaryData: null,
      };
    case `${ActionTypes.FETCH_PORTFOLIO_SUMMARY}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetchingSummaryData: false,
        summaryData: action.responseData,
        errorFetchingSummaryData: null,
      };
    case `${ActionTypes.FETCH_PORTFOLIO_SUMMARY}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetchingSummaryData: false,
        errorFetchingSummaryData: action.error,
      };
    case `${ActionTypes.FETCH_NET_CASH_FLOW}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetchingNetCashFlowData: true,
        errorFetchingNetCashFlowData: null,
      };
    case `${ActionTypes.FETCH_NET_CASH_FLOW}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetchingNetCashFlowData: false,
        netCashFlowData: action.responseData,
        errorFetchingNetCashFlowData: null,
      };
    case `${ActionTypes.FETCH_NET_CASH_FLOW}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetchingNetCashFlowData: false,
        errorFetchingNetCashFlowData: action.error,
      };
    default:
      return state;
  }
}
