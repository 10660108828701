import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  message,
} from 'antd';

import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as DateUtils from '@utils/date';
import * as TenanciesActions from '@actions/tenancies';
import TenanciesList from '@components/Tenancies/List';
import CreateForm from '@components/Tenancies/CreateForm';
import UpdateForm from '@components/Tenancies/UpdateForm';
import TerminateTenancyForm from '@components/Tenancies/TerminateTenancyForm';
import AttachDocumentModal from '@components/AttachDocumentModal';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isCreating: false,
    isFetching: false,
    itemsById: {},
    errorCreating: null,
    errorFetching: null,

    // modals
    selectedItemId: null,
    createModalVisible: false,
    updateItemModalVisible: false,
    terminateTenancyModalVisible: false,

    // search params

    ...state,
  };
}

class Tenancies extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchTenancies = this.fetchTenancies.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.attachDocumentToTenancy = this.attachDocumentToTenancy.bind(this);
    this.onTerminateTenancySubmit = this.onTerminateTenancySubmit.bind(this);
  }

  componentDidMount() {
    this.fetchTenancies();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.fetchTenancies();
    }
  }

  async fetchTenancies(params = {}) {
    if (this.props.ownershipId) {
      params = {
        ...params,
        ownership_id: this.props.ownershipId,
      }
    }
    this.setState({ isFetching: true })
    try {
      let responseData = await this.props.fetchTenancies({
        ...params,
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.tenancies),
        },
      });
      return responseData
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      });
      return
    }
  }

  getSelectedItem() {
    const { selectedItemId, itemsById } = this.state;
    if (!selectedItemId) {
      return null
    }
    const selectedItem = itemsById[selectedItemId];
    return selectedItem ? selectedItem : null;
  }

  async onCreateSubmit() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      let { contract_period, rent_free_period, ...rest } = values;
      this.setState({ isCreating: true });
      let responseData = await this.props.createTenancy(this.props.ownershipId, {
        start_date: contract_period[0].format('YYYY-MM-DD'),
        end_date: contract_period[1].format('YYYY-MM-DD'),
        rent_free_start_date: rent_free_period && rent_free_period[0] && rent_free_period[0].format('YYYY-MM-DD'),
        rent_free_end_date: rent_free_period && rent_free_period[1] && rent_free_period[1].format('YYYY-MM-DD'),
        ...rest,
      });
      const newTenancy = responseData.tenancy;
      this.setState({
        isCreating: false,
        createModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [newTenancy.id]: newTenancy,
        },
      });
      message.success(this.props.t("Tenancies.messages.AddTenancy.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      });
      return
    }
  }

  async onUpdateSubmit() {
    const { form } = this.updateFormRef.props;
    let selectedItem = this.getSelectedItem();
    if (!selectedItem) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.updateTenancy(selectedItem.id, selectedItem.ownership_id, values);
      const updatedTenancy = responseData.tenancy;
      this.setState({
        selectedItemId: null,
        updateItemModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedTenancy.id]: updatedTenancy,
        },
      });
      message.success(this.props.t("Tenancies.messages.UpdateTenancy.Success"));
      return responseData;
    } catch (error) {
      selectedItem = this.getSelectedItem();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      return null;
    }
  }

  async onTerminateTenancySubmit() {
    const { form } = this.terminateTenancyFormRef.props;
    let selectedItem = this.getSelectedItem();
    if (!selectedItem) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.updateTenancyTerminateEarly(selectedItem.id, selectedItem.ownership_id, values);
      const updatedTenancy = responseData.tenancy;
      this.setState({
        selectedItemId: null,
        terminateTenancyModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedTenancy.id]: updatedTenancy,
        },
      });
      message.success(this.props.t("Tenancies.messages.TerminateTenancy.Success"));
      return responseData;
    } catch (error) {
      selectedItem = this.getSelectedItem();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      return null;
    }
  }

  async attachDocumentToTenancy(documentId) {
    let selectedItem = this.getSelectedItem();
    if (!selectedItem) {
      return
    }
    try {
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.attachDocumentToTenancy(selectedItem.id, documentId);
      const updatedTenancy = responseData.tenancy;
      this.setState({
        selectedItemId: null,
        attachDocumentModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedTenancy.id]: updatedTenancy,
        },
      });
      message.success(this.props.t("Documents.messages.AttachDocument.Success"));
      return responseData;
    } catch (error) {
      selectedItem = this.getSelectedItem();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      message.error(this.props.t("Documents.messages.AttachDocument.Fail"));
      throw error
    }
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  saveTerminateTenancyFormRef = formRef => {
    this.terminateTenancyFormRef = formRef;
  }

  render() {
    const selectedItem = this.getSelectedItem();
    return (
      <div>
        <Button
          type="primary" icon="plus"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ createModalVisible: true })}
        >
          { this.props.t("Tenancies.actions.AddTenancy") }
        </Button>
        <CreateForm
          wrappedComponentRef={ this.saveCreateFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false }) }
          onOk={ this.onCreateSubmit }
          ownershipId={ this.props.ownershipId }
          confirmLoading={ this.state.isCreating }
        />
        <AttachDocumentModal
          title={ selectedItem && `${ this.props.t("Tenancies.Tenancy") }: ${DateUtils.formatDateRange(selectedItem.start_date, selectedItem.early_termination_date || selectedItem.end_date)}` }
          ownershipId={ selectedItem && selectedItem.ownership_id }
          item={ selectedItem }
          visible={ !!selectedItem && this.state.attachDocumentModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, attachDocumentModalVisible: false }) }
          onAttachDocumentSubmit={ (documentId) => this.attachDocumentToTenancy(documentId) }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <UpdateForm
          wrappedComponentRef={ this.saveUpdateFormRef }
          tenancy={ selectedItem }
          visible={ !!selectedItem && this.state.updateItemModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, updateItemModalVisible: false }) }
          onOk={ this.onUpdateSubmit }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <TerminateTenancyForm
          wrappedComponentRef={ this.saveTerminateTenancyFormRef }
          tenancy={ selectedItem }
          visible={ !!selectedItem && this.state.terminateTenancyModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, terminateTenancyModalVisible: false }) }
          onOk={ this.onTerminateTenancySubmit }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <TenanciesList
          loading={ this.state.isFetching }
          tenancies={ Object.values(this.state.itemsById) }
          onClickAttachDocument={ (tenancyId) => this.setState({ selectedItemId: tenancyId, attachDocumentModalVisible: true })}
          onClickEdit={ (tenancyId) => this.setState({ selectedItemId: tenancyId, updateItemModalVisible: true }) }
          onClickTerminateEarly={ (tenancyId) => this.setState({ selectedItemId: tenancyId, terminateTenancyModalVisible: true }) }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchTenancies: (params = {}) => {
      return dispatch(TenanciesActions.fetchTenancies(params))
    },
    createTenancy: (ownershipId, params = {}) => {
      return dispatch(TenanciesActions.createTenancy(ownershipId, params));
    },
    updateTenancy: (tenancyId, ownershipId, params = {}) => {
      return dispatch(TenanciesActions.updateTenancy(tenancyId, ownershipId, params));
    },
    updateTenancyTerminateEarly: (tenancyId, ownershipId, params = {}) => {
      return dispatch(TenanciesActions.updateTenancyTerminateEarly(tenancyId, ownershipId, params));
    },
    attachDocumentToTenancy: (tenancyId, documentId) => {
      return dispatch(TenanciesActions.attachDocumentToTenancy(tenancyId, documentId));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Tenancies));
