import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Icon,
  message,
  Switch,
  Tooltip,
  Typography,
} from 'antd';

import { withTranslation } from 'react-i18next';
import numeral from 'numeral';
import dompurify from 'dompurify';

import * as DateUtils from '@utils/date';
import * as OwnershipActions from '@actions/ownerships';
import UpdateSettingsForm from './UpdateSettingsForm';
import UpdatePropertyInfoForm from './UpdatePropertyInfoForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isUpdating: false,
    errorUpdating: null,

    // modal
    isEditingSettings: false,
    isEditingInfo: false,

    ...state,
  };
}

class OwnershipSettings extends React.Component {
  static propTypes = {
    ownership: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onSubmitUpdateOwnershipSettings = this.onSubmitUpdateOwnershipSettings.bind(this);
    this.onSubmitUpdateOwnershipInfo = this.onSubmitUpdateOwnershipInfo.bind(this);
  }

  async onSubmitUpdateOwnershipSettings() {
    const { ownership } = this.props;
    const { form } = this.updateSettingsFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({ isUpdating: true });
      let responseData = await this.props.updateOwnershipSettings(ownership.id, values);
      this.setState({
        isEditingSettings: false,
        isUpdating: false,
      })
      message.success(this.props.t("OwnershipDetailSections.Settings.messages.UpdatedSettings.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("OwnershipDetailSections.Settings.messages.UpdatedSettings.Fail"));
      return null;
    }
  }

  async onSubmitUpdateOwnershipInfo() {
    const { ownership } = this.props;
    const { form } = this.updateInfoFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({ isUpdating: true });
      let responseData = await this.props.updateOwnershipInfo(ownership.id, values);
      this.setState({
        isEditingInfo: false,
        isUpdating: false,
      })
      message.success(this.props.t("OwnershipDetailSections.Settings.messages.UpdatePropertyInfo.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("OwnershipDetailSections.Settings.messages.UpdatePropertyInfo.Fail"));
      return null;
    }
  }

  saveUpdateSettingsFormRef = formRef => {
    this.updateSettingsFormRef = formRef;
  }

  saveUpdateInfoFormRef = formRef => {
    this.updateInfoFormRef = formRef;
  }

  render() {
    const { ownership } = this.props;

    const settings = [{
      title: this.props.t("Properties.PropertyName"),
      shouldRender: true,
      render: () => (ownership.name)
    // }, {
    //   title: "Send invoices",
    //   render: () => (
    //     <Switch
    //       disabled
    //       checkedChildren={<Icon type="check" />}
    //       unCheckedChildren={<Icon type="close" />}
    //       checked={ ownership.send_invoices || false }
    //     />
    //   )
    // }, {
    //   title: "Send payment reminders",
    //   render: () => (
    //     <Switch
    //       disabled
    //       checkedChildren={<Icon type="check" />}
    //       unCheckedChildren={<Icon type="close" />}
    //       checked={ ownership.send_payment_reminders || false }
    //     />
    //   )
    // }, {
    //   title: "Send receipts",
    //   render: () => (
    //     <Switch
    //       disabled
    //       checkedChildren={<Icon type="check" />}
    //       unCheckedChildren={<Icon type="close" />}
    //       checked={ ownership.send_receipts || false }
    //     />
    //   )
    }, {
      title: "Managed by GV Properties", // TODO: translate
      titleHelper: () => (
        <Tooltip title={ "Payments will be made to GV Properties Ltd account. Contact GV Properties to change this setting." }>
          { /* TODO: translate */ }
          <Icon type="info-circle" style={{ fontSize: 18, marginLeft: 10 }} />
        </Tooltip>
      ),
      shouldRender: ownership.is_accounts_managed_by_gv,
      render: () => (
        <Switch
          disabled
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          checked={ ownership.is_accounts_managed_by_gv || false }
        />
      )
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.BankName"),
      shouldRender: true,
      render: () => (ownership.bank_name)
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.BankSwift"),
      shouldRender: true,
      render: () => (ownership.bank_swift_code),
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.BankAddress"),
      shouldRender: true,
      render: () => (ownership.bank_address),
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.BankAccountName"),
      shouldRender: true,
      render: () => (ownership.bank_account_name)
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.BankAccountNumber"),
      shouldRender: true,
      render: () => (ownership.bank_account_number)
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.IncludeSignature"),
      shouldRender: true,
      render: () => (
        <Checkbox.Group
          disabled
          options={[
            { label: this.props.t("OwnershipDetailSections.Settings.IncludeSignatureDebitNote"), value: "Invoice" },
            { label: this.props.t("OwnershipDetailSections.Settings.IncludeSignatureReceipt"), value: "Receipt" },
          ]}
          value={ ownership.include_signature_for_autogenerated }
        />
      ),
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.DebitNoteFormat"),
      shouldRender: true,
      render: () => (ownership.invoice_number_generator),
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.DebitNoteRemarks"),
      shouldRender: true,
      render: () => ownership.invoice_remarks && (
        <div
          style={{ borderWidth: 1, borderStyle: 'solid', padding: 10 }}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(ownership.invoice_remarks),
          }}
        />
      )
    }];

    const info = [{
      title: this.props.t("Properties.PropertyType"),
      shouldRender: true,
      render: () => (ownership.usage_type),
    }, {
      title: this.props.t("Properties.Address"),
      shouldRender: true,
      render: () => (ownership.address),
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.NetArea"),
      shouldRender: true,
      render: () => (ownership.net_sqft) ? `${numeral(ownership.net_sqft).format('0,0')} sqft.` : null,
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.GrossArea"),
      shouldRender: true,
      render: () => (ownership.gross_sqft) ? `${numeral(ownership.gross_sqft).format('0,0')} sqft.` : null,
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.PurchasePrice"),
      shouldRender: true,
      render: () => (ownership.purchase_price) ? numeral(ownership.purchase_price).format('($0,0)') : null,
    }, {
      title: this.props.t("OwnershipDetailSections.Settings.PurchaseDate"),
      shouldRender: true,
      render: () => ownership.purchase_date ? DateUtils.formatDate(ownership.purchase_date) : null,
    }, {
      title: "Rent and Rates Account Number", // TODO: translate
      shouldRender: !!ownership.rent_and_rates_account_number,
      render: () => ownership.rent_and_rates_account_number,
    }, {
      title: this.props.t("shared.Notes"),
      shouldRender: true,
      render: () => (
        <div style={{ 'whiteSpace': 'pre-line' }}>
          { ownership.notes }
        </div>
      ),
    }]

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <div>
        <UpdateSettingsForm
          wrappedComponentRef={ this.saveUpdateSettingsFormRef }
          ownership={ ownership }
          visible={ this.state.isEditingSettings }
          confirmLoading={ this.state.isUpdating }
          onCancel={ () => this.setState({ isEditingSettings: false }) }
          onOk={ () => this.onSubmitUpdateOwnershipSettings() }
        />
        <UpdatePropertyInfoForm
          wrappedComponentRef={ this.saveUpdateInfoFormRef }
          ownership={ ownership }
          visible={ this.state.isEditingInfo }
          confirmLoading={ this.state.isUpdating }
          onCancel={ () => this.setState({ isEditingInfo: false }) }
          onOk={ () => this.onSubmitUpdateOwnershipInfo() }
        />
        <div style={{ marginBottom: 50 }}>
          <Divider orientation="left">Settings</Divider>
          <Button
            type="primary" icon="edit"
            style={{ marginBottom: 10 }}
            onClick={ () => this.setState({ isEditingSettings: true }) }
          >
            { this.props.t("OwnershipDetailSections.Settings.actions.UpdateSettings") }
          </Button>
          <Form layout="vertical">
            {
              settings.filter(({ shouldRender}) => { return shouldRender }).map(item => (
                <Form.Item
                  key={ `${item.title}-row` }
                  label={
                    <React.Fragment>
                      <Typography.Text strong style={{ fontSize: 16 }}>
                        { item.title }
                      </Typography.Text>
                      { item.titleHelper && item.titleHelper() }
                    </React.Fragment>
                  }
                  style={{ margin: 0, fontSize: 16 }}
                  {...formItemLayout}
                >
                  { item.render() }
                </Form.Item>
              ))
            }
          </Form>
        </div>
        <div>
          <Divider orientation="left">Property Details</Divider>
            <Button
              type="primary" icon="edit"
              style={{ marginBottom: 10 }}
              onClick={ () => this.setState({ isEditingInfo: true }) }
            >
              { this.props.t("OwnershipDetailSections.Settings.actions.UpdateDetails") }
            </Button>
          <Form layout="vertical">
            {
              info.filter(({ shouldRender}) => { return shouldRender }).map(item => (
                <Form.Item
                  key={ `${item.title}-row` }
                  label={
                    <Typography.Text strong style={{ fontSize: 16 }}>
                      { item.title }
                    </Typography.Text>
                  }
                  style={{ margin: 0, fontSize: 16 }}
                  {...formItemLayout}
                >
                  { item.render() }
                </Form.Item>
              ))
            }
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOwnershipSettings: (ownershipId, values) => {
      return dispatch(OwnershipActions.updateOwnershipSettings(ownershipId, values));
    },
    updateOwnershipInfo: (ownershipId, values) => {
      return dispatch(OwnershipActions.updateOwnershipInfo(ownershipId, values));
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OwnershipSettings));
