import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Row,
  Select,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import '@components/FilterForm.scss';
import * as ArrayUtils from '@utils/array';
import * as OwnersActions from '@actions/owners';

class FilterForm extends React.Component {
  static propTypes = {
    showPropertyName: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    filteredInfo: PropTypes.shape({
      selectedAccountEntryTypes: PropTypes.array,
    }).isRequired,
  }

  static defaultProps = {
    showPropertyName: false,
    loading: false,
    onSubmit: (filteredInfo) => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: { ...this.props.filteredInfo },
      isFetchingOwners: false,
      owners: {},
      errorFetchingOwners: null,
      showAdvancedSearch: false,
    };
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    if (props.showPropertyName) {
      this.fetchOwners();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filteredInfo !== this.props.filteredInfo) {
      this.setState({
        filteredInfo: this.props.filteredInfo,
      });
    }
  }

  async fetchOwners(params = {}) {
    this.setState({ isFetchingOwners: true })
    try {
      let responseData = await this.props.fetchOwners({
        ...params,
      });
      this.setState({
        isFetchingOwners: false,
        errorFetchingOwners: null,
        owners: {
          ...ArrayUtils.arrayToObject(responseData.owners),
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetchingOwners: false,
        errorFetchingOwners: error,
        owners: {},
      });
      return
    }
  }

  isDirty() {
    const keys = Object.keys(this.state.filteredInfo);
    return keys.some((key) => this.props.filteredInfo[key] !== this.state.filteredInfo[key])
  }

  onSubmit() {
    this.props.onSubmit(this.state.filteredInfo);
  }

  onChangeFilter(key, value) {
    this.setState({
      filteredInfo: {
        ...this.state.filteredInfo,
        [key]: value,
      },
    });
  }

  getFormItems() {
    const items = [{
      label: this.props.t("shared.Type"),
      shouldRender: true,
      span: 8,
      renderChildren: () => (
        <Select
          mode="multiple"
          placeholder={ this.props.t("shared.AllTypes") }
          value={ this.state.filteredInfo.selectedAccountEntryTypes }
          onChange={ (values) => this.onChangeFilter('selectedAccountEntryTypes', values) }
        >
          {
            Object.values(this.props.AccountEntryTypeOptions).map((accountEntryTypeOption) => (
              <Select.Option key={ accountEntryTypeOption.value }>
                { accountEntryTypeOption.text }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }, {
      label: this.props.t("shared.Date"),
      shouldRender: true,
      span: 8,
      renderChildren: () => (
        <DatePicker.RangePicker
          format={ "YYYY/MM/DD" }
          ranges={{
            'Last 1 Month': [moment().subtract(1, 'months'), moment()],
            'Last 3 Months': [moment().subtract(3, 'months'), moment()],
            'Last 6 Months': [moment().subtract(6, 'months'), moment()],
          }}
          onChange={ (dates, dateStrings) => this.onChangeFilter('accountsDateRange', dates) }
        />
      ),
    }, {
      label: this.props.t("shared.Status"),
      shouldRender: true,
      span: 8,
      renderChildren: () => (
        <Select
          mode="multiple"
          placeholder={ this.props.t("shared.AllStatuses") }
          value={ this.state.filteredInfo.selectedAccountStates }
          onChange={ (values) => this.onChangeFilter('selectedAccountStates', values) }
        >
          {
            Object.values(this.props.AccountEntryStatusOptions).map((accountEntryStatusOption) => (
              <Select.Option key={ accountEntryStatusOption.value }>
                { this.props.t(accountEntryStatusOption.ttextKey) }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }, {
      label: this.props.t("Properties.Property"),
      shouldRender: this.props.showPropertyName,
      span: 8,
      renderChildren: () => (
        <Input
          placeholder={ this.props.t("Properties.PropertyName") }
          onChange={ (e) => this.onChangeFilter('displayName', e.target.value) }
        />
      ),
    }, {
      label: this.props.t("Properties.Address"),
      shouldRender: this.props.showPropertyName,
      span: 8,
      renderChildren: () => (
        <Input
          placeholder={ this.props.t("Properties.PropertyAddress") }
          onChange={ (e) => this.onChangeFilter('address', e.target.value) }
        />
      ),
    }, {
      label: this.props.t("Owners.Title"),
      shouldRender: this.props.showPropertyName,
      span: 8,
      renderChildren: () => (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={ this.props.t("shared.AllOwners") }
          value={ this.state.filteredInfo.selectedOwners }
          onChange={ (values) => this.onChangeFilter('selectedOwners', values) }
          loading={ this.state.isFetchingOwners }
          filterOption={ (inputValue, option) => {
            const ownerId = parseInt(option.key);
            const owner = this.state.owners[ownerId];
            return (owner.name.toLowerCase().includes(inputValue.toLowerCase()))
          }}
        >
          {
            Object.values(this.state.owners).map((owner) => (
              <Select.Option key={ owner.id }>
                { owner.name }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }, {
      label: this.props.t("AccountEntries.Reference"),
      shouldRender: this.state.showAdvancedSearch,
      span: 8,
      renderChildren: () => (
        <Input
          placeholder={ this.props.t("AccountEntries.Reference") }
          onChange={ (e) => this.onChangeFilter('reference', e.target.value) }
        />
      ),
    }]

    const formItemLayout = {
      labelCol: {
        lg: { span: 6, },
        xl: { span: 5  },
      },
      wrapperCol: {
        lg: { span: 18 },
        xl: { span: 19 },
      },
    };
    return items.filter(({ shouldRender }) => { return shouldRender }).map((item, index) => {
      return (
        <Col span={ item.span || 8 } key={ index.toString() }>
          <Form.Item
            label={ item.label }
            { ...formItemLayout }
            style={{ marginBottom: 10 }}
          >
            { item.renderChildren() }
          </Form.Item>
        </Col>
      )
    });
  }

  render() {
    const buttonItemLayout = {
      wrapperCol: {
        lg: { span: 18, offset: 6 },
        xl: { span: 18, offset: 5 }
      },
    };

    const formItems = this.getFormItems();
    return (
      <div>
        <Form className="ant-advanced-search-form">
          <Row
            gutter={ 24 }
            justify="space-between"
          >
            { this.getFormItems() }
          </Row>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="primary" icon="search"
              disabled={ !this.isDirty() }
              loading={ this.props.loading }
              onClick={ this.onSubmit }
            >
              { this.props.t("actions.Search") }
            </Button>
            <Button
              type="link"
              onClick={ () => this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch }) }
            >
              { this.state.showAdvancedSearch ? <Icon type="up" /> : <Icon type="down" /> } { this.state.showAdvancedSearch ? this.props.t("actions.Collapse") : this.props.t("actions.Expand") }
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {
    AccountEntryStatusOptions: state.accountEntriesReducer.AccountEntryStatusOptions,
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwners: (params = {}) => {
      return dispatch(OwnersActions.fetchOwners(params))
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(FilterForm));
