import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import {
  Avatar,
  Icon,
  List,
  Popconfirm,
} from 'antd';

class OwnershipManagersList extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    dataSource: PropTypes.array,
    onDestroyOwnershipManager: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loading: false,
    dataSource: [],
    onDestroyOwnershipManager: (ownershipManagerId) => {},
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { onDestroyOwnershipManager, currentUserId, ...rest } = this.props;
    return (
      <List
        itemLayout="horizontal"
        renderItem={item => (
          <List.Item
            extra={
              (currentUserId !== item.user_id) ? (
                <Popconfirm
                  title={ `${this.props.t("OwnershipDetailSections.Team.RemoveUserConfirm") }?`}
                  onConfirm={ () => onDestroyOwnershipManager(item.id) }
                  onCancel={ () => {} }
                  okText={ this.props.t("shared.ConfirmYes") }
                  cancelText={ this.props.t("sharedConfirmNo") }
                >
                  <span style={{ color: 'red', cursor: 'pointer' }}>
                    { this.props.t("OwnershipDetailSections.Team.RemoveUser") }
                  </span>
                </Popconfirm>
              ) : null
            }
          >
            <List.Item.Meta
              avatar={<Avatar icon="user" src={ item.avatar_url } />}
              title={ item.display_name }
              description={ item.role }
            />
          </List.Item>
        )}
        { ...rest }
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const me = state.usersReducer.me || {};
  return {
    currentUserId: me.id,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OwnershipManagersList));
