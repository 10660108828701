import React from 'react';
import PropTypes from 'prop-types';

import {
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Switch,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

class UpdatePropertyInfoForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    ownership: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      return
    }
  }

  render() {
    const { visible, onCancel, onOk, confirmLoading, form, ownership } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        visible={ visible }
        width="70%"
        title={ this.props.t("OwnershipDetailSections.Settings.actions.UpdateDetails") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ onCancel }
        onOk={ onOk }
        confirmLoading={ confirmLoading }
      >
        { this.props.children }
        <Form layout="vertical">
          <Form.Item
            hasFeedback
            label={ this.props.t("Properties.Address") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('address', {
              initialValue: ownership.address,
              rules: [
                { required: true, message: 'Please input a property address' },
              ],
            })(
              <Input.TextArea rows={ 4 } />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.NetArea") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('net_sqft', {
              initialValue: ownership.net_sqft,
              rules: [
                { required: false, message: 'Please input a property area' },
              ],
            })(
              <InputNumber
                style={{ width: '100%', minWidth: '150px' }}
                min={ 0 }
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.GrossArea") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('gross_sqft', {
              initialValue: ownership.gross_sqft,
              rules: [
                { required: false, message: 'Please input a property area' },
              ],
            })(
              <InputNumber
                style={{ width: '100%', minWidth: '150px' }}
                min={ 0 }
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.PurchasePrice") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('purchase_price', {
              initialValue: ownership.purchase_price,
              rules: [{ required: false, message: 'Please input purchase price' }],
            })(
              <InputNumber
                style={{ width: '100%', minWidth: '150px' }}
                min={ 0 }
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.PurchaseDate") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("purchase_date", {
              initialValue: ownership.purchase_date ? moment(ownership.purchase_date) : null,
              rules: [{ required: false, message: 'Please input purchase date!' }],
            })(
              <DatePicker format={"YYYY/MM/DD"} />
            )}
          </Form.Item>
          {/* TODO: translate */}
          <Form.Item
            hasFeedback
            label={ "Rent and Rates Account Number" }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('rent_and_rates_account_number', {
              initialValue: ownership.rent_and_rates_account_number,
              rules: [
                { required: false, message: 'Please input account number' },
              ],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("shared.Notes") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("notes", {
              initialValue: ownership.notes,
              rules: [{ required: false, message: 'Please input notes!' }],
            })(
              <Input.TextArea
                rows={ 4 }
                placeholder={ this.props.t("shared.Notes") }
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedUpdatePropertyInfoForm = Form.create({ name: 'ownership_info_update_form_in_modal' })(UpdatePropertyInfoForm);

export default withTranslation('common')(WrappedUpdatePropertyInfoForm);
