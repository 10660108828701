import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  Carousel,
  Col,
  Descriptions,
  Icon,
  Radio,
  Row,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';

import moment from 'moment';
import numeral from 'numeral';
import dompurify from 'dompurify';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class ListingEventsTableComponent extends React.Component {
  static propTypes = {
    dataSource: PropTypes.array,
  }

  static defaultProps = {
    dataSource: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        current: 1,
        pageSize: 10,
      },
      sorter: {},
    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(pagination, filters, sorter) {
    this.setState({
      pagination,
      sorter,
    })
  }

  render() {
    let { sorter } = this.state;

    let columns = [{
      title: this.props.t("shared.Date"),
      dataIndex: 'event_time',
      key: 'event_time',
      width: 150,
      sorter: (a, b) => moment(a.event_time) - moment(b.event_time),
      sortOrder: sorter.columnKey === 'event_time' && sorter.order,
      render: (event_time) => DateUtils.formatDate(event_time),
    }, {
      title: this.props.t("Listings.AgentEventsTable.Agent"),
      dataIndex: 'property_agent_name',
      key: 'property_agent_name',
      render: (property_agent_name, record) => {
        return (!!record.property_agent_mobile) ? (
          <Tooltip placement="top" title={ record.property_agent_mobile }>
            { property_agent_name }
          </Tooltip>
        ) : property_agent_name;
      },
    }, {
      title: this.props.t("Listings.AgentEventsTable.Agency"),
      dataIndex: 'property_agency_name',
      key: 'property_agency_name',
    }, {
      title: this.props.t("Listings.AgentEventsTable.Event"),
      dataIndex: 'event_type_display_name',
      key: 'event_type_display_name',
      width: 200,
    }, {
      title: this.props.t("shared.Notes"),
      dataIndex: 'note',
      key: 'note',
    }];

    return (
      <Table
        rowKey={ record => record.id }
        columns={ columns }
        onChange={ this.onChange }
        pagination={{ pageSize: 100 }}
        scroll={{ y: 400 }}
        { ...this.props }
      />
    )
  }
}
const ListingEventsTable = withTranslation('common')(ListingEventsTableComponent)

class ListingShow extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    const eventTypeTags = Object.values(props.ListingEventTypeOptions).map((listingEventType) => listingEventType.value);
    this.state = {
      selectedListingEventTypeTags: eventTypeTags,
      listingEventSummaryRange: "4-weeks",
    };
    this.handleListingEventTagChange = this.handleListingEventTagChange.bind(this);
  }

  handleListingEventTagChange(tag, checked) {
    const { selectedListingEventTypeTags } = this.state;
    const nextSelectedTags = checked ? [...selectedListingEventTypeTags, tag] : selectedListingEventTypeTags.filter(t => t !== tag);
    this.setState({ selectedListingEventTypeTags: nextSelectedTags });
  }

  renderCarousel(listing) {
    if (listing.images.length == 0) {
      return
    }
    return (
      <div style={{ width: '800px', height: '300px', margin: '30px 0px' }}>
        <Carousel autoplay>
          {
            listing.images.map((image) => (
              <div key={ image.attachment_id.toString() }>
                <img
                  style={{ width: 'auto', height: '300px', margin: '0px auto', display: 'block' }}
                  src={ image.attachment_preview_url || image.attachment_url }
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    )
  }

  renderDescriptions(listing) {
    const isRental = (listing.listing_type == "rent")
    const descriptions = [
      {
        label: this.props.t("Listings.ListingName"),
        shouldRender: true,
        span: 1,
        render: () => listing.name,
      }, {
        label: this.props.t("Listings.AvailableFrom"),
        shouldRender: true,
        span: 1,
        render: () => !!listing.available_date && DateUtils.formatDate(listing.available_date),
      }, {
        label: this.props.t("Listings.PreviewUrl"),
        shouldRender: true,
        span: 1,
        render: () => <a href={ listing.agent_path } target="_blank">{ this.props.t("Listings.actions.ViewListing") }</a>,
      }, {
        label: this.props.t("Listings.MonthlyAmount"),
        shouldRender: isRental,
        span: 1,
        render: () => listing.amount && <span>{ numeral(listing.amount).format('($0,0)') } / month</span>
      }, {
        label: this.props.t("shared.SecurityDeposit"),
        shouldRender: isRental,
        span: 1,
        render: () => listing.security_deposit && <span>{ numeral(listing.security_deposit).format('($0,0)') }</span>
      }, {
        label: this.props.t("Listings.SaleAmount"),
        shouldRender: !isRental,
        span: 2,
        render: () => <span>{ numeral(listing.amount).format('($0,0)') }</span>
      }, {
        label: this.props.t("Listings.AgentCommission"),
        shouldRender: true,
        span: 1,
        render: () => listing.agent_commission_fixed && <span>{ numeral(listing.agent_commission_fixed).format('($0,0)') }</span>,
      }, {
        label: this.props.t("Listings.FloorArea"),
        shouldRender: true,
        span: 3,
        render: () => listing.ownership.sqft_display_text && <span>{ listing.ownership.sqft_display_text }</span>,
      }, {
        label: this.props.t("Listings.LongDescription"),
        shouldRender: true,
        span: 3,
        render: () => (
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(listing.description),
            }}
          />
        ),
      }, {
        label: this.props.t("Listings.InternalNotes"),
        shouldRender: true,
        span: 3,
        render: () => (
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(listing.internal_notes),
            }}
          />
        ),
      }, {
        label: this.props.t("Listings.Photos"),
        shouldRender: true,
        span: 3,
        render: () => this.renderCarousel(listing),
      },
    ]
    return (
      <Descriptions
        title={ null }
        bordered
      >
        {
          descriptions.filter(({ shouldRender }) => shouldRender).map((item, index) => (
            <Descriptions.Item
              key={ index.toString() }
              label={ <strong>{ item.label }</strong> }
              span={ item.span }
            >
              { item.render() }
            </Descriptions.Item>
          ))
        }
      </Descriptions>
    )
  }

  renderActionButtons(listing) {
    return (
      <Button.Group>
        <Button
          type="primary"
          onClick={ () => this.props.onClickUpdate(listing) }
          icon="edit"
        >
          { this.props.t("Listings.actions.UpdateListing") }
        </Button>
        <Button
          onClick={ () => this.props.onClickBroadcast(listing) }
        >
          { this.props.t("Listings.actions.BroadcastListing") }
        </Button>
        <Button
          onClick={ () => this.props.onClickCloseListing(listing) }
          icon="close"
          type="danger"
          ghost
        >
          { this.props.t("Listings.actions.CloseListing") }
        </Button>
      </Button.Group>
    )
  }

  render() {
    const { listing } = this.props;
    const listingEvents = listing.listing_property_agent_events;
    const filteredEvents = listingEvents.filter((listingEvent) => {
      return (this.state.selectedListingEventTypeTags.indexOf(listingEvent.event_type_value) > -1)
    }).map((listingEvent) => {
      return {
        ...listingEvent,
        event_type_display_name: this.props.ListingEventTypeOptions[listingEvent.event_type_value].text,
      }
    })
    const countEvents = (event_type) => {
      let events = listingEvents.filter((listingEvent) => listingEvent.event_type_value === event_type);
      events = events.filter((listingEvent) => {
        if (this.state.listingEventSummaryRange === "4-weeks") {
          return moment().diff(moment(listingEvent.event_time), 'weeks') < 4;
        } else if (this.state.listingEventSummaryRange === "2-weeks") {
          return moment().diff(moment(listingEvent.event_time), 'weeks') < 2;
        } else {
          return true;
        }
      })
      return events.length;
    };
    const listingStatistics = [
      {
        title: "Broadcast via Whatsapp",
        value: countEvents("broadcast_whatsapp"),
        prefix: <Icon type="message" />,
      }, {
        title: "Outgoing Call",
        value: countEvents("outgoing_call"),
        prefix: <Icon type="phone" />,
      }, {
        title: "Inquiry for property",
        value: countEvents("inquiry"),
        prefix: <Icon type="question-circle" />,
      }, {
        title: "Property Viewing",
        value: countEvents("onsite_viewing"),
        prefix: <Icon type="eye" />,
      },
    ]
    return (
      <div>
        {
          listing.closed_at ?
            <h2>{ this.props.t("Listings.ListingClosedOn") }: { DateUtils.formatDate(listing.closed_at) }</h2> :
            this.renderActionButtons(listing)
        }
        { this.renderDescriptions(listing) }
        <Tabs>
          <Tabs.TabPane tab="Events" key="events">
            <div>
              <span style={{ marginRight: 8 }}><strong>Event Types:</strong></span>
              {
                Object.values(this.props.ListingEventTypeOptions).map((listingEventType) => {
                  const key = listingEventType.value;
                  return (
                    <Tag.CheckableTag
                      key={ key }
                      checked={ this.state.selectedListingEventTypeTags.indexOf(key) > -1 }
                      onChange={ checked => this.handleListingEventTagChange(key, checked) }
                    >
                      { listingEventType.text }
                    </Tag.CheckableTag>
                  )
                })
              }
            </div>
            <ListingEventsTable
              dataSource={ filteredEvents }
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Summary" key="summary">
            <Radio.Group
              buttonStyle="solid"
              value={ this.state.listingEventSummaryRange }
              onChange={ (e) => {
                this.setState({ listingEventSummaryRange: e.target.value });
              }}
            >
              <Radio.Button value="all-weeks">All</Radio.Button>
              <Radio.Button value="4-weeks">1 month</Radio.Button>
              <Radio.Button value="2-weeks">2 weeks</Radio.Button>
            </Radio.Group>
            <Row gutter={ 16 }>
              {
                listingStatistics.map(({ title, value, prefix }, index) => {
                  const card = (
                    <Card>
                      <Statistic
                        title={ title }
                        value={ value }
                        prefix={ prefix }
                      />
                    </Card>
                  )
                  return (
                    <Col span={ 6 } key={ index.toString() }>
                      { card }
                    </Col>
                  )
                })
              }
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ListingEventTypeOptions: state.listingsReducer.ListingEventTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ListingShow));
