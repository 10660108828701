import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
} from 'antd';

import numeral from 'numeral';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';
import {
  ThemeColors,
} from '@constants/Theme';

const RESET_STATE = (state = {}) => {
  return {
    ...state,
  }
}
class AddPaymentForm extends React.Component {
  static propTypes = {
    confirmLoading: PropTypes.bool,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    confirmLoading: false,
    visible: false,
    onCancel: () => {},
    onOk: () => {},
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
    }
  }

  renderForm() {
    const { accountEntry, form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          { ...formItemLayout }
        >
          { accountEntry.ownership.name }
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.EntryType") }
          { ...formItemLayout }
        >
          { this.props.AccountEntryTypeOptions[accountEntry.entry_type].text }. { DateUtils.formatDate(accountEntry.due_date) }
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.OutstandingAmount") }
          { ...formItemLayout }
        >
          { numeral(Math.abs(accountEntry.amount - accountEntry.reconciled_amount)).format('($0,0.[00])') }
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.PaymentDate") }
          {...formItemLayout}
        >
          {getFieldDecorator("payment_date", {
            rules: [{ required: true, message: 'Please input payment date!' }],
          })(
            <DatePicker format={"YYYY/MM/DD"} />
          )}
        </Form.Item>
        <Form.Item
          label={
            <span>{ this.props.t("shared.Amount") } <strong>{ (accountEntry.amount > 0) ? this.props.t("AccountEntries.received") : this.props.t("AccountEntries.spent") }</strong></span>
          }
          {...formItemLayout}
        >
          {getFieldDecorator('payment_amount', {
            rules: [{ required: true, message: 'Please input amount' }],
          })(
            <InputNumber
              style={{ width: '100%', minWidth: '150px' }}
              step={ 0.01 }
              min={ 0 }
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.Reference") }
          { ...formItemLayout }
        >
          {getFieldDecorator("reference_number", {
          })(
            <Input placeholder="E.g. Cheque #11111, Deposit #888888" />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Notes") }
          { ...formItemLayout }
        >
          {getFieldDecorator("note", {
          })(
            <Input.TextArea rows={4} placeholder={ this.props.t("shared.Notes") } />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    if (!this.props.accountEntry) {
      return null;
    }

    return (
      <Modal
        visible={this.props.visible}
        title={ this.props.t("AccountEntries.actions.AddPayment") }
        okText={ this.props.t("actions.Submit") }
        onCancel={this.props.onCancel}
        onOk={this.props.onOk}
        confirmLoading={this.props.confirmLoading}
      >
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedAddPaymentForm = connect(mapStateToProps, mapDispatchToProps)(AddPaymentForm);

const WrappedAddPaymentForm = Form.create({ name: 'account_entry_add_payment_form_in_modal' })(ConnectedAddPaymentForm);

export default withTranslation('common')(WrappedAddPaymentForm);
