import React from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  Button,
  Layout,
} from 'antd';

import { LayoutStyle } from '@constants/Theme'
import LoginForm from '@views/Authentication/LoginForm';
import SignUpForm from '@views/Authentication/SignUpForm';
import ForgotPasswordForm from '@views/Authentication/ForgotPasswordForm';
import NotFound from './NotFound';

const routes = [
  {
    key: "/login",
    component: LoginForm,
  },{
    key: "/sign_up",
    component: SignUpForm,
  },{
    key: "/forgot_password",
    component: ForgotPasswordForm,
  },
];

class UnauthenticatedRouter extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSider() {
    return (
      <Layout.Sider
      >
        <Link to="/">
          <h3
            style={{
              ...LayoutStyle.siderTitle,
              color: 'white',
            }}
          >
            { this.props.orgName }
          </h3>
        </Link>
      </Layout.Sider>
    )
  }

  render() {
    return (
      <Layout>
        { this.renderSider() }
        <Layout.Content style={ LayoutStyle.routerContentStyle }>
          <Switch>
            <Redirect exact from="/" to={ routes[0].key } />
            {
              routes.map((route, index) => (
                <Route
                  key={ index.toString() }
                  path={ route.key }
                  component={ route.component }
                />
              ))
            }
            <Redirect to={{ pathname: '/login', state: { from: this.props.location }}} />
            <Route component={ NotFound } />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    orgName: state.organizationReducer.name,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnauthenticatedRouter);
