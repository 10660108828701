import * as ActionTypes from '@actions/types';

export const initialState = {
  neighbourhoodsById: {
  },
  repairsDataTagsById: {
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
}
