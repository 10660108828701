import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchOwnerships = (params = {}) => {
  return Api.get(
    `ownerships`,
    ActionTypes.FETCH_OWNERSHIPS,
    params,
  );
}

export const fetchOwnershipsWithManagers = (params = {}) => {
  return Api.get(
    `ownerships/index_with_ownership_managers`,
    ActionTypes.FETCH_OWNERSHIPS_WITH_MANAGERS,
    params,
  );
}

export const fetchOwnership = (ownershipId, params = {}) => {
  return Api.get(
    `ownerships/${ownershipId}`,
    ActionTypes.FETCH_OWNERSHIP,
    params,
    {
      // metaData
      ownershipId,
    }
  );
}

export const fetchOwnershipManagers = (ownershipId, params = {}) => {
  return Api.get(
    `ownerships/${ownershipId}/ownership_managers`,
    ActionTypes.FETCH_OWNERSHIP_MANAGERS,
    params,
    {
      // metaData
      ownershipId,
    }
  );
}

export const createOwnership = (values) => {
  return Api.post(
    `ownerships`,
    ActionTypes.CREATE_OWNERSHIP,
    values,
  )
}

export const addOwnershipManager = (ownershipId, values) => {
  return Api.post(
    `ownerships/${ownershipId}/add_ownership_manager`,
    ActionTypes.ADD_OWNERSHIP_MANAGER,
    values,
    {
      // metaData
      ownershipId,
    }
  )
}

export const addOwnerToOwnership = (ownershipId, values) => {
  return Api.postUpload(
    `ownerships/${ownershipId}/add_owner`,
    ActionTypes.ADD_OWNER_TO_OWNERSHIP,
    {
      ...values,
      ownership_id: ownershipId,
    },
    {
      // metaData
      ownershipId,
    }
  )
}

export const fetchUnprocessedDocuments = (ownershipId) => {
  return Api.get(
    `ownerships/${ownershipId}/unprocessed_documents`,
    ActionTypes.FETCH_OWNERSHIP_UNPROCESSED_DOCUMENTS,
    {},
  )
}

export const updateOwnershipSettings = (ownershipId, values) => {
  return Api.post(
    `ownerships/${ownershipId}/update_settings`,
    ActionTypes.UPDATE_OWNERSHIP_DETAILS,
    values,
    {
      // metaData
      ownershipId,
    }
  )
}

export const updateOwnershipInfo = (ownershipId, values) => {
  return Api.post(
    `ownerships/${ownershipId}/update_property_info`,
    ActionTypes.UPDATE_OWNERSHIP_DETAILS,
    values,
    {
      // metaData
      ownershipId,
    }
  )
}
