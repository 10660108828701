export const CALL_API_REQUEST = 'CALL_API_REQUEST';
export const CALL_API_SUCCESS = 'CALL_API_SUCCESS';
export const CALL_API_ERROR = 'CALL_API_ERROR';

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';

export const FETCH_PORTFOLIO_SUMMARY = 'FETCH_PORTFOLIO_SUMMARY';
export const FETCH_NET_CASH_FLOW = 'FETCH_NET_CASH_FLOW';
export const FETCH_PORTFOLIO_IMPORTANT_DATES = 'FETCH_PORTFOLIO_IMPORTANT_DATES';
export const FETCH_OWNERSHIPS_WITH_TRANSITION = 'FETCH_OWNERSHIPS_WITH_TRANSITION';
export const FETCH_PORTFOLIO_TEAM_MEMBERS = 'FETCH_PORTFOLIO_TEAM_MEMBERS';

export const FETCH_OWNERSHIPS = 'FETCH_OWNERSHIPS';
export const FETCH_OWNERSHIPS_WITH_MANAGERS = 'FETCH_OWNERSHIPS_WITH_MANAGERS';
export const FETCH_OWNERSHIP = 'FETCH_OWNERSHIP';
export const CREATE_OWNERSHIP = 'CREATE_OWNERSHIP';
export const ADD_OWNERSHIP_MANAGER = 'ADD_OWNERSHIP_MANAGER';
export const ADD_OWNER_TO_OWNERSHIP = 'ADD_OWNER_TO_OWNERSHIP';
export const FETCH_OWNERSHIP_UNPROCESSED_DOCUMENTS = 'FETCH_OWNERSHIP_UNPROCESSED_DOCUMENTS';
export const UPDATE_OWNERSHIP_DETAILS = 'UPDATE_OWNERSHIP_DETAILS';

export const FETCH_OWNERSHIP_MANAGERS = 'FETCH_OWNERSHIP_MANAGERS';
export const DESTROY_OWNERSHIP_MANAGER = 'DESTROY_OWNERSHIP_MANAGER';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DESTROY_DOCUMENT = 'DESTROY_DOCUMENT';

export const FETCH_TASKS = 'FETCH_TASKS';
export const CREATE_TASK = "CREATE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DESTROY_TASK = "DESTROY_TASK";
export const DELETE_TASK_ATTACHMENT = 'DELETE_TASK_ATTACHMENT';

export const FETCH_TENANCIES = 'FETCH_TENANCIES';
export const CREATE_TENANCY = 'CREATE_TENANCY';
export const UPDATE_TENANCY = 'UPDATE_TENANCY';
export const TENANCY_ATTACH_DOCUMENT = 'TENANCY_ATTACH_DOCUMENT';

export const FETCH_ACCOUNT_ENTRIES = 'FETCH_ACCOUNT_ENTRIES';
export const FETCH_ACCOUNT_ENTRY = 'FETCH_ACCOUNT_ENTRY';
export const CREATE_ACCOUNT_ENTRY = 'CREATE_ACCOUNT_ENTRY';
export const CREATE_ACCOUNT_ENTRY_LINE_ITEM = 'CREATE_ACCOUNT_ENTRY_LINE_ITEM';
export const UPDATE_ACCOUNT_ENTRY_LINE_ITEM = 'UPDATE_ACCOUNT_ENTRY_LINE_ITEM';
export const DELETE_ACCOUNT_ENTRY_LINE_ITEM = 'DELETE_ACCOUNT_ENTRY_LINE_ITEM';
export const ACCOUNT_ENTRY_SEND_INVOICE = 'ACCOUNT_ENTRY_SEND_INVOICE';
export const ACCOUNT_ENTRY_SEND_PAYMENT_REMINDER = 'ACCOUNT_ENTRY_SEND_PAYMENT_REMINDER';
export const ACCOUNT_ENTRY_SEND_RECEIPT = 'ACCOUNT_ENTRY_SEND_RECEIPT';
export const ACCOUNT_ENTRY_ATTACH_DOCUMENT = 'ACCOUNT_ENTRY_ATTACH_DOCUMENT';
export const ACCOUNT_ENTRY_ADD_PAYMENT = 'ACCOUNT_ENTRY_ADD_PAYMENT';
export const ACCOUNT_ENTRY_MARK_AS_VOID = 'ACCOUNT_ENTRY_MARK_AS_VOID';
export const ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION = 'ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION';
export const ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION = 'ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION';

export const DESTROY_ACCOUNT_PAYMENT = 'DESTROY_ACCOUNT_PAYMENT';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';

export const FETCH_OWNERS = 'FETCH_OWNERS';
export const UPDATE_OWNER = 'UPDATE_OWNER';

export const FETCH_LISTINGS = 'FETCH_LISTINGS';
export const CREATE_LISTING = 'CREATE_LISTING';
export const BROADCAST_LISTING = 'BROADCAST_LISTING';
export const UPDATE_LISTING = 'UPDATE_LISTING';
export const CLOSE_LISTING = 'CLOSE_LISTING';

export const FETCH_PROPERTY_AGENTS = 'FETCH_PROPERTY_AGENTS';

export const FETCH_REPAIRS_DATA = 'FETCH_REPAIRS_DATA';
