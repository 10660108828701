export const ListingTypeOptions = {
  "rent": {
    value: "rent",
    ttextKey: "Listings.ListingTypeRent",
    color: "orange",
  },
  "sale": {
    value: "sale",
    ttextKey: "Listings.ListingTypeSale",
    color: "red",
  },
}


export const ListingStatusOptions = {
  "available": {
    value: "available",
    ttextKey: "Listings.StatusOptions.Available",
  },
  "closed": {
    value: "closed",
    ttextKey: "Listings.StatusOptions.Closed",
  },
}
