import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  Divider,
  Icon,
  Row,
  Spin,
  Statistic,
  Table,
} from 'antd';
import { Chart, Axis, Legend, Tooltip, Geom } from 'bizcharts';
import numeral from 'numeral';

import { withTranslation } from 'react-i18next';

import {
  ThemeColors,
} from '@constants/Theme';
import * as DateUtils from '@utils/date';
import * as PortfolioActions from '@actions/portfolio';
import AccountEntries from '@components/AccountEntries';
import EventCalendar from '@components/EventCalendar';
import OwnershipWithTransitionContainer from '@components/Portfolio/OwnershipWithTransitionContainer';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchSummary();
    this.props.fetchNetCashflow();
  }

  renderSummaryCards() {
    const summary = Object.assign({
      number_of_properties: '',
      // management_team_size: '',
      outstanding_rental_payments_count: '',
      outstanding_rental_payments_amount: '',
      pending_tasks_count: '',
      occupancy_rate: '',
    }, this.props.summaryData);

    const stats = [
      {
        title: this.props.t("Portfolio.PropertyCount"),
        value: summary.number_of_properties,
        valueStyle: { color: ThemeColors.green },
        linkTo: {
          key: '/properties',
        },
      },
      {
        title: this.props.t("Portfolio.Occupancy-6"),
        value: summary.occupancy_rate ? `${Math.round((summary.occupancy_rate[0] / summary.occupancy_rate[1]) * 1000)/10} %` : null,
      },
      {
        title:  <span><strong>{ summary.outstanding_rental_payments_count }</strong> { this.props.t("Portfolio.OutstandingPaymentCount") }</span>,
        value: numeral(summary.outstanding_rental_payments_amount || 0).format('($0,0.[00])'),
        valueStyle: {
          color: (summary.outstanding_rental_payments_count > 0) ? ThemeColors.red : ThemeColors.black,
        },
        prefix: (summary.outstanding_rental_payments_count > 0) ? <Icon type="exclamation-circle" /> : null,
      },
      {
        title: this.props.t("Portfolio.PendingTasks"),
        value: summary.pending_tasks_count,
        valueStyle: {
          color: (summary.pending_tasks_count > 0) ? ThemeColors.orange : ThemeColors.black,
        },
        prefix: (summary.pending_tasks_count > 0) ? <Icon type="warning" /> : null,
        linkTo: {
          key: '/tasks',
        },
      },
    ]
    const colSpan = Math.floor(24 / stats.length);
    return (
      <div>
        <Row gutter={16}>
          {
            stats.map(({ title, value, valueStyle, prefix, linkTo }, index) => {
              const card = (
                <Card>
                  <Statistic
                    title={ title }
                    value={ value }
                    valueStyle={ valueStyle }
                    formatter={ (value) => {
                      if (this.props.isFetchingSummaryData) {
                        return (<Icon type='loading' />)
                      }
                      return value;
                    }}
                    prefix={ prefix }
                  />
                </Card>
              )
              const linkedCard = (linkTo && linkTo.key) ? (
                <Link to={ linkTo.key }>
                  { card }
                </Link>
              ) : card;

              return (
                <Col span={ colSpan } key={ index.toString() }>
                  { linkedCard }
                </Col>
              )
            })
          }
        </Row>
      </div>
    )
  }

  renderAccountEntries() {
    return (
      <div>
        <AccountEntries />
      </div>
    )
  }

  renderIncomeChart() {
    if (this.props.isFetchingNetCashFlowData) {
      return <Icon type='loading' />;
    }
    const data = this.props.netCashFlowData.data;
    if (!data || data.length === 0) {
      return <p>No account data</p>;
    }
    return (
      <Chart height={400} data={data} forceFit>
        <Axis name="month" />
        <Axis name="amount" />
        <Legend />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="interval"
          position="month*amount"
          color={"flow"}
          adjust={[
            {
              type: "dodge",
              marginRatio: 1 / 32
            }
          ]}
        />
      </Chart>
    );
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: 30 }}>
          { this.renderSummaryCards() }
        </div>
        <div style={{ backgroundColor: 'white', padding: 30, marginBottom: 30 }}>
          { this.renderAccountEntries() }
        </div>
        <div style={{ backgroundColor: 'white', padding: 30, marginBottom: 30 }}>
          <OwnershipWithTransitionContainer />
        </div>
        <div style={{ backgroundColor: 'white', padding: 30, marginBottom: 30 }}>
          <Row style={{ marginBottom: 10 }}>
            <h3>{ this.props.t("Portfolio.NetCashFlow") }</h3>
          </Row>
          { this.renderIncomeChart() }
        </div>
        <div style={{ backgroundColor: 'white', padding: 30 }}>
          <Row style={{ marginBottom: 10 }}>
            <h3>{ this.props.t("Portfolio.ImportantDates") }</h3>
          </Row>
          <EventCalendar />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingSummaryData: state.portfolioReducer.isFetchingSummaryData,
    summaryData: state.portfolioReducer.summaryData,
    errorFetchingSummaryData: state.portfolioReducer.errorFetchingSummaryData,
    isFetchingNetCashFlowData: state.portfolioReducer.isFetchingNetCashFlowData,
    netCashFlowData: state.portfolioReducer.netCashFlowData,
    errorFetchingNetCashFlowData: state.portfolioReducer.errorFetchingNetCashFlowData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSummary: () => { return dispatch(PortfolioActions.fetchSummary()).catch((error) => {}) },
    fetchNetCashflow: () => { return dispatch(PortfolioActions.fetchNetCashflow()).catch((error) => {}) },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Portfolio));
