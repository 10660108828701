import React from 'react';
import PropTypes from 'prop-types';

import {
  Alert,
  Card,
  Popover,
  Row,
} from 'antd';

import { withTranslation } from 'react-i18next';
import * as DateUtils from '@utils/date';

class OwnershipCard extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    status: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    ownership: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { ownership } = this.props;
    const popoverContainer = (
      <div>
        <h1>{ ownership.name }</h1>
        <h3 style={{ 'whiteSpace': 'pre-line' }}>
          { ownership.address }
        </h3>
        {
          ownership.alerts.map((alert, index) => {
            return (
              <Alert
                key={ index.toString() }
                type={ alert.type }
                message={ alert.message }
                showIcon
              />
            )
          })
        }
      </div>
    )
    return (
      <Popover
        placement="rightTop"
        trigger="hover"
        content={ popoverContainer }
      >
        <Card
          hoverable={ true }
          onClick={ this.props.onClick }
          title={ this.props.title }
          extra={ this.props.status }
          bordered={ true }
          style={{ width: "100%", padding: "0 10px", margin: "10px 0px", ...this.props.style }}
        >
          <Row type="flex" justify="space-between">
            <p><strong>{ this.props.t("Properties.NextPayment") }:</strong></p>
            <p>
              {
                ownership.next_payment_due_date &&
                DateUtils.formatDate(ownership.next_payment_due_date)
              }
            </p>
          </Row>
        </Card>
      </Popover>
    )
  }
}

export default withTranslation('common')(OwnershipCard);
