import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Checkbox,
  Form,
  Icon,
  Input,
  Row,
} from 'antd';

import {
  ThemeColors,
} from '@constants/Theme';

import * as AuthActions from '@actions/authentications';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      successMessage: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    const { form } = this.props;
    try {
      let values = await form.validateFields();
      this.setState({ isLoading: true });
      let responseData = await this.props.forgotPassword(values);
      this.setState({
        isLoading: false,
        successMessage: responseData.message,
      });
      this.props.form.resetFields();
      return responseData;
    } catch (error) {
      this.setState({
        isLoading: false,
        error,
      });
      return null;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={ this.handleSubmit }>
        {
          this.state.successMessage && (
            <Alert
              style={{ marginBottom: 20 }}
              type="success"
              message={ this.state.successMessage }
              showIcon
            />
          )
        }
        <Form.Item
          hasFeedback
        >
          {getFieldDecorator('email', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please input your email!' },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { type: 'email', message: 'Please input a valid email!' },
              ],
            }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              size="large"
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={ () => this.handleSubmit() } loading={ this.state.isLoading }>
            Forgot password
          </Button>
        </Form.Item>
        <Row type="flex" justify="space-between">
          <Link to="/login">Log in</Link>
          <Link to="/sign_up">Sign up</Link>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (values) => {
      return dispatch(AuthActions.forgotPassword(values))
    },
  }
}

const WrappedForgotPasswordForm = Form.create({ name: 'forgot_password' })(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
);

export default class ForgotPasswordScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row
        type="flex" justify="center" align="middle"
        style={{ height: '90%' }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 20, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          lg={{ span: 12, offset: 0 }}
          style={{
            backgroundColor: 'white',
            borderColor: ThemeColors.darkBlue,
            borderStyle: 'solid',
            borderWidth: 1,
            padding: 30,
          }}
        >
          <h2>Forgot Password</h2>
          <WrappedForgotPasswordForm />
        </Col>
      </Row>
    )
  }
}
