import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Input,
  Modal,
} from 'antd';
import { withTranslation } from 'react-i18next';

const RESET_STATE = (state = {}) => {
  return {
    confirmDirty: false,

    ...state,
  }
}

class UserPasswordUpdateForm extends React.Component {
  static propTypes = {
    // form: PropTypes
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.t("Profile.actions.UpdatePassword") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.props.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        <Form layout="vertical">
          <Form.Item
            {...formItemLayout}
            label={ this.props.t("Profile.CurrentPassword") }
          >
            {getFieldDecorator('current_password', {
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputCurrentPassword") }!` },
              ],
            })(
              <Input.Password />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.NewPassword") }
          >
            {getFieldDecorator('password', {
              validateTrigger: "onBlur",
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputNewPassword") }!` },
                { min: 6, message: `${ this.props.t("Profile.messages.Form.PasswordShouldBeGreater") }!` },
                { validator: this.validateToNextPassword },
              ],
            })(
              <Input.Password />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.ConfirmNewPassword") }
          >
            {getFieldDecorator('password_confirmation', {
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseConfirmYourNewPassword") }!` },
                { validator: this.compareToFirstPassword },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedUserPasswordUpdateForm = Form.create({ name: 'user_password_update_form_in_modal' })(UserPasswordUpdateForm);

export default withTranslation('common')(WrappedUserPasswordUpdateForm);
