import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchPropertyAgents = (params = {}) => {
  return Api.get(
    'property_agents',
    ActionTypes.FETCH_PROPERTY_AGENTS,
    params,
  )
}
