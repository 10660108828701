export const TaskCategoryOptions = {
  "other": {
    value: "other",
    text: "Other",
  },
  "leasing": {
    value: "leasing",
    text: "Leasing",
  },
  "repairs": {
    value: "repairs",
    text: "Repairs"
  },
  "finance": {
    value: "finance",
    text: "Finance",
  },
  "info": {
    value: "info",
    text: "Info",
  },
}

export const TaskStatusOptions = {
  "pending": {
    value: "pending",
    ttextKey: "Tasks.StatusPending",
  },
  "completed": {
    value: "completed",
    ttextKey: "Tasks.StatusCompleted",
  },
}
