import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class DocumentPreview extends React.Component {
  static propTypes = {
    document: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      preview_url: PropTypes.string,
      doc_type_display_name: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { document } = this.props;
    return (
      <a
        target="_blank"
        href={`${document.url}`}
        style={{ display: 'inline-block', textAlign: 'center', padding: 14, textDecoration: 'none' }}
      >
        {
          (document.preview_url) && (
            <div>
              <img
                src={ document.preview_url }
                style={{ height: '200px', width: 'auto' }}
                alt={ document.doc_type_display_name }
              />
              <br></br>
            </div>
          )
        }
        { document.doc_type_display_name }
      </a>
    )
  }
}

export default DocumentPreview;
