import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Alert,
  DatePicker,
  Form,
  Input,
  Modal,
} from "antd";

import moment from 'moment'
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class TerminateTenancyForm extends React.Component {
  static propTypes = {
    tenancy: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { tenancy } = this.props;
    if (!tenancy) {
      return null;
    }

    const { form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 }
    };

    return (
      <Modal
        visible={this.props.visible}
        title={ this.props.t("Tenancies.actions.TerminateTenancy") }
        okText={ this.props.t("actions.Submit") }
        onCancel={this.props.onCancel}
        onOk={this.props.onOk}
        confirmLoading={this.props.confirmLoading}
      >
        {this.props.children}
        <Alert
          style={{ marginBottom: 20 }}
          type="warning"
          message={ this.props.t("Tenancies.messages.Form.AlertTerminateTenancy") }
          showIcon
        />
        <Form layout="vertical">
          <Form.Item
            label={ this.props.t("Tenancies.Tenancy") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            { DateUtils.formatDate(tenancy.start_date) } - { DateUtils.formatDate(tenancy.end_date) }
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantName") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >

            { tenancy.official_tenant_name }
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.EarlyTerminationDate") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("early_termination_date", {
              initialValue: tenancy.early_termination_date && moment(tenancy.early_termination_date),
              rules: [{ required: true, message: `${ this.props.t("Tenancies.messages.Form.PleaseInputTerminationDate") }!` }],
            })(
              <DatePicker
                format={"YYYY/MM/DD"}
                disabledDate={ (current) => {
                  return current && (current < moment(tenancy.start_date) || current > moment(tenancy.end_date))
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            label={ this.props.t("shared.Notes") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >
            {getFieldDecorator('notes', {
              initialValue: tenancy.notes,
            })(
              <Input.TextArea rows={ 5 } />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedTerminateTenancyForm = connect(mapStateToProps, mapDispatchToProps)(TerminateTenancyForm);

const WrappedTerminateTenancyForm = Form.create({
  name: "tenancies_terminate_form_in_modal"
})(ConnectedTerminateTenancyForm);

export default withTranslation('common')(WrappedTerminateTenancyForm);
