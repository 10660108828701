import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import AuthenticatedRouter from '@views/AuthenticatedRouter';
import UnauthenticatedRouter from '@views/UnauthenticatedRouter';

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const Component = (this.props.is_authenticated) ? AuthenticatedRouter : UnauthenticatedRouter;
    return <Route component={ Component } />
  }
}

const mapStateToProps = (state) => {
  return {
    is_authenticated: state.usersReducer.is_authenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
