import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Form,
  Input,
  Modal,
  Radio,
} from "antd";
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class UpdateForm extends React.Component {
  static propTypes = {
    tenancy: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { tenancy } = this.props;
    if (!tenancy) {
      return null;
    }

    const { form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 }
    };

    return (
      <Modal
        visible={this.props.visible}
        title={ this.props.t("Tenancies.actions.UpdateTenancy") }
        okText={ this.props.t("actions.Submit") }
        onCancel={this.props.onCancel}
        onOk={this.props.onOk}
        confirmLoading={this.props.confirmLoading}
      >
        {this.props.children}
        <Form layout="vertical">
          <Form.Item
            label={ this.props.t("Tenancies.Tenancy") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            { DateUtils.formatDate(tenancy.start_date) } - { DateUtils.formatDate(tenancy.end_date) }
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantName") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >
            {getFieldDecorator("official_tenant_name", {
              initialValue: tenancy.official_tenant_name,
              rules: [{ required: true, message: `${ this.props.t("Tenancies.messages.Form.PleaseInputRegisteredTenantName") }` }],
            })(<Input placeholder={ this.props.t("Tenancies.TenantName") } />)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantEmail") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >
            {getFieldDecorator("official_tenant_email", {
              initialValue: tenancy.official_tenant_email,
              rules: [{ required: false, message: "Please input registered tenant email" }],
            })(<Input placeholder="Email" />)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tenancies.TenantPhone") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >
            {getFieldDecorator("official_tenant_phone_number", {
              initialValue: tenancy.official_tenant_phone_number,
              rules: [{ required: false, message: "Please input registered tenant phone number" }],
            })(<Input placeholder="Phone Number" />)}
          </Form.Item>
          <Form.Item
            label={ `${this.props.t("Tenancies.TenantCorrespondenceAddress") } (${this.props.t("Tenancies.IfDifferentToPropertyAddress")})` }
            {...formItemLayout}
          >
            {getFieldDecorator("correspondence_address", {
              initialValue: tenancy.correspondence_address,
              rules: [{ required: false, message: "Please input registered tenant correspondence address" }],
            })(
              <Input.TextArea
                rows={ 3 }
              />
            )}
          </Form.Item>
          <Form.Item
            label="Management Fee"
            {...formItemLayout}
          >
            {getFieldDecorator('include_mgmt_fee', {
              initialValue: (tenancy.include_mgmt_fee || false).toString(),
              rules: [{ required: true, message: 'Please select management fee' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label="Govt Rent"
            {...formItemLayout}
          >
            {getFieldDecorator('include_govt_rent', {
              initialValue: (tenancy.include_govt_rent || false).toString(),
              rules: [{ required: true, message: 'Please select govt rent' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label="Govt Rates"
            {...formItemLayout}
          >
            {getFieldDecorator('include_govt_rates', {
              initialValue: (tenancy.include_govt_rates || false).toString(),
              rules: [{ required: true, message: 'Please select govt rates' }],
            })(
              <Radio.Group>
                <Radio value="true">Included</Radio>
                <Radio value="false">Excluded</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            label={ this.props.t("shared.Notes") }
            {...formItemLayout}
            style={{ margin: 0, paddingBottom: 14 }}
          >
            {getFieldDecorator('notes', {
              initialValue: tenancy.notes,
            })(
              <Input.TextArea rows={ 5 } />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedUpdateForm = connect(mapStateToProps, mapDispatchToProps)(UpdateForm);

const WrappedUpdateForm = Form.create({
  name: "tenancies_update_form_in_modal"
})(ConnectedUpdateForm);

export default withTranslation('common')(WrappedUpdateForm);
