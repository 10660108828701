import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Upload,
} from 'antd';

import { withTranslation } from 'react-i18next';

const RESET_STATE = (state = {}) => {
  return {
    fileList: [],
    ...state,
  }
}

class UserProfileEditForm extends React.Component {
  static propTypes = {
    // form: PropTypes
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    me: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone_number: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    visible: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onCancel = this.onCancel.bind(this);
    this.onOk = this.onOk.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onCancel() {
    this.props.onCancel();
  }

  async onOk() {
    const { fileList } = this.state;
    this.props.form.setFieldsValue({
      avatar: (fileList.length > 0) ? fileList[0] : null,
    })
    let isFormValid = await this.props.onOk();
    if (isFormValid && isFormValid.response) {
      this.setState({
        fileList: [],
      });
    }
  }

  render() {
    // TODO: check out https://ant.design/components/upload/ "Avatar" section
    const { visible, confirmLoading, form } = this.props;
    const { getFieldDecorator } = form;
    const me = this.props.me;

    const formItemLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    return (
      <Modal
        visible={ visible }
        title="Update user profile"
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.onCancel }
        onOk={ this.onOk }
        confirmLoading={ confirmLoading }
      >
        { this.props.children }
        <Form layout="vertical">
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.Settings.FirstName") }
          >
            {getFieldDecorator('first_name', {
              initialValue: me.first_name,
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputFirstName") }!` },
              ],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.Settings.LastName") }
          >
            {getFieldDecorator('last_name', {
              initialValue: me.last_name,
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputLastName") }!` },
              ],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.Settings.Phone") }
          >
            {getFieldDecorator('phone_number', {
              initialValue: me.phone_number,
              rules: [
                { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputYourPhoneNumber") }!` },
              ],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            {...formItemLayout}
            label={ this.props.t("Profile.Settings.Email") }
          >
            {getFieldDecorator('email', {
              initialValue: me.email,
              validate: [{
                trigger: 'onChange',
                rules: [
                  { required: true, message: `${ this.props.t("Profile.messages.Form.PleaseInputYourEmail") }!` },
                ],
              }, {
                trigger: 'onBlur',
                rules: [
                  { type: 'email', message: `${ this.props.t("Profile.messages.Form.PleaseInputValidEmail") }!` },
                ],
              }],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Profile.Settings.ProfilePicture") }
            { ...formItemLayout }
          >
            <div className="dropbox">
              {getFieldDecorator('avatar', {
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  accept="image/*"
                  multiple={ false }
                  onRemove={
                    (avatar) => {
                      this.setState({ fileList: [] });
                    }
                  }
                  beforeUpload={
                    (avatar) => {
                      this.setState({
                        fileList: [avatar],
                      });
                      return false;
                    }
                  }
                  fileList={ this.state.fileList }
                  name="avatar"
                >
                  <Button>
                    <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                  </Button>
                </Upload>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedUserProfileEditForm = Form.create({ name: 'user_profile_edit_form_in_modal' })(UserProfileEditForm);

export default withTranslation('common')(WrappedUserProfileEditForm);
