import React from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  Icon,
  Layout,
  Menu,
} from 'antd';

import { withTranslation } from 'react-i18next';

import Mixpanel from '@utils/mixpanel';
import { LayoutStyle, ThemeColors } from '@constants/Theme'

import Portfolio from './Portfolio';
import Properties from './Properties';
import Documents from './Documents';
import Tasks from './Tasks';
import Owners from './Owners';
import Listings from './Listings';
import RepairsData from './RepairsData';
import Profile from './Profile';
import NotFound from './NotFound';

const routes = [
  {
    key: "/portfolio",
    style: { marginTop: 0 },
    icon: "pie-chart",
    ttext: "Portfolio.Title",
    component: Portfolio,
  },{
    key: "/properties",
    icon: "home",
    ttext: "Properties.Title",
    component: Properties,
  },{
    key: "/documents",
    icon: "file",
    ttext: "Documents.Title",
    component: Documents,
  },{
    key: "/tasks",
    icon: "file-done",
    ttext: "Tasks.Title",
    component: Tasks,
  },{
    key: "/owners",
    icon: "team",
    ttext: "Owners.Title",
    component: Owners,
  },{
    key: "/listings",
    icon: "block",
    ttext: "Listings.Title",
    component: Listings,
  },{
    key: "/repairs_data",
    icon: "tool",
    ttext: "RepairsData.Title",
    component: RepairsData,
  },{
    key: "/profile",
    icon: "user",
    ttext: "Profile.Title",
    component: Profile,
  }
];

class AuthenticatedRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingOut: false,
      collapsed: false,
    };
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    this.setState({ isLoggingOut: true })
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  renderSider() {
    const { location } = this.props;
    const selected = routes.filter((route) => {
      return location.pathname.includes(route.key);
    }).map((route) => { return route.key });

    return (
      <Layout.Sider
        collapsible
        collapsed={ this.state.collapsed }
        onCollapse={ this.onCollapse }
      >
        <Link to="/">
          <h3
            style={{
              ...LayoutStyle.siderTitle,
              color: this.state.collapsed ? "rgba(0,0,0,0)" : "white",
            }}
          >
            { this.props.orgName }
          </h3>
        </Link>
        <Menu theme="dark" selectedKeys={ selected } mode="inline">
          {
            routes.map((route) => (
              <Menu.Item key={ route.key } style={ route.style || {} }>
                <Link
                  to={ route.key }
                  onClick={ () => Mixpanel.track('Sider Menu click', { name: route.key }) }
                >
                  <Icon type={ route.icon } />
                  <span>{ this.props.t(route.ttext) }</span>
                </Link>
              </Menu.Item>
            ))
          }
          <Menu.Item style={{ marginTop: 20 }} key="/users/sign_out">
            <a
              href="/users/sign_out"
              onClick={ this.onLogout }
            >
              <Icon
                type={ this.state.isLoggingOut ? "loading" : "logout" }
                style={{ color: ThemeColors.red }}
              />
              <span style={{ color: ThemeColors.red }}>
                { this.props.t("sider.SignOut") }
              </span>
            </a>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
    )
  }

  render() {
    return (
      <Layout>
        { this.renderSider() }
        <Layout.Content style={ LayoutStyle.routerContentStyle }>
          <Switch>
            <Redirect exact from="/" to={ routes[0].key } />
            {
              routes.map((route, index) => (
                <Route
                  key={ index.toString() }
                  path={ route.key }
                  component={ route.component }
                />
              ))
            }
            <Redirect from="/login" to={{ pathname: routes[0].key }} />
            <Redirect exact from="/sign_up" to={{ pathname: routes[0].key }} />
            <Route component={ NotFound } />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    orgName: state.organizationReducer.name,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRouter));
