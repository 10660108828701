import axios from 'axios';
import qs from 'qs';

import * as ActionTypes from '@actions/types';

const API_URL = `${window.location.origin}`;
const API_PATH = `${API_URL}/api/v2`;

const onRequestStart = (requestType, metaData) => {
  return {
    type: `${requestType}_${ActionTypes.CALL_API_REQUEST}`,
    metaData,
  };
}

const onRequestSuccess = (requestType, responseData, metaData) => {
  return {
    type: `${requestType}_${ActionTypes.CALL_API_SUCCESS}`,
    responseData,
    metaData
  };
}

const onRequestError = (requestType, error, metaData) => {
  return {
    type: `${requestType}_${ActionTypes.CALL_API_ERROR}`,
    error,
    metaData,
  };
}

export const get = (endpoint, requestType, params = {}, metaData = {}) => {
  return async (dispatch) => {
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'GET',
        baseURL: `${API_PATH}/`,
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}

export const post = (endpoint, requestType, data = {}, metaData = {}) => {
  return async (dispatch) => {
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'POST',
        baseURL: `${API_PATH}/`,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}

export const put = (endpoint, requestType, body = {}, metaData = {}) => {
  return async (dispatch) => {
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'PUT',
        baseURL: `${API_PATH}/`,
        data: body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}

export const putUpload = (endpoint, requestType, body = {}, metaData = {}) => {
  return async (dispatch) => {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (!value) {
        // Ignore null value. multipart-form will submit "null" string instead of just null
        return
      }
      if (key.includes('[]')) {
        value.forEach((valueItem) => {
          data.append(key, valueItem);
        });
      } else {
        data.append(key, value);
      }
    });
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'PUT',
        baseURL: `${API_PATH}/`,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}

export const postUpload = (endpoint, requestType, body = {}, metaData = {}) => {
  return async (dispatch) => {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (!value) {
        // Ignore null value. multipart-form will submit "null" string instead of just null
        return
      }
      if (key.includes('[]')) {
        value.forEach((valueItem) => {
          data.append(key, valueItem);
        });
      } else {
        data.append(key, value);
      }
    });
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'POST',
        baseURL: `${API_PATH}/`,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}

export const del = (endpoint, requestType, params = {}, metaData = {}) => {
  // delete is a protected word in js. use del instead
  return async (dispatch) => {
    try {
      dispatch(onRequestStart(requestType, metaData));
      let response = await axios(endpoint, {
        method: 'DELETE',
        baseURL: `${API_PATH}/`,
        params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;
      dispatch(onRequestSuccess(requestType, responseData, metaData));
      return responseData;
    } catch (error) {
      dispatch(onRequestError(requestType, error, metaData));
      throw error;
    }
  }
}
