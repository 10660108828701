import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Switch,
} from "antd";
import moment from "moment";
import { withTranslation } from 'react-i18next';

import { TaskCategoryOptions, TaskStatusOptions } from "@constants/models/Tasks";
import * as OwnershipActions from '@actions/ownerships';
import QuillEditor from '@components/QuillEditor'
import AsyncUpload from '@components/AsyncUpload';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnershipManagers: false,
    ownershipManagers: [],
    errorFetchingOwnershipManagers: null,
    fileDataMap: {},
    ...state,
  };
}

class UpdateForm extends React.Component {
  static propTypes = {
    task: PropTypes.shape({
      id: PropTypes.number.isRequired,
      ownership_id: PropTypes.number.isRequired,
    }),
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE()
    this.onCancel = this.onCancel.bind(this);
    this.onOk = this.onOk.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.updateFileDataMap = this.updateFileDataMap.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      if (!this.props.task) {
        return null;
      }
      this.setState({ isFetchingOwnershipManagers: true })
      this.props.fetchOwnershipManagers(this.props.task.ownership_id).then((responseData) => {
        this.setState({
          isFetchingOwnershipManagers: false,
          ownershipManagers: responseData.ownership_managers,
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnershipManagers: false,
          ownershipManagers: [],
          errorFetchingOwnershipManagers: error,
        });
      })
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onCancel() {
    this.props.onCancel();
  }

  onOk() {
    this.props.form.setFieldsValue({
      files: Object.values(this.state.fileDataMap).map((fileMetaData) => fileMetaData.blobSignedId),
    });
    this.props.onOk();
  }

  handleChangeQuill(key, value) {
    this.props.form.setFieldsValue({
      [key]: value,
    })
  }

  updateFileDataMap(fileDataMap) {
    this.setState({ fileDataMap });
  }

  render() {
    const { task } = this.props;
    if (!task) {
      return null;
    }

    const { visible, confirmLoading, form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    return (
      <Modal
        width="80%"
        visible={visible}
        title={ this.props.t("Tasks.actions.UpdateTask") }
        okText={ this.props.t("actions.Submit") }
        onCancel={this.onCancel}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
      >
        {this.props.children}
        <Form layout="vertical">
          <Form.Item
            label={ this.props.t("Properties.Property") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
            >
            { task.ownership_name }
            <br />
            { task.ownership_address }
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.TaskName") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
            >
            {getFieldDecorator("name", {
              initialValue: task.name,
              rules: [{ required: true, message: `${ this.props.t("Tasks.messages.Form.PleaseInputTaskName") }!` }]
            })(<Input placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.Category") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("category", {
              initialValue: task.category,
              rules: [
                { required: true, message: `${ this.props.t("Tasks.messages.Form.PleaseSelectCategory") }!` }
              ]
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("Tasks.messages.Form.PleaseSelectCategory") }
              >
                {Object.values(TaskCategoryOptions).map(taskCategory => (
                  <Select.Option key={taskCategory.value}>
                    {taskCategory.text}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.AssignedTo") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {
              (this.state.ownershipManagers.length > 0) ? (
                getFieldDecorator("assigned_to_user_id", {
                  initialValue: task.owned_by && task.owned_by.id.toString(),
                  rules: [
                    { required: true, message: `${ this.props.t("Tasks.messages.Form.PleaseSelectAssignee") }!` }
                  ]
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder={ this.props.t("Tasks.messages.Form.PleaseSelectAssignee") }
                  >
                    {
                      this.state.ownershipManagers.map((manager) => (
                        <Select.Option key={ manager.user_id.toString() }>
                          { manager.display_name }
                        </Select.Option>
                      ))
                    }
                  </Select>
                )
              ) : <span>{ task.owned_by.display_name }</span>
            }
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.MyProptyAssistance") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("assign_to_mypropty", {
              valuePropName: "checked",
              initialValue: task.assign_to_mypropty,
            })(<Checkbox>Request Support</Checkbox>)}
          </Form.Item>
          <Form.Item
            label={ this.props.t("shared.Status") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("status", {
              initialValue: task.status,
            })(
              <Radio.Group>
                {
                  Object.values(TaskStatusOptions).map(taskStatus => (
                    <Radio key={ taskStatus.value } value={ taskStatus.value }>
                      { this.props.t(taskStatus.ttextKey) }
                    </Radio>
                  ))
                }
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("shared.Description") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator("description", {
              initialValue: task.description || "",
            })(<Input.TextArea style={{ display: 'none' }} />)}
            <QuillEditor
              value={ this.props.form.getFieldValue("description") }
              onChange={ (value) => this.handleChangeQuill("description", value) }
            />
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.Deadline") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('deadline', {
              initialValue: task.deadline ? moment(task.deadline) : null,
              rules: [
                {
                  type: 'object',
                  required: false,
                },
              ],
            })(
              <DatePicker format={"YYYY/MM/DD"} />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("Tasks.Attachments") }
            style={{ margin: 0, paddingBottom: 14 }}
            { ...formItemLayout }
          >
            <div className="dropbox">
              {getFieldDecorator("files", {
                getValueFromEvent: this.normFile,
                rules: [
                  { required: false, message: `${ this.props.t("Tasks.messages.Form.PleaseSelectAttachments") }!` },
                ],
              })(
                <AsyncUpload
                  name="files"
                  multiple={ true }
                  fileDataMap={ this.state.fileDataMap }
                  updateFileDataMap={ this.updateFileDataMap }
                >
                  <Button>
                    <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                  </Button>
                </AsyncUpload>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipManagers: (ownershipId) => {
      return (dispatch(OwnershipActions.fetchOwnershipManagers(ownershipId)));
    },
  };
}

const ConnectedUpdateForm = connect(mapStateToProps, mapDispatchToProps)(UpdateForm);

const WrappedUpdateForm = Form.create({
  name: "tasks_update_form_in_modal"
})(ConnectedUpdateForm);

export default withTranslation('common')(WrappedUpdateForm);
