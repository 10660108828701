import * as ArrayUtils from '@utils/array';
import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetching: false,
  isUpdating: false,
  documents: {},
  error: null,
  isCreating: false,
  creatingError: null,
  DocumentTypeOptions: {},
}

export default (state = initialState, action) => {
  let document, documents;
  switch (action.type) {
    case `${ActionTypes.FETCH_DOCUMENTS}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case `${ActionTypes.FETCH_DOCUMENTS}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetching: false,
        error: null,
        documents: {
          ...state.documents,
          ...ArrayUtils.arrayToObject(action.responseData.documents),
        },
      };
    case `${ActionTypes.FETCH_DOCUMENTS}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case `${ActionTypes.CREATE_DOCUMENT}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isCreating: true,
        creatingError: null,
      };
    case `${ActionTypes.CREATE_DOCUMENT}_${ActionTypes.CALL_API_SUCCESS}`:
      document = { ...action.responseData.document };
      return {
        ...state,
        isCreating: false,
        creatingError: null,
        documents: {
          ...state.documents,
          [document.id]: document,
        },
      };
    case `${ActionTypes.CREATE_DOCUMENT}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isCreating: false,
        creatingError: action.error,
      }
    case `${ActionTypes.DESTROY_DOCUMENT}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case `${ActionTypes.DESTROY_DOCUMENT}_${ActionTypes.CALL_API_SUCCESS}`:
      documents = { ...state.documents };
      delete documents[action.metaData.documentId];
      return {
        ...state,
        isUpdating: false,
        error: null,
        documents: documents,
      };
    case `${ActionTypes.DESTROY_DOCUMENT}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    default:
      return state;
  }
}
