import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Checkbox,
  Divider,
  Modal,
} from 'antd';

const checkedOptions = {
  owners: [
    { value: 'owner_name', label: 'Name'}
  ],
  currentTenancy: [
    { value: 'current_tenancy_tenant_name', label: 'Tenant Name' },
    { value: 'current_tenancy_rental_amount', label: 'Rental Amount' },
    { value: 'current_tenancy_security_deposit', label: 'Security Deposit' },
    { value: 'current_tenancy_terms', label: 'Terms' },
    { value: 'current_tenancy_notes', label: 'Notes' },
  ],
  nextTenancy: [
    { value: 'next_tenancy_tenant_name', label: 'Tenant Name' },
    { value: 'next_tenancy_rental_amount', label: 'Rental Amount' },
    { value: 'next_tenancy_security_deposit', label: 'Security Deposit' },
    { value: 'next_tenancy_terms', label: 'Terms' },
    { value: 'next_tenancy_notes', label: 'Notes' },
  ],
};

class DownloadTenanciesModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    title: "",
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedLists: {
        owners: [],
        currentTenancy: [],
        nextTenancy: [],
      },
    }
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onOk = this.onOk.bind(this);
  }

  onChangeCheckbox(listName, checkedList) {
    this.setState({
      checkedLists: {
        ...this.state.checkedLists,
        [listName]: checkedList,
      },
    });
  };

  onOk() {
    this.props.onOk(this.state.checkedLists)
  }

  render() {
    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.title }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        <Divider orientation="left">Owner Particulars</Divider>
        <Checkbox.Group
          options={ checkedOptions.owners }
          value={ this.state.checkedLists.owners }
          onChange={ (checkedList) => this.onChangeCheckbox('owners', checkedList) }
        />
        <Divider orientation="left">Current Tenancy</Divider>
        <Checkbox.Group
          options={ checkedOptions.currentTenancy }
          value={ this.state.checkedLists.currentTenancy }
          onChange={ (checkedList) => this.onChangeCheckbox('currentTenancy', checkedList) }
        />
        <Divider orientation="left">Next Tenancy</Divider>
        <Checkbox.Group
          options={ checkedOptions.nextTenancy }
          value={ this.state.checkedLists.nextTenancy }
          onChange={ (checkedList) => this.onChangeCheckbox('nextTenancy', checkedList) }
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTenanciesModal);
