import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  ListingTypeOptions,
} from '@constants/models/Listing';
import * as ArrayUtils from '@utils/array';
import QuillEditor from '@components/QuillEditor'
import AsyncUpload from '@components/AsyncUpload';

const RESET_STATE = (state = {}) => {
  return {
    fileDataMap: {},
    ...state,
  };
}

class UpdateForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onOk = this.onOk.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.updateFileDataMap = this.updateFileDataMap.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onOk() {
    this.props.form.setFieldsValue({
      images: Object.values(this.state.fileDataMap).map((fileMetaData) => fileMetaData.blobSignedId),
    });
    this.props.onOk();
  }

  renderAmountLabel(listingType) {
    if (listingType === "rent") {
      return this.props.t("Listings.MonthlyAmount");
    } else {
      return this.props.t("Listings.SaleAmount");
    }
  }

  handleChangeQuill(key, value) {
    this.props.form.setFieldsValue({
      [key]: value,
    })
  }

  updateFileDataMap(fileDataMap) {
    this.setState({ fileDataMap });
  }

  renderForm() {
    const { listing } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { listing.ownership.name }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingType") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          { this.props.t(ListingTypeOptions[listing.listing_type].ttextKey) }
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingName") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
          >
          {getFieldDecorator("name", {
            initialValue: listing.name || "",
            rules: [{ required: true, message: "Please input a short name" }]
          })(<Input placeholder="2 bedroom, 1 bathroom, sea-view" />)}
        </Form.Item>
        <Form.Item
          label={ this.renderAmountLabel(listing.listing_type) }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('amount', {
            initialValue: listing.amount,
            rules: [{ required: true, message: 'Please input amount' }],
          })(
            <InputNumber
              style={{ width: '100%', minWidth: '150px' }}
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          )}
        </Form.Item>
        {
          listing.listing_type === "rent" && (
            <Form.Item
              label={ this.props.t("shared.SecurityDeposit") }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              {getFieldDecorator('security_deposit', {
                initialValue: listing.security_deposit,
                rules: [{ required: false, message: 'Please input security deposit' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          )
        }
        <Form.Item
          label={ this.props.t("Listings.AgentCommission") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('agent_commission_fixed', {
            initialValue: listing.agent_commission_fixed,
            rules: [{ required: false, message: 'Please input agent commission' }],
          })(
            <InputNumber
              style={{ width: '100%', minWidth: '150px' }}
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.AvailableFrom") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("available_date", {
            initialValue: listing.available_date ? moment(listing.available_date) : null,
          })(<DatePicker format={"YYYY/MM/DD"} />)}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.LongDescription") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("description", {
            initialValue: listing.description || "",
          })(<Input.TextArea style={{ display: 'none' }} />)}
          <QuillEditor
            value={ this.props.form.getFieldValue('description') }
            onChange={ (value) => this.handleChangeQuill("description", value) }
          />
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.InternalNotes") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("internal_notes", {
            initialValue: listing.internal_notes || "",
          })(<Input.TextArea style={{ display: 'none' }} />)}
          <QuillEditor
            value={ this.props.form.getFieldValue('internal_notes') }
            onChange={ (value) => this.handleChangeQuill("internal_notes", value) }
          />
        </Form.Item>
        {
          (listing.listing_type === "rent") && (
            <Form.Item
              label={ this.props.t("Listings.MinLeasePeriod") }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              {getFieldDecorator('min_lease_period_in_months', {
                initialValue: listing.min_lease_period_in_months,
                rules: [{ required: false, message: 'Please input minimum lease period' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  min={0}
                />
              )}
            </Form.Item>
          )
        }
        <Form.Item
          label={ this.props.t("Listings.Photos") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          <div className="dropbox">
            {getFieldDecorator("images", {
              getValueFromEvent: this.normFile,
              rules: [
                { required: false, message: `${this.props.t("Listings.messages.Form.PleaseSelectImages")}!` },
              ],
            })(
              <AsyncUpload
                name="images"
                accept="image/png, image/jpeg"
                multiple={ true }
                fileDataMap={ this.state.fileDataMap }
                updateFileDataMap={ this.updateFileDataMap }
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </AsyncUpload>
            )}
          </div>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { listing } = this.props;
    if (!listing) {
      return null;
    }
    const anyFileLoading = Object.values(this.state.fileDataMap).some((fileMetaData) => fileMetaData.isFileUploading);
    return (
      <Modal
        width="80%"
        visible={ this.props.visible }
        title={ this.props.t("Listings.actions.UpdateListing") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        okButtonProps={{ disabled: anyFileLoading }}
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedUpdateForm = connect(mapStateToProps, mapDispatchToProps)(UpdateForm);

const WrappedUpdateForm = Form.create({
  name: "listings_update_form_in_modal"
})(ConnectedUpdateForm);

export default withTranslation('common')(WrappedUpdateForm);
