import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const forgotPassword = (values) => {
  return Api.post(
    'authentications/forgot_password',
    ActionTypes.AUTH_FORGOT_PASSWORD,
    {
      email: values.email,
    },
  )
}
