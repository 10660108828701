import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Collapse,
  Row,
  Spin,
  Tag,
  Tooltip,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { ThemeColors } from '@constants/Theme';
import * as DateUtils from '@utils/date';
import TenancyShow from '@components/Tenancies/Show';

class List extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    tenancies: PropTypes.array,
    onClickAttachDocument: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickTerminateEarly: PropTypes.func,
  }

  static defaultProps = {
    loading: false,
    tenancies: [],
    onClickAttachDocument: (tenancyId) => {},
    onClickEdit: (tenancyId) => {},
    onClickTerminateEarly: (tenancyId) => {},
  }

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.loading) {
      return (
        <Row type="flex" justify="center">
          <Spin />
        </Row>
      )
    }

    const { tenancies } = this.props;
    if (tenancies.length == 0) {
      return (<span>{ this.props.t("Tenancies.messages.NoTenanciesListed") }.</span>)
    }

    let sortedTenancies = tenancies.sort((a, b) => {
      const a_end = a.early_termination_date || a.end_date;
      const b_end = b.early_termination_date || b.end_date;
      return (moment(b_end) - moment(a_end));
    });

    const defaultActiveKeys = [`${sortedTenancies[0].id}`];
    return (
      <Collapse defaultActiveKey={ defaultActiveKeys }>
        {
          sortedTenancies.map((tenancy, index) => {
            const formattedStartDate = DateUtils.formatDate(tenancy.start_date);
            const formattedEndDate = DateUtils.formatDate(tenancy.end_date);

            const formattedFullEndDate = tenancy.early_termination_date ? (
              <Tooltip placement="top" title={ `Originally: ${formattedEndDate}` }>
                <span style={{ color: ThemeColors.red }}>
                  { DateUtils.formatDate(tenancy.early_termination_date) }
                </span>
              </Tooltip>
            ) : formattedEndDate;
            const isPast = moment(tenancy.early_termination_date || tenancy.end_date) < moment();
            const isFuture = moment(tenancy.start_date) > moment();
            return (
              <Collapse.Panel
                header={
                  <div>
                    <Tag
                      style={{ marginRight: "10px" }}
                      color={ isPast ? "grey" : (isFuture ? "green" : "blue") }
                    >
                      { isPast ? this.props.t("Tenancies.PAST") : (isFuture ? this.props.t("Tenancies.FUTURE") : this.props.t("Tenancies.CURRENT")) }
                    </Tag>
                    { formattedStartDate } - { formattedFullEndDate }: {tenancy.official_tenant_name}
                  </div>
                }
                key={ tenancy.id.toString() }
              >
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Button.Group>
                    <Button
                      icon="edit"
                      onClick={ (event) => { this.props.onClickEdit(tenancy.id) } }
                    >
                      { this.props.t("Tenancies.actions.UpdateTenancy") }
                    </Button>
                    <Button
                      icon="paper-clip"
                      onClick={ (event) => { this.props.onClickAttachDocument(tenancy.id) } }
                    >
                      { this.props.t("Documents.actions.AttachDocument") }
                    </Button>
                    <Button
                      icon="close"
                      type="danger"
                      ghost
                      onClick={ (event) => { this.props.onClickTerminateEarly(tenancy.id) } }
                      disabled={ !!tenancy.early_termination_date || isPast }
                    >
                      { this.props.t("Tenancies.actions.TerminateTenancy") }
                    </Button>
                  </Button.Group>
                  <Button.Group>
                    <Button
                      type="link"
                      onClick={ (event) => { window.open(tenancy.account_payments_url, '_blank') } }
                      disabled={ !tenancy.account_payments_url }
                    >
                      { this.props.t("Tenancies.actions.ViewPaymentHistory") }
                    </Button>
                  </Button.Group>
                </div>
                <TenancyShow tenancy={ tenancy } />
              </Collapse.Panel>
            )
          })
        }
      </Collapse>
    )
  }
}

export default withTranslation('common')(List);
