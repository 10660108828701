import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchAccountEntries = (params = {}) => {
  return Api.get(
    'account_entries',
    ActionTypes.FETCH_ACCOUNT_ENTRIES,
    params,
  );
}

export const createAccountEntry = (ownershipId, params = {}) => {
  return Api.post(
    `account_entries`,
    ActionTypes.CREATE_ACCOUNT_ENTRY,
    {
      ...params,
      ownership_id: ownershipId,
    },
    {
      ownershipId,
    }
  )
}

export const markAsVoid = (accountEntryId, ownershipId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/mark_as_void`,
    ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  );
}

export const fetchAccountEntry = (accountEntryId, ownershipId, params = {}) => {
  return Api.get(
    `account_entries/${accountEntryId}`,
    ActionTypes.FETCH_ACCOUNT_ENTRY,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  );
}

export const createLineItem = (accountEntryId, ownershipId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/create_line_item`,
    ActionTypes.CREATE_ACCOUNT_ENTRY_LINE_ITEM,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  )
}

export const updateLineItem = (accountEntryId, ownershipId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/update_line_item`,
    ActionTypes.UPDATE_ACCOUNT_ENTRY_LINE_ITEM,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  )
}

export const deleteLineItem = (accountEntryId, ownershipId, params = {}) => {
  return Api.del(
    `account_entries/${accountEntryId}/delete_line_item`,
    ActionTypes.DELETE_ACCOUNT_ENTRY_LINE_ITEM,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  )
}

export const sendAccountEntryInvoice = (accountEntryId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/send_invoice`,
    ActionTypes.ACCOUNT_ENTRY_SEND_INVOICE,
    params,
    {
      accountEntryId,
    }
  );
}

export const sendAccountEntryPaymentReminder = (accountEntryId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/send_payment_reminder`,
    ActionTypes.ACCOUNT_ENTRY_SEND_PAYMENT_REMINDER,
    params,
    {
      accountEntryId,
    }
  );
}

export const sendAccountEntryReceipt = (accountEntryId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/send_receipt`,
    ActionTypes.ACCOUNT_ENTRY_SEND_RECEIPT,
    params,
    {
      accountEntryId,
    }
  );
}

export const addPaymentToAccountEntry = (accountEntryId, ownershipId, params = {}) => {
  return Api.post(
    `account_entries/${accountEntryId}/add_payment`,
    ActionTypes.ACCOUNT_ENTRY_ADD_PAYMENT,
    params,
    {
      accountEntryId,
      ownershipId,
    }
  )
}

export const attachDocumentToAccountEntry = (accountEntryId, ownershipId, documentId) => {
  return Api.post(
    `account_entries/${accountEntryId}/attach_document`,
    ActionTypes.ACCOUNT_ENTRY_ATTACH_DOCUMENT,
    {
      document_id: documentId,
    },
    {
      accountEntryId,
      ownershipId,
    }
  );
}

export const saveNewInvoiceToAccountEntry = (accountEntryId, ownershipId) => {
  return Api.post(
    `account_entries/${accountEntryId}/save_new_generated_invoice_version`,
    ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION,
    {},
    {
      ownershipId,
    }
  )
}

export const saveNewReceiptToAccountEntry = (accountEntryId, ownershipId) => {
  return Api.post(
    `account_entries/${accountEntryId}/save_new_generated_receipt_version`,
    ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION,
    {},
    {
      ownershipId,
    }
  )
}

export const destroyAccountPayment = (accountPaymentId) => {
  return Api.del(
    `account_payments/${accountPaymentId}`,
    ActionTypes.DESTROY_ACCOUNT_PAYMENT,
    {},
  );
}
