import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetching: false,
  ownerships: {},
  error: null,
  isCreating: false,
  creatingError: null,
};

export default (state = initialState, action) => {
  let ownership, ownerships;
  switch (action.type) {
    case `${ActionTypes.FETCH_OWNERSHIPS}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case `${ActionTypes.FETCH_OWNERSHIPS}_${ActionTypes.CALL_API_SUCCESS}`:
      ownerships = { ...state.ownerships };
      action.responseData.ownerships.forEach((ownership) => {
        ownerships[ownership.id] = {
          ...ownerships[ownership.id],
          ...ownership,
        };
      })
      return {
        ...state,
        isFetching: false,
        ownerships: ownerships,
        error: null,
      };
    case `${ActionTypes.FETCH_OWNERSHIPS}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case `${ActionTypes.CREATE_OWNERSHIP}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isCreating: true,
        creatingError: null,
      };
    case `${ActionTypes.CREATE_OWNERSHIP}_${ActionTypes.CALL_API_SUCCESS}`:
      ownership = { ...action.responseData.ownership };
      return {
        ...state,
        isCreating: false,
        creatingError: null,
        ownerships: {
          ...state.ownerships,
          [ownership.id]: ownership,
        },
      };
    case `${ActionTypes.CREATE_OWNERSHIP}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isCreating: false,
        creatingError: action.error,
      };
    case `${ActionTypes.FETCH_OWNERSHIP}_${ActionTypes.CALL_API_REQUEST}`:
      ownership = { ...state.ownerships[action.metaData.ownershipId] };
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [action.metaData.ownershipId]: {
            ...ownership,
            id: action.metaData.ownershipId,
            isFetching: true,
          },
        },
      };
    case `${ActionTypes.FETCH_OWNERSHIP}_${ActionTypes.CALL_API_SUCCESS}`:
      ownership = { ...action.responseData.ownership };
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [ownership.id]: ownership,
        },
      };
    case `${ActionTypes.FETCH_OWNERSHIP}_${ActionTypes.CALL_API_ERROR}`:
      ownership = { ...state.ownerships[action.metaData.ownershipId] };
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [action.metaData.ownershipId]: {
            ...ownership,
            id: action.metaData.ownershipId,
            isFetching: false,
            fetchOwnershipError: action.error,
          },
        },
      };
    case `${ActionTypes.ADD_OWNER_TO_OWNERSHIP}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.CREATE_TENANCY}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.UPDATE_OWNERSHIP_DETAILS}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ATTACH_DOCUMENT}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ADD_PAYMENT}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.UPDATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.DELETE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION}_${ActionTypes.CALL_API_REQUEST}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION}_${ActionTypes.CALL_API_REQUEST}`:
      ownership = { ...state.ownerships[action.metaData.ownershipId] };
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [action.metaData.ownershipId]: {
            ...ownership,
            id: action.metaData.ownershipId,
            isUpdating: true,
          },
        },
      };
    case `${ActionTypes.ADD_OWNER_TO_OWNERSHIP}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.CREATE_TENANCY}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.UPDATE_OWNERSHIP_DETAILS}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ATTACH_DOCUMENT}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ADD_PAYMENT}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.UPDATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.DELETE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION}_${ActionTypes.CALL_API_SUCCESS}`:
      ownership = { ...action.responseData.ownership };
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [ownership.id]: ownership,
        },
      };
    case `${ActionTypes.ADD_OWNER_TO_OWNERSHIP}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.CREATE_TASK}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.CREATE_TENANCY}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.UPDATE_OWNERSHIP_DETAILS}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ATTACH_DOCUMENT}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.ACCOUNT_ENTRY_ADD_PAYMENT}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.CREATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.UPDATE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.DELETE_ACCOUNT_ENTRY_LINE_ITEM}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_INVOICE_VERSION}_${ActionTypes.CALL_API_ERROR}`:
    case `${ActionTypes.ACCOUNT_ENTRY_SAVE_NEW_RECEIPT_VERSION}_${ActionTypes.CALL_API_ERROR}`:
      ownership = state.ownerships[action.metaData.ownershipId];
      return {
        ...state,
        ownerships: {
          ...state.ownerships,
          [action.metaData.ownershipId]: {
            ...ownership,
            id: action.metaData.ownershipId,
            isUpdating: false,
            updateError: action.error,
          },
        },
      };
    default:
      return state;
  }
};
