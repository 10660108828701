import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Modal,
  Spin,
} from 'antd';

import { PDFObject } from 'react-pdfobject'

class PreviewPdfModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    pdfUrl: PropTypes.string,
  }

  static defaultProps = {
    title: "",
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { pdfUrl } = this.props;
    if (!pdfUrl) {
      return null;
    }
    return (
      <Modal
        width="90%"
        style={{ top: 20 }}
        bodyStyle={{ height: 'calc(100vh - 100px)' }}
        visible={ this.props.visible }
        title={ this.props.title }
        onCancel={ this.props.onCancel }
        footer={ null }
      >
        { this.props.children }
        <div style={{ height: "95%", display: "flex", justifyContent: "center", alignItems: "center", position: 'relative' }}>
          <Spin size="large" style={{ position: 'absolute', top: 20 }}/>
            <PDFObject
              containerProps={{
                style: { height: '100%', width: '80%', position: 'absolute', top: 0 }
              }}
              url={ pdfUrl }
            />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPdfModal);
