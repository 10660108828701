import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Col,
  Descriptions,
  Icon,
  message,
  Popconfirm,
  Row,
  Table,
  Timeline,
} from 'antd';

import moment from 'moment';
import numeral from 'numeral';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';
import * as AccountEntryActions from '@actions/accountEntries';
import EditableTable from '@components/EditableTable';
import DocumentPreview from '@components/DocumentPreview';

class AccountEntryShow extends React.Component {
  static propTypes = {
    showPropertyName: PropTypes.bool,
    accountEntry: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    updateItemState: PropTypes.func.isRequired,
    destroyAccountPayment: PropTypes.func.isRequired,
  }

  static defaultProps = {
    showPropertyName: false,
    updateItemState: (newItem) => {},
  }

  constructor(props) {
    super(props);
    this.onSaveLineItem = this.onSaveLineItem.bind(this);
    this.onDeleteLineItem = this.onDeleteLineItem.bind(this);
  }

  async onSaveLineItem(key, row) {
    const { accountEntry } = this.props;
    const newData = [...accountEntry.account_entry_line_items];
    const index = newData.findIndex(item => key === item.id);
    if (index > -1) {
      const item = {
        ...newData[index],
        ...row,
      };
      try {
        this.props.updateItemState({
          ...accountEntry,
          isUpdating: true,
        })
        const responseData = await this.props.updateAccountEntryLineItem(accountEntry.id, accountEntry.ownership_id, item);
        this.props.updateItemState(responseData.account_entry);
        message.success(this.props.t("AccountEntries.messages.UpdateLineItem.Success"));
        return responseData;
      } catch (error) {
        this.props.updateItemState({
          ...accountEntry,
          isUpdating: false,
          errorUpdating: error,
        })
        message.error(this.props.t("AccountEntries.messages.UpdateLineItem.Fail"));
        throw error;
      }
    } else {
      try {
        this.props.updateItemState({
          ...accountEntry,
          isUpdating: true,
        });
        const responseData = await this.props.createAccountEntryLineItem(accountEntry.id, accountEntry.ownership_id, row);
        this.props.updateItemState(responseData.account_entry);
        message.success(this.props.t("AccountEntries.messages.CreateLineItem.Success"));
        return responseData;
      } catch(error) {
        this.props.updateItemState({
          ...accountEntry,
          isUpdating: false,
          errorUpdating: error,
        });
        message.error(this.props.t("AccountEntries.messages.CreateLineItem.Fail"));
        throw error;
      }
    }
  }

  async onDeleteLineItem(key) {
    const { accountEntry } = this.props;
    try {
      this.props.updateItemState({
        ...accountEntry,
        isUpdating: true,
      })
      const responseData = await this.props.deleteAccountEntryLineItem(accountEntry.id, accountEntry.ownership_id, key);
      this.props.updateItemState(responseData.account_entry);
      message.success(this.props.t("AccountEntries.messages.DestroyLineItem.Success"));
      return responseData;
    } catch (error) {
      this.props.updateItemState({
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      })
      message.error(this.props.t("AccountEntries.messages.DestroyLineItem.Fail"));
      throw error;
    }
  }

  renderLineItems(accountEntry) {
    if ((accountEntry.account_entry_line_items || []).length === 0) {
      return null
    }

    return (
      <Row style={{ marginBottom: '30px', backgroundColor: 'white', padding: '20px' }}>
        <EditableTable
          loading={ accountEntry.isFetching || accountEntry.isUpdating }
          dataSource={ accountEntry.account_entry_line_items }
          onSaveRecord={ this.onSaveLineItem }
          onDeleteRecord={ this.onDeleteLineItem }
          canAdd={ true }
          title={ <h3 style={{ display: 'inline-block', marginRight: 10 }}>{ this.props.t("AccountEntries.LineItems") }</h3> }
          columns={[{
            title: this.props.t("AccountEntries.LineItemType"),
            dataIndex: 'item_type',
            inputType: 'string',
            key: 'item_type',
            editable: true,
          }, {
            title: this.props.t("shared.Description"),
            dataIndex: 'description',
            inputType: 'string',
            key: 'description',
            editable: true,
          }, {
            title: this.props.t("shared.Amount"),
            dataIndex: 'amount',
            inputType: 'currency',
            key: 'amount',
            editable: true,
            render: (amount) => numeral(amount).format('($0,0.[00])'),
          }]}
        />
      </Row>
    )
  }

  renderPayments(accountEntry) {
    if ((accountEntry.account_entry_payments || []).length === 0 && accountEntry.isFetching) {
      return null
    }

    return (
      <Row style={{ marginBottom: '30px', backgroundColor: 'white', padding: '20px' }}>
        <h3>{ this.props.t("AccountEntries.CompletedPayments")}</h3>
        <Table
          rowKey={ record => record.id }
          loading={ accountEntry.isFetching || accountEntry.isUpdating }
          dataSource={ accountEntry.account_entry_payments }
          pagination={ false }
          locale={{ emptyText: (<span>{ this.props.t("AccountEntries.NoPayments") }</span>)}}
          columns={[{
            title: this.props.t("AccountEntries.PaymentDate"),
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (payment_date) => DateUtils.formatDate(payment_date),
          }, {
            title: this.props.t("AccountEntries.Reference"),
            dataIndex: 'reference_number',
            key: 'reference_number',
          }, {
            title: this.props.t("AccountEntries.PaymentAmount"),
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (total_amount, record) => {
              const totalAmount = numeral(total_amount).format('($0,0.[00])')
              if (total_amount === record.reconciled_amount) {
                return totalAmount
              }
              return `${numeral(record.reconciled_amount).format('($0,0.[00])')} of total: ${totalAmount}`;
            },
          }, {
            title: this.props.t("shared.Notes"),
            dataIndex: 'note',
            key: 'note',
          // }, {
          //   title: 'Attachment',
          //   dataIndex: 'attachment',
          //   key: 'attachment',
          }, {
            title: this.props.t("shared.Action"),
            dataIndex: 'action',
            key: 'action',
            render: (actions, record) => {
              return (
                <Popconfirm title="Sure to delete?" onConfirm={() => this.props.destroyAccountPayment(record.account_payment_id)}>
                  <a>Delete</a>
                </Popconfirm>
              )
            },
          }]}
        />
      </Row>
    )
  }

  renderTimeline(accountEntry) {
    return (
      <Timeline>
        {
          (accountEntry.account_entry_activity_logs || []).map((log) => {
            const user = (<strong style={{ paddingRight: '5px' }}>{ log.user_display_name }</strong>);
            let color = "blue";
            if (log.note.toLowerCase().includes("added")) {
              color = "green";
            } else if (log.note.toLowerCase().includes("removed")) {
              color = "red";
            }
            return (
              <Timeline.Item
                key={ `AccountEntryActivityLog_${log.id}` }
                color={ color }
              >
                { user } { log.note }
                <br />
                { moment(log.created_at).fromNow() }
              </Timeline.Item>
            )
          })
        }
      </Timeline>
    )
  }

  render() {
    const { accountEntry } = this.props;
    return (
      <div>
        { this.renderLineItems(accountEntry) }
        { this.renderPayments(accountEntry) }
        <Row style={{ backgroundColor: 'white', padding: '20px' }}>
          <Col
            span={ 12 }
            style={{ paddingRight: '10px' }}
          >
            <Descriptions
              title={ this.props.t("AccountEntries.EntryInfo") }
              layout="vertical"
              column={{ xs: 1, sm: 2, xxl: 2 }}
            >
              {
                (accountEntry.period_start_date && accountEntry.period_end_date && (
                  <Descriptions.Item label={ <strong>{ this.props.t("AccountEntries.Period") }</strong> }>
                    { DateUtils.formatDate(accountEntry.period_start_date) } - { DateUtils.formatDate(accountEntry.period_end_date) }
                  </Descriptions.Item>
                ))
              }
              {
                (this.props.showPropertyName && (
                  <Descriptions.Item label={ <strong>{ this.props.t("Properties.Address") }</strong> }>
                    { accountEntry.ownership.address }
                  </Descriptions.Item>
                ))
              }
              <Descriptions.Item label={ <strong>{ this.props.t("AccountEntries.BankDetails") }</strong> }>
                { accountEntry.ownership.bank_name }
                <br />
                { accountEntry.ownership.bank_account_name }
                <br />
                { accountEntry.ownership.bank_account_number }
              </Descriptions.Item>
              {
                (accountEntry.owners && accountEntry.owners.length > 0 && (
                  <Descriptions.Item label={ <strong>{ this.props.t("Owners.Title") }</strong> }>
                    { (accountEntry.owners).map((owner) => owner.name) }
                  </Descriptions.Item>
                ))
              }
              {
                (accountEntry.notes && (
                  <Descriptions.Item label={ <strong>{ this.props.t("shared.Notes") }</strong> }>
                    { accountEntry.notes }
                  </Descriptions.Item>
                ))
              }
            </Descriptions>
          </Col>
          <Col
            span={ 12 }
            style={{ paddingLeft: '10px' }}
          >
            <div style={{ paddingTop: 10, maxHeight: '250px', overflowY: 'scroll' }}>
              { (accountEntry.isFetching || accountEntry.isUpdating) && <Icon type="loading" /> }
              { this.renderTimeline(accountEntry) }
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
              {
                (accountEntry.documents || []).map((document, index) => (
                  <DocumentPreview
                    key={ `Document_${index}`}
                    document={ document }
                  />
                ))
              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    createAccountEntryLineItem: (aeId, ownershipId, lineItem = {}) => {
      const { amount, ...rest } = lineItem;
      return dispatch(AccountEntryActions.createLineItem(aeId, ownershipId, {
        amount_in_cents: Math.round(amount * 100),
        ...rest,
      }));
    },
    updateAccountEntryLineItem: (aeId, ownershipId, lineItem = {}) => {
      const { id, amount, ...rest } = lineItem;
      return dispatch(AccountEntryActions.updateLineItem(aeId, ownershipId, {
        account_entry_line_item_id: id,
        amount_in_cents: Math.round(amount * 100),
        ...rest
      }));
    },
    deleteAccountEntryLineItem: (aeId, ownershipId, lineItemId) => {
      return dispatch(AccountEntryActions.deleteLineItem(aeId, ownershipId, {
        account_entry_line_item_id: lineItemId,
      }));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AccountEntryShow));
