import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  ListingTypeOptions,
} from '@constants/models/Listing';
import * as ArrayUtils from '@utils/array';
import * as OwnershipActions from '@actions/ownerships';
import QuillEditor from '@components/QuillEditor'
import AsyncUpload from '@components/AsyncUpload';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnerships: false,
    ownershipsById: {},
    errorFetchingOwnerships: null,

    fileDataMap: {},

    selectedListingType: null,
    ...state,
  };
}

class CreateForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onOk = this.onOk.bind(this);
    this.onChangeSelectedType = this.onChangeSelectedType.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.updateFileDataMap = this.updateFileDataMap.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwnerships: true });
      this.props.fetchOwnershipsWithManagers().then((responseData) => {
        this.setState({
          isFetchingOwnerships: false,
          ownershipsById: {
            ...ArrayUtils.arrayToObject(responseData.ownerships),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnerships: false,
          errorFetchingOwnerships: error,
        })
      });
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onOk() {
    this.props.form.setFieldsValue({
      images: Object.values(this.state.fileDataMap).map((fileMetaData) => fileMetaData.blobSignedId),
    });
    this.props.onOk();
  }

  onChangeSelectedType(value) {
    this.setState({ selectedListingType: value });
  }

  renderAmountLabel() {
    const { selectedListingType } = this.state;
    if (selectedListingType) {
      if (selectedListingType === "rent") {
        return this.props.t("Listings.MonthlyAmount");
      } else {
        return this.props.t("Listings.SaleAmount");
      }
    }
    return this.props.t("shared.Amount");
  }

  handleChangeQuill(key, value) {
    this.props.form.setFieldsValue({
      [key]: value,
    })
  }

  updateFileDataMap(fileDataMap) {
    this.setState({ fileDataMap });
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("ownership_id", {
              rules: [
                { required: true, message: `${this.props.t("Properties.messages.Form.PleaseSelectProperty")}!` },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("Properties.messages.Form.PleaseSelectProperty") }
                loading={ this.state.isFetchingOwnerships }
                showSearch={ (Object.values(this.state.ownershipsById).length > 5) }
                filterOption={ (inputValue, option) => {
                  const ownershipId = parseInt(option.key);
                  const ownership = this.state.ownershipsById[ownershipId];
                  if (ownership.name.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  if (ownership.address.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  return false;
                }}
                notFoundContent={
                  this.state.isFetchingOwnerships ? <Spin size="small" /> : null
                }
              >
                {
                  Object.values(this.state.ownershipsById).map((ownership) => (
                    <Select.Option
                      key={ ownership.id.toString() }
                    >
                      { ownership.name }. { ownership.address }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingType") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("listing_type", {
              rules: [
                { required: true, message: `${this.props.t("messages.Form.PleaseSelectType")}!` },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("messages.Form.PleaseSelectType") }
                onChange={ this.onChangeSelectedType }
              >
                {
                  Object.values(ListingTypeOptions).map((listingType) => (
                    <Select.Option
                      key={ listingType.value }
                    >
                      { this.props.t(listingType.ttextKey) }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.ListingName") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
          >
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please input a short name" }]
          })(<Input placeholder="2 bedroom, 1 bathroom, sea-view" />)}
        </Form.Item>
        {
          this.state.selectedListingType && (
            <Form.Item
              label={ this.renderAmountLabel() }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              {getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Please input amount' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          )
        }
        {
          this.state.selectedListingType === "rent" && (
            <Form.Item
              label={ this.props.t("shared.SecurityDeposit") }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              {getFieldDecorator('security_deposit', {
                rules: [{ required: false, message: `${ this.props.t("Listings.messages.Form.PleaseInputSecurityDeposit") }!` }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          )
        }
        <Form.Item
          label={ this.props.t("Listings.AgentCommission") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('agent_commission_fixed', {
            rules: [{ required: false, message: `${this.props.t("Listings.messages.Form.PleaseInputAgentCommission") }!` }],
          })(
            <InputNumber
              style={{ width: '100%', minWidth: '150px' }}
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.AvailableFrom") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("available_date")(<DatePicker format={"YYYY/MM/DD"} />)}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.LongDescription") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("description", {
            initialValue: null
          })(<Input.TextArea style={{ display: 'none' }} />)}
          <QuillEditor
            value={ this.props.form.getFieldValue('description') }
            onChange={ (value) => this.handleChangeQuill("description", value) }
          />
        </Form.Item>
        <Form.Item
          label={ this.props.t("Listings.InternalNotes") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("internal_notes", {
            initialValue: null
          })(<Input.TextArea style={{ display: 'none' }} />)}
          <QuillEditor
            value={ this.props.form.getFieldValue('internal_notes') }
            onChange={ (value) => this.handleChangeQuill("internal_notes", value) }
          />
        </Form.Item>
        {
          (this.state.selectedListingType === "rent") && (
            <Form.Item
              label={ this.props.t("Listings.MinLeasePeriod") }
              style={{ margin: 0, paddingBottom: 14 }}
              {...formItemLayout}
            >
              {getFieldDecorator('min_lease_period_in_months', {
                rules: [{ required: false, message: 'Please input minimum lease period' }],
              })(
                <InputNumber
                  style={{ width: '100%', minWidth: '150px' }}
                  min={0}
                />
              )}
            </Form.Item>
          )
        }
        <Form.Item
          label={ this.props.t("Listings.Photos") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          <div className="dropbox">
            {getFieldDecorator("images", {
              getValueFromEvent: this.normFile,
              rules: [
                { required: false, message: `${this.props.t("Listings.messages.Form.PleaseSelectImages") }!` },
              ],
            })(
              <AsyncUpload
                name="images"
                accept="image/png, image/jpeg"
                multiple={ true }
                fileDataMap={ this.state.fileDataMap }
                updateFileDataMap={ this.updateFileDataMap }
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </AsyncUpload>
            )}
          </div>
        </Form.Item>
      </Form>
    )
  }

  render() {
    return (
      <Modal
        width="80%"
        visible={ this.props.visible }
        title={ this.props.t("Listings.actions.CreateListing") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipsWithManagers: (params) => {
      return (dispatch(OwnershipActions.fetchOwnershipsWithManagers(params)));
    },
  };
}

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

const WrappedCreateForm = Form.create({
  name: "listings_creation_form_in_modal"
})(ConnectedCreateForm);

export default withTranslation('common')(WrappedCreateForm);
