import React from 'react';
import PropTypes from 'prop-types';

import '@components/LayoutStyle.scss';
import Documents from '@components/Documents';

export default class DocumentsMainView extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="main-full-view">
        <Documents />
      </div>
    )
  }
}
