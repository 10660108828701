import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Descriptions,
  Icon,
  List,
} from 'antd';

import { withTranslation } from 'react-i18next';
import numeral from 'numeral';

import { ThemeColors } from '@constants/Theme';
import * as DateUtils from '@utils/date';
import * as FormatterUtils from '@utils/formatter';
import DocumentPreview from '@components/DocumentPreview';

class TenancyShow extends React.Component {
  static propTypes = {
    tenancy: PropTypes.object,
  }

  static defaultProps = {
    tenancy: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      showDocuments: false,
    }
  }

  renderDocuments(documents) {
    if (documents.length == 0) {
      return null
    }
    return (
      <div>
        <a onClick={ () => this.setState({ showDocuments: !this.state.showDocuments }) }>
          { this.state.showDocuments ? "Hide documents" : "Show documents" }
          <Icon type={this.state.showDocuments ? 'up' : 'down'} />
        </a>
        <div style={{ marginTop: "10px", overflowX: 'scroll', whiteSpace: 'nowrap' }}>
          {
            this.state.showDocuments && documents.map((document, index) => (
              <DocumentPreview
                key={ `Document_${index}`}
                document={ document }
              />
            ))
          }
        </div>
      </div>
    )
  }

  getDescriptionItems(tenancy) {
    return [{
      label: this.props.t("Tenancies.TenancyPeriod"),
      renderChildren: () => (
        <React.Fragment>
          <strong>{ this.props.t("Tenancies.TenancyPeriod") }:</strong> { DateUtils.formatDateRange(tenancy.start_date, tenancy.end_date) }
          <br />
          <strong>{ this.props.t("Tenancies.RentFreePeriod") }:</strong> { DateUtils.formatDateRange(tenancy.rent_free_start_date, tenancy.rent_free_end_date) }
          {
            tenancy.early_termination_date && (
              <div style={{ color: ThemeColors.red }}>
                <strong>{ this.props.t("Tenancies.EarlyTerminationDate") }:</strong> { DateUtils.formatDate(tenancy.early_termination_date) }
              </div>
            )
          }
        </React.Fragment>
      ),
    }, {
      label: this.props.t("Tenancies.RentAmount"),
      renderChildren: () => (
        <React.Fragment>
          <strong>{ this.props.t("Listings.MonthlyAmount") }:</strong> { numeral(tenancy.rental_amount).format('($0,0)') }
          <br />
          <strong>{ this.props.t("shared.SecurityDeposit") }:</strong> { numeral(tenancy.security_deposit).format('($0,0)') }
        </React.Fragment>
      ),
    }, {
      label: this.props.t("Tenancies.TenancyTerms.Title"),
      renderChildren: () => (
        <React.Fragment>
          <strong>{ this.props.t("Tenancies.TenancyTerms.ManagementFee") }:</strong> { tenancy.include_mgmt_fee ? "INCLUDED" : "EXCLUDED" }
          <br />
          <strong>{ this.props.t("Tenancies.TenancyTerms.GovtRates") }:</strong> { tenancy.include_govt_rates ? "INCLUDED" : "EXCLUDED" }
          <br />
          <strong>{ this.props.t("Tenancies.TenancyTerms.GovtRent") }:</strong> { tenancy.include_govt_rent ? "INCLUDED" : "EXCLUDED" }
        </React.Fragment>
      ),
    }, {
      label: this.props.t("Tenancies.PaymentDate"),
      renderChildren: () => (
        <React.Fragment>
          { tenancy.rent_due }
        </React.Fragment>
      ),
    }, {
      label: this.props.t("shared.ContactDetails"),
      renderChildren: () => (
        <React.Fragment>
          { tenancy.official_tenant_email }<br />
          { tenancy.official_tenant_phone_number}<br />
          { tenancy.correspondence_address }
        </React.Fragment>
      ),
    }, {
      label: this.props.t("shared.Notes"),
      renderChildren: () => (
        <React.Fragment>
          <span style={{ whiteSpace: 'pre-line' }}>
            { tenancy.notes }
          </span>
        </React.Fragment>
      ),
    }]
  }

  render() {
    const { tenancy } = this.props;

    return (
      <div>
        <div>
          <Descriptions
            title=""
            layout="vertical"
            bordered
            size="small"
          >
            {
              this.getDescriptionItems(tenancy).map((item, index) => (
                <Descriptions.Item
                  key={ index.toString() }
                  label={ <strong>{ item.label }</strong> }
                >
                  { item.renderChildren() }
                </Descriptions.Item>
              ))
            }
          </Descriptions>
        </div>
        { this.renderDocuments(tenancy.documents || []) }
      </div>
    )
  }
}

export default withTranslation('common')(TenancyShow);
