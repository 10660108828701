import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Col,
  message,
  notification,
  Row,
} from 'antd';

import moment from 'moment';
import qs from 'qs';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as DateUtils from '@utils/date';
import * as AccountEntryActions from '@actions/accountEntries';
import AttachDocumentModal from '@components/AttachDocumentModal';
import AccountEntriesTable from './Table';
import AccountEntryShow from './Show';
import CreateForm from './CreateForm';
import FilterForm from './FilterForm';
import MarkAsVoidForm from './MarkAsVoidForm';
import AddPaymentForm from './AddPaymentForm';
import PreviewPdfModal from './PreviewPdfModal';
import PreviewInvoiceModal from './PreviewInvoiceModal';
import PreviewReceiptModal from './PreviewReceiptModal';
import DownloadAccountEntriesModal from './DownloadAccountEntriesModal';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isFetching: false,
    isCreating: false,
    itemsById: {},
    errorFetching: null,
    errorCreating: null,

    // modals
    selectedItemId: null,
    attachDocumentModalVisible: false,
    addPaymentModalVisible: false,
    markAsVoidModalVisible: false,
    previewInvoiceModalVisible: false,
    previewRentalReminderModalVisible: false,
    previewReceiptModalVisible: false,
    createModalVisible: false,

    // search params
    filteredInfo: {
      displayName: '',
      address: '',
      reference: '',
      selectedAccountEntryTypes: [],
      selectedAccountStates: [],
      selectedOwners: [],
      accountsDateRange: null,
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      columnKey: 'state',
      order: 'ascend',
    },

    ...state,
  }
}

class AccountEntries extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.fetchAccountEntries = this.fetchAccountEntries.bind(this);
    this.fetchAccountEntry = this.fetchAccountEntry.bind(this);
    this.onExpandTableRow = this.onExpandTableRow.bind(this);
    this.onItemAction = this.onItemAction.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.onMarkAsVoidSubmit = this.onMarkAsVoidSubmit.bind(this);
    this.onAddPaymentSubmit = this.onAddPaymentSubmit.bind(this);
    this.onSaveNewInvoiceVersion = this.onSaveNewInvoiceVersion.bind(this);
    this.onSaveNewReceiptVersion = this.onSaveNewReceiptVersion.bind(this);
    this.attachDocumentToAccountEntry = this.attachDocumentToAccountEntry.bind(this);
    this.updateItemState = this.updateItemState.bind(this);
  }

  componentDidMount() {
    this.fetchAccountEntries();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.setState({
        pagination: {
          pageSize: this.state.pagination.pageSize,
          current: 1,
        },
      }, this.fetchAccountEntries)
    }
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    }, this.fetchAccountEntries)
  }

  handleListChange(pagination, filters, sorter) {
    this.setState({
      pagination,
      sorter,
    }, this.fetchAccountEntries);
  }

  getFilterParams() {
    const { filteredInfo } = this.state;
    const ownershipIdFilter = (this.props.ownershipId) ? { ownership_id: this.props.ownershipId } : {};
    return {
      sortField: this.state.sorter.columnKey,
      sortOrder: this.state.sorter.order,
      filter: {
        ...filteredInfo,
        accountsDateRange: filteredInfo && filteredInfo.accountsDateRange && (filteredInfo.accountsDateRange.length == 2) && [
          filteredInfo.accountsDateRange[0].format("YYYY-MM-DD"),
          filteredInfo.accountsDateRange[1].format("YYYY-MM-DD"),
        ],
      },
      ...ownershipIdFilter,
    };
  }

  async fetchAccountEntries(params = {}) {
    this.setState({ isFetching: true });
    try {
      let responseData = await this.props.fetchAccountEntries({
        page: this.state.pagination.current,
        results: this.state.pagination.pageSize,
        ...this.getFilterParams(),
        ...params,
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.account_entries),
        },
        pagination: {
          ...this.state.pagination,
          total: responseData.pagy ? responseData.pagy.count : responseData.account_entries.length,
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      })
      return null;
    }
  }

  updateItemState(id, item = {}) {
    this.setState({
      itemsById: {
        ...this.state.itemsById,
        [id]: item,
      },
    });
  }

  async fetchAccountEntry(record, params = {}) {
    this.updateItemState(record.id, {
      ...record,
      isFetching: true,
    })
    try {
      let responseData = await this.props.fetchAccountEntry(record.id, record.ownership_id);
      this.updateItemState(record.id, {
        ...responseData.account_entry,
      });
      return responseData;
    } catch(error) {
      this.updateItemState(record.id, {
        ...record,
        isFetching: false,
        errorFetching: error,
      });
      return null;
    }
  }

  async onCreateSubmit() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      const ownershipId = this.props.ownershipId ? this.props.ownershipId : values["ownership_id"];
      if (!ownershipId) {
        return
      }
      this.setState({ isCreating: true });
      let responseData = await this.props.createAccountEntry(ownershipId, values);
      const newAccountEntry = responseData.account_entry;
      this.setState({
        isCreating: false,
        createModalVisible: false,
      });
      this.updateItemState(newAccountEntry.id, newAccountEntry);
      message.success(this.props.t("AccountEntries.messages.CreateAccountEntry.Success"));
      return responseData;
    } catch (error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      });
      return null;
    }
  }

  async attachDocumentToAccountEntry(documentId) {
    let accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    if (!accountEntry) {
      return;
    }
    try {
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: true,
      });
      let responseData = await this.props.attachDocumentToAccountEntry(accountEntry.id, accountEntry.ownership_id, documentId);
      const updatedAccountEntry = responseData.account_entry;
      this.updateItemState(updatedAccountEntry.id, {
        ...updatedAccountEntry,
      });
      message.success(this.props.t("Documents.messages.AttachDocument.Success"));
      return responseData;
    } catch (error) {
      accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("Documents.messages.AttachDocument.Fail"));
      throw error
    }
  }

  async onMarkAsVoidSubmit() {
    let accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    if (!accountEntry) {
      return;
    }
    const { form } = this.markAsVoidFormRef.props;
    try {
      let values = await form.validateFields();
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: true,
      });
      let responseData = await this.props.markAsVoidAccountEntry(accountEntry.id, accountEntry.ownership_id, values);
      const updatedAccountEntry = responseData.account_entry;
      this.updateItemState(updatedAccountEntry.id, {
        ...updatedAccountEntry,
      });
      this.setState({
        selectedItemId: null,
        markAsVoidModalVisible: false,
      })
      message.success(this.props.t("AccountEntries.messages.VoidAccountEntry.Success"));
      return responseData;
    } catch (error) {
      accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("AccountEntries.messages.VoidAccountEntry.Fail"));
      return null;
    }
  }

  async onAddPaymentSubmit() {
    const { form } = this.addPaymentFormRef.props;
    let accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    if (!accountEntry) {
      return;
    }

    try {
      let { payment_amount, ...values } = await form.validateFields();
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: true,
      });
      let responseData = await this.props.addPaymentToAccountEntry(accountEntry.id, accountEntry.ownership_id, {
        ...values,
        payment_amount: (accountEntry.amount < 0) ? payment_amount * -1 : payment_amount,
      });
      const updatedAccountEntry = responseData.account_entry;
      this.updateItemState(updatedAccountEntry.id, {
        ...updatedAccountEntry,
      });
      this.setState({
        selectedItemId: null,
        addPaymentModalVisible: false,
      })
      message.success(this.props.t("AccountEntries.messages.AddPaymentToEntry.Success"));
      return responseData;
      return
    } catch (error) {
      accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("AccountEntries.messages.AddPaymentToEntry.Fail"));
      return null;
    }
  }

  async onDestroyAccountPayment(accountPaymentId) {
    this.setState({ isFetching: true });
    try {
      let responseData = await this.props.destroyAccountPayment(accountPaymentId);
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...this.state.itemsById,
          ...ArrayUtils.arrayToObject(responseData.account_entries),
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
      })
      return null;
    }
  }

  async onSaveNewInvoiceVersion() {
    let accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    if (!accountEntry) {
      return;
    }
    try {
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: true,
      });
      let responseData = await this.props.saveNewInvoiceToAccountEntry(accountEntry.id, accountEntry.ownership_id);
      const updatedAccountEntry = responseData.account_entry;
      this.updateItemState(updatedAccountEntry.id, {
        ...updatedAccountEntry,
      });
      message.success(this.props.t("AccountEntries.messages.SaveInvoiceForAccountEntry.Success"));
      return responseData;
    } catch (error) {
      accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("AccountEntries.messages.SaveInvoiceForAccountEntry.Fail"));
      throw error;
    }
  }

  async onSaveNewReceiptVersion() {
    let accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    if (!accountEntry) {
      return;
    }
    try {
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: true,
      });
      let responseData = await this.props.saveNewReceiptToAccountEntry(accountEntry.id, accountEntry.ownership_id);
      const updatedAccountEntry = responseData.account_entry;
      this.updateItemState(updatedAccountEntry.id, {
        ...updatedAccountEntry,
      });
      message.success(this.props.t("AccountEntries.messages.SaveReceiptForAccountEntry.Success"));
      return responseData;
    } catch (error) {
      accountEntry = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
      this.updateItemState(accountEntry.id, {
        ...accountEntry,
        isUpdating: false,
        errorUpdating: error,
      });
      message.error(this.props.t("AccountEntries.messages.SaveReceiptForAccountEntry.Fail"));
      throw error;
    }
  }

  onItemAction(record, key) {
    switch (key) {
      case "markAsVoid":
        this.setState({ selectedItemId: record.id, markAsVoidModalVisible: true });
        return
      case "addPayment":
        this.setState({ selectedItemId: record.id, addPaymentModalVisible: true });
        return
      case "attachDocument":
        this.setState({ selectedItemId: record.id, attachDocumentModalVisible: true });
        return
      case "previewInvoice":
        this.setState({ selectedItemId: record.id, previewInvoiceModalVisible: true });
        return
      case "sendInvoice":
        return this.props.sendAccountEntryInvoice(record.id).then((responseData) => {
          notification.success({
            message: `Successfully sent invoice`,
            description: responseData.msg,
          });
        }).catch((error) => {
          notification.error({
            message: 'Unable to send invoice',
            description: error.response.data.msg,
          });
        })
      case "previewOutstandingRentalReminder":
        this.setState({ selectedItemId: record.id, previewRentalReminderModalVisible: true });
        return
      case "sendPaymentReminder":
        return this.props.sendAccountEntryPaymentReminder(record.id).then((responseData) => {
          notification.success({
            message: `Successfully sent payment reminder`,
            description: responseData.msg,
          });
        }).catch((error) => {
          notification.error({
            message: 'Unable to send payment reminder',
            description: error.response.data.msg,
          });
        })
      case "previewReceipt":
        this.setState({ selectedItemId: record.id, previewReceiptModalVisible: true });
        return
      case "sendReceipt":
        return this.props.sendAccountEntryReceipt(record.id).then((responseData) => {
          notification.success({
            message: `Successfully sent receipt`,
            description: responseData.msg,
          });
        }).catch((error) => {
          notification.error({
            message: 'Unable to send receipt',
            description: error.response.data.msg,
          });
        });
      default:
        return
    }
  }

  onExpandTableRow(expanded, record) {
    if (!expanded) {
      return;
    }

    this.fetchAccountEntry(record);
  }

  getFilteredResults(items) {
    const { filteredInfo } = this.state;
    return items.filter((accountEntry) => {
      if (!filteredInfo) { return true };
      if (filteredInfo.reference) {
        if (!(accountEntry.invoice_number && accountEntry.invoice_number.toLowerCase().includes(filteredInfo.reference.toLowerCase()))) {
          return false
        }
      }
      if (filteredInfo.selectedAccountEntryTypes && filteredInfo.selectedAccountEntryTypes.length > 0) {
        if (!filteredInfo.selectedAccountEntryTypes.includes(accountEntry.entry_type)) {
          return false
        }
      }
      if (filteredInfo.selectedAccountStates && filteredInfo.selectedAccountStates.length > 0) {
        if (!filteredInfo.selectedAccountStates.includes(accountEntry.state)) {
          return false
        }
      }
      if (filteredInfo.accountsDateRange && filteredInfo.accountsDateRange.length == 2) {
        if (moment(accountEntry.due_date).isBefore(filteredInfo.accountsDateRange[0], 'day')) {
          return false
        }
        if (moment(accountEntry.due_date).isAfter(filteredInfo.accountsDateRange[1], 'day')) {
          return false
        }
      }
      return true
    })
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  saveMarkAsVoidFormRef = formRef => {
    this.markAsVoidFormRef = formRef;
  }

  saveAddPaymentFormRef = formRef => {
    this.addPaymentFormRef = formRef;
  }

  renderRentalReminderModal(item) {
    if (!item) { return null }
    return (
      <PreviewPdfModal
        title={ `Preview rental reminder for: ${item.ownership.name} ${this.props.AccountEntryTypeOptions[item.entry_type].text} - ${DateUtils.formatDate(item.due_date)}` }
        pdfUrl={ item.preview_rental_reminder_url }
        visible={ this.state.previewRentalReminderModalVisible }
        onCancel={ () => this.setState({
          selectedItemId: null,
          previewRentalReminderModalVisible: false,
        })}
      />
    )
  }

  render() {
    const dataSource = this.getFilteredResults(Object.values(this.state.itemsById));
    const selectedItem = this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
    return (
      <div>
        <FilterForm
          showPropertyName={ !this.props.ownershipId }
          filteredInfo={ this.state.filteredInfo }
          loading={ this.state.isFetching }
          onSubmit={ this.handleFilterFormSubmit }
        />
        <Row style={{ marginTop: 14, marginBottom: 14 }}>
          <Col span={ 24 }>
            <Button
              type="primary" icon="plus"
              onClick={ () => this.setState({ createModalVisible: true, selectedItemId: null })}
            >
              { this.props.t("AccountEntries.actions.AddAccountEntry") }
            </Button>
            <Button
              icon="download"
              style={{ marginLeft: 8 }}
              onClick={ () => this.setState({ downloadAccountEntriesModalVisible: true })}
            >
              { this.props.t("actions.DownloadResults") }
            </Button>
          </Col>
        </Row>
        <CreateForm
          wrappedComponentRef={ this.saveCreateFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false })}
          onOk={ this.onCreateSubmit }
          ownershipId={ this.props.ownershipId }
          confirmLoading={ this.state.isCreating }
        />
        <AddPaymentForm
          wrappedComponentRef={ this.saveAddPaymentFormRef }
          visible={ !!selectedItem && this.state.addPaymentModalVisible }
          accountEntry={ selectedItem }
          onCancel={ () => this.setState({ selectedItemId: null, addPaymentModalVisible: false }) }
          onOk={ this.onAddPaymentSubmit }
          confirmLoading={ selectedItem && selectedItem.isUpdating }
        />
        <AttachDocumentModal
          title={ selectedItem && `${this.props.AccountEntryTypeOptions[selectedItem.entry_type].text} - ${DateUtils.formatDate(selectedItem.due_date)}` }
          ownershipId={ selectedItem && selectedItem.ownership_id }
          item={ selectedItem }
          visible={ !!selectedItem && this.state.attachDocumentModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, attachDocumentModalVisible: false }) }
          onAttachDocumentSubmit={ (documentId) => this.attachDocumentToAccountEntry(documentId) }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <DownloadAccountEntriesModal
          visible={ this.state.downloadAccountEntriesModalVisible }
          title={ this.props.t("actions.DownloadResults") }
          onCancel={ () => this.setState({ downloadAccountEntriesModalVisible: false }) }
          onOk={ (values) => {
            const downloadQs = qs.stringify({
              ...this.getFilterParams(),
              fieldsToExport: Object.values(values).flat(),
            }, { arrayFormat: 'brackets' });
            const downloadAllUrl = `/account_entries.xlsx?${downloadQs}`;
            window.open(downloadAllUrl, '_blank')
            this.setState({ downloadAccountEntriesModalVisible: false })
          }}
          confirmLoading={ false }
        />
        <PreviewInvoiceModal
          item={ selectedItem }
          visible={ !!selectedItem && this.state.previewInvoiceModalVisible }
          onCancel={ () => this.setState({
            selectedItemId: null,
            previewInvoiceModalVisible: false,
          })}
          onSaveNewVersion={ this.onSaveNewInvoiceVersion }
        />
        { this.renderRentalReminderModal(selectedItem) }
        <PreviewReceiptModal
          item={ selectedItem }
          visible={ !!selectedItem && this.state.previewReceiptModalVisible }
          onCancel={ () => this.setState({
            selectedItemId: null,
            previewReceiptModalVisible: false,
          })}
          onSaveNewVersion={ this.onSaveNewReceiptVersion }
        />
        <MarkAsVoidForm
          wrappedComponentRef={ this.saveMarkAsVoidFormRef }
          accountEntry={ selectedItem }
          visible={ !!selectedItem && this.state.markAsVoidModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, markAsVoidModalVisible: false }) }
          onOk={ this.onMarkAsVoidSubmit }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <AccountEntriesTable
          showPropertyName={ !this.props.ownershipId }
          filteredInfo={ this.state.filteredInfo }
          dataSource={ dataSource }
          loading={ this.state.isFetching }
          pagination={ this.state.pagination }
          sorter={ this.state.sorter }
          onChange={ this.handleListChange }
          onExpand={ this.onExpandTableRow }
          onItemAction={ (record, actionKey) => this.onItemAction(record, actionKey) }
          expandedRowRender={
            record => (
              <AccountEntryShow
                accountEntry={ record }
                showPropertyName={ !this.props.ownershipId }
                destroyAccountPayment={ (accountPaymentId) => this.onDestroyAccountPayment(accountPaymentId) }
                updateItemState={ (item) => this.updateItemState(record.id, item) }
              />
            )
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAccountEntries: (params = {}) => {
      return dispatch(AccountEntryActions.fetchAccountEntries(params))
    },
    fetchAccountEntry: (aeId, ownershipId) => {
      return dispatch(AccountEntryActions.fetchAccountEntry(aeId, ownershipId));
    },
    createAccountEntry: (ownershipId, params = {}) => {
      const { amount, direction, due_date, account_period, ...rest } = params;
      return dispatch(AccountEntryActions.createAccountEntry(ownershipId, {
        amount_in_cents: Math.round(parseInt(direction) * amount * 100),
        due_date: due_date ? due_date.format('YYYY-MM-DD') : null,
        period_start_date: account_period && account_period[0] && account_period[0].format('YYYY-MM-DD'),
        period_end_date: account_period && account_period[1] && account_period[1].format('YYYY-MM-DD'),
        ...rest,
      }));
    },
    markAsVoidAccountEntry: (aeId, ownershipId, params) => {
      return dispatch(AccountEntryActions.markAsVoid(aeId, ownershipId, params));
    },
    sendAccountEntryInvoice: (aeId) => {
      return dispatch(AccountEntryActions.sendAccountEntryInvoice(aeId));
    },
    sendAccountEntryPaymentReminder: (aeId) => {
      return dispatch(AccountEntryActions.sendAccountEntryPaymentReminder(aeId));
    },
    sendAccountEntryReceipt: (aeId) => {
      return dispatch(AccountEntryActions.sendAccountEntryReceipt(aeId));
    },
    attachDocumentToAccountEntry: (aeId, ownershipId, documentId) => {
      return dispatch(AccountEntryActions.attachDocumentToAccountEntry(aeId, ownershipId, documentId));
    },
    addPaymentToAccountEntry: (aeId, ownershipId, params = {}) => {
      const { payment_amount, payment_date, ...rest } = params;
      return dispatch(AccountEntryActions.addPaymentToAccountEntry(aeId, ownershipId, {
        amount_in_cents: Math.round(payment_amount * 100),
        payment_date: payment_date ? payment_date.format('YYYY-MM-DD') : null,
        ...rest,
      }));
    },
    destroyAccountPayment: (accountPaymentId) => {
      return dispatch(AccountEntryActions.destroyAccountPayment(accountPaymentId));
    },
    saveNewInvoiceToAccountEntry: (aeId, ownershipId) => {
      return dispatch(AccountEntryActions.saveNewInvoiceToAccountEntry(aeId, ownershipId));
    },
    saveNewReceiptToAccountEntry: (aeId, ownershipId) => {
      return dispatch(AccountEntryActions.saveNewReceiptToAccountEntry(aeId, ownershipId));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AccountEntries));
