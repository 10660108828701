import { combineReducers } from 'redux';
import accountEntriesReducer from './accountEntries';
import documentsReducer from './documents';
import listingsReducer from './listings';
import constantsReducer from './constants';
import organizationReducer from './organization';
import ownershipsReducer from './ownerships';
import ownersReducer from './owners';
import portfolioReducer from './portfolio';
import tasksReducer from './tasks';
import usersReducer from './users';

export default combineReducers({
  accountEntriesReducer,
  documentsReducer,
  listingsReducer,
  constantsReducer,
  organizationReducer,
  ownershipsReducer,
  ownersReducer,
  portfolioReducer,
  tasksReducer,
  usersReducer,
});
