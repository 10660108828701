import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Form,
  Input,
  Modal,
} from 'antd';

import { withTranslation } from 'react-i18next';
import numeral from 'numeral';

import {
  ThemeColors,
} from '@constants/Theme';

const RESET_STATE = (state = {}) => {
  return {
    ...state,
  }
}

class MarkAsVoidForm extends React.Component {
  static propTypes = {
    confirmLoading: PropTypes.bool,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    confirmLoading: false,
    visible: false,
    onCancel: () => {},
    onOk: () => {},
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
    }
  }

  renderForm() {
    const { accountEntry, form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          { accountEntry.ownership.name }
        </Form.Item>
        <Form.Item
          label={ this.props.t("AccountEntries.EntryType") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          { this.props.AccountEntryTypeOptions[accountEntry.entry_type].text }
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Amount") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          <p
            style={{
              color: (accountEntry.amount < 0) ? ThemeColors.red : ThemeColors.green,
            }}
          >
            { numeral(accountEntry.amount).format('($0,0)') }
          </p>
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Status") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          { this.props.t(this.props.AccountEntryStatusOptions[accountEntry.state].ttextKey) }
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Notes") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator("note", {
          })(
            <Input.TextArea rows={4} placeholder={ this.props.t("shared.Notes") } />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    if (!this.props.accountEntry) {
      return null;
    }

    return (
      <Modal
        visible={this.props.visible}
        title={ this.props.t("AccountEntries.actions.MarkAsVoid") }
        okText={ this.props.t("actions.Submit") }
        onCancel={this.props.onCancel}
        onOk={this.props.onOk}
        confirmLoading={this.props.confirmLoading}
      >
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    AccountEntryStatusOptions: state.accountEntriesReducer.AccountEntryStatusOptions,
    AccountEntryTypeOptions: state.accountEntriesReducer.AccountEntryTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

const ConnectedMarkAsVoidForm = connect(mapStateToProps, mapDispatchToProps)(MarkAsVoidForm);

const WrappedMarkAsVoidForm = Form.create({ name: 'account_entry_void_form_in_modal' })(ConnectedMarkAsVoidForm);

export default withTranslation('common')(WrappedMarkAsVoidForm);
