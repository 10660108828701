import React from 'react';

import '@components/LayoutStyle.scss';
import RepairsData from '@components/RepairsData';

export default class RepairsDataMainView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-full-view">
        <RepairsData />
      </div>
    )
  }
}
