import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  message,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as OwnersActions from '@actions/owners';
import * as OwnershipsActions from '@actions/ownerships';
import OwnersList from '@components/Owners/List';
import FilterForm from '@components/Owners/FilterForm';
import AddOwnerForm from '@components/Owners/AddOwnerForm';
import UpdateOwnerForm from '@components/Owners/UpdateForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isCreating: false,
    isFetching: false,
    itemsById: {},
    errorFetching: null,
    errorCreating: null,

    // modals
    createModalVisible: false,
    updateItemModalVisible: false,

    // search params
    filteredInfo: {
      selectedIsCompany: [],
    },

    ...state,
  };
}

class Owners extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchOwners = this.fetchOwners.bind(this);
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
    this.onCreateOwnerRelation = this.onCreateOwnerRelation.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchOwners();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.fetchOwners();
    }
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
    }, this.fetchOwners)
  }

  getSelectedItem() {
    const { selectedItemId, itemsById } = this.state;
    if (!selectedItemId) {
      return null
    }
    const selectedItem = itemsById[selectedItemId];
    return selectedItem ? selectedItem : null;
  }

  getFilterParams() {
    const { filteredInfo } = this.state;
    return {
      filter: {
        ...filteredInfo,
      },
    }
  }

  async fetchOwners(params = {}) {
    if (this.props.ownershipId) {
      params = {
        ...params,
        ownership_id: this.props.ownershipId,
      }
    }
    this.setState({ isFetching: true })
    try {
      let responseData = await this.props.fetchOwners({
        ...params,
        ...this.getFilterParams(),
      });
      this.setState({
        isFetching: false,
        errorFetching: null,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.owners),
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        errorFetching: error,
        itemsById: {},
      });
      return
    }
  }

  async onCreateOwnerRelation() {
    if (!this.props.ownershipId) {
      return
    }
    const { form } = this.addOwnerFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({ isCreating: true });
      let responseData = await this.props.addOwnerToOwnership(this.props.ownershipId, values);
      const newOwner = responseData.owner;
      this.setState({
        isCreating: false,
        createModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [newOwner.id]: newOwner,
        },
      });
      message.success(this.props.t("Owners.messages.RegisterOwner.Success"));
      return responseData;
    } catch(error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      })
      message.error(this.props.t("Owners.messages.RegisterOwner.Fail"));
      return
    }
  }

  async onUpdateSubmit() {
    const { form } = this.updateFormRef.props;
    let selectedItem = this.getSelectedItem();
    if (!selectedItem) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.updateOwner(selectedItem.id, values);
      const updatedOwner = responseData.owner;
      this.setState({
        selectedItemId: null,
        updateItemModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedOwner.id]: updatedOwner,
        },
      });
      message.success(this.props.t("Owners.messages.UpdateOwner.Success"));
      return responseData;
    } catch (error) {
      selectedItem = this.getSelectedItem();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedItem.id]: {
            ...selectedItem,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      return null;
    }
  }

  saveAddOwnerFormRef = formRef => {
    this.addOwnerFormRef = formRef;
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  renderAddOwner() {
    if (!this.props.ownershipId) {
      return
    }
    return (
      <div>
        <Button
          type="primary" icon="plus"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ createModalVisible: true })}
        >
          { this.props.t("Owners.actions.AddOwner") }
        </Button>
        <AddOwnerForm
          wrappedComponentRef={ this.saveAddOwnerFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false }) }
          onOk={ this.onCreateOwnerRelation }
          confirmLoading={ this.state.isCreating }
          ownershipOwnersIds={ Object.values(this.state.itemsById).map((item) => item.id ) }
        />
      </div>
    )
  }

  getFilteredResults(items) {
    const { filteredInfo } = this.state;
    return items.filter((owner) => {
      if (!filteredInfo) { return true };
      if (filteredInfo.selectedIsCompany && filteredInfo.selectedIsCompany.length > 0) {
        if (!filteredInfo.selectedIsCompany.includes(owner.is_company.toString())) {
          return false;
        }
      }
      return true;
    })
  }

  render() {
    const dataSource = this.getFilteredResults(Object.values(this.state.itemsById));
    const selectedItem = this.getSelectedItem();
    return (
      <div>
        {
          !this.props.ownershipId && (
            <FilterForm
              filteredInfo={ this.state.filteredInfo }
              loading={ this.state.isFetching }
              onSubmit={ this.handleFilterFormSubmit }
            />
          )
        }
        { this.renderAddOwner() }
        <UpdateOwnerForm
          wrappedComponentRef={ this.saveUpdateFormRef }
          owner={ selectedItem }
          visible={ !!selectedItem && this.state.updateItemModalVisible }
          onCancel={ () => this.setState({ selectedItemId: null, updateItemModalVisible: false }) }
          onOk={ this.onUpdateSubmit }
          confirmLoading={
            (selectedItem && selectedItem.isUpdating)
          }
        />
        <OwnersList
          loading={ this.state.isFetching }
          owners={ dataSource }
          onClickEdit={ (ownerId) => this.setState({ selectedItemId: ownerId, updateItemModalVisible: true }) }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwners: (params = {}) => {
      return dispatch(OwnersActions.fetchOwners(params))
    },
    addOwnerToOwnership: (ownershipId, params = {}) => {
      const { br_expiry_date, ...rest } = params;
      return dispatch(OwnershipsActions.addOwnerToOwnership(ownershipId, {
        ...rest,
        br_expiry_date: br_expiry_date && br_expiry_date.format('YYYY-MM-DD'),
      }));
    },
    updateOwner: (ownerId, params = {}) => {
      return dispatch(OwnersActions.updateOwner(ownerId, params));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Owners));
