import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  List,
  Modal,
} from 'antd';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';
import * as OwnershipActions from '@actions/ownerships';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingDocuments: false,
    documents: [],
    errorFetchingDocuments: null,
    ...state,
  };
}

class AttachDocumentModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    ownershipId: PropTypes.number,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onAttachDocumentSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    title: "",
    visible: false,
    confirmLoading: false,
    onAttachDocumentSubmit: (documentId) => {},
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onAttachDocumentSubmit = this.onAttachDocumentSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      if (!this.props.ownershipId) {
        return
      }
      this.setState({ isFetchingDocuments: true });
      this.props.fetchUnprocessedDocuments(this.props.ownershipId).then((responseData) => {
        this.setState({
          isFetchingDocuments: false,
          documents: responseData.documents,
        });
      }).catch((error) => {
        this.setState({
          isFetchingDocuments: false,
          errorFetchingDocuments: error,
        })
      });
    }
  }

  async onAttachDocumentSubmit(documentId) {
    try {
      let responseData = await this.props.onAttachDocumentSubmit(documentId);
      this.setState({
        documents: this.state.documents.filter((document) => document.id !== documentId),
      });
      return responseData;
    } catch(error) {
      return
    }
  }

  renderList() {
    const docList = [...this.state.documents];
    docList.sort((a, b) => {
      // Descending order
      return moment(b.created_at) - moment(a.created_at);
    });
    return (
      <List
        loading={ this.state.isFetchingDocuments || this.props.item.isUpdating }
        itemLayout="horizontal"
        locale={{
          emptyText: this.props.t("Documents.messages.Form.NoDocumentsToAttach")
        }}
        dataSource={ docList }
        renderItem={item => (
          <List.Item
            actions={[
              <a onClick={ () => this.onAttachDocumentSubmit(item.id) }>
                { this.props.t("Documents.actions.AttachDocument") }
              </a>
            ]}
          >
            <List.Item.Meta
              avatar={
                item.preview_url && <img style={{ height: '100px' }} src={ item.preview_url } />
              }
              title={ item.doc_type_display_name }
              description={ item.description }
            />
            <div>{ DateUtils.formatDate(item.date) }</div>
          </List.Item>
        )}
      />
    )
  }

  render() {
    if (!this.props.ownershipId) {
      return null;
    }

    return (
      <Modal
        width="70%"
        visible={ this.props.visible }
        title={ `${ this.props.t("Documents.actions.AttachDocument")}: ${this.props.title}` }
        okText={ this.props.t("Documents.actions.AttachDocument")}
        onCancel={ this.props.onCancel }
        confirmLoading={ this.props.confirmLoading }
        footer={ null }
      >
        { this.renderList() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchUnprocessedDocuments: (ownershipId) => {
      return (dispatch(OwnershipActions.fetchUnprocessedDocuments(ownershipId)));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AttachDocumentModal));
