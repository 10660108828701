import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchTenancies = (params = {}) => {
  return Api.get(
    'tenancies',
    ActionTypes.FETCH_TENANCIES,
    params,
  )
}

export const createTenancy = (ownershipId, params = {}) => {
  return Api.post(
    'tenancies',
    ActionTypes.CREATE_TENANCY,
    {
      ownership_id: ownershipId,
      ...params,
    },{
      ownershipId,
    }
  )
}

export const updateTenancy = (tenancyId, ownershipId, params = {}) => {
  return Api.put(
    `tenancies/${tenancyId}`,
    ActionTypes.UPDATE_TENANCY,
    {
      ...params,
    },{
      ownershipId,
    }
  )
}

export const updateTenancyTerminateEarly = (tenancyId, ownershipId, params = {}) => {
  return Api.post(
    `tenancies/${tenancyId}/terminate_tenancy`,
    ActionTypes.UPDATE_TENANCY,
    {
      ...params,
    },{
      ownershipId,
    }
  )
}

export const attachDocumentToTenancy = (tenancyId, documentId) => {
  return Api.post(
    `tenancies/${tenancyId}/attach_document`,
    ActionTypes.TENANCY_ATTACH_DOCUMENT,
    {
      document_id: documentId,
    },
    {
      tenancyId,
    }
  );
}
