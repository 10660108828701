import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';
import {
  Button,
  Card,
  Descriptions,
  Tag,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as DateUtils from '@utils/date';

class OwnerCard extends React.Component {
  static propTypes = {
    owner: PropTypes.object,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { owner } = this.props;
    const descriptions = [{
      label: this.props.t("Owners.CorrespondenceAddress"),
      shouldRender: true,
      render: () => (
        <span style={{ 'whiteSpace': 'pre-line' }}>
          { owner.correspondence_address }
        </span>
      ),
    }, {
      label: this.props.t("shared.ContactDetails"),
      shouldRender: true,
      render: () => (
        <span style={{ 'whiteSpace': 'pre-line' }}>
          { owner.contact_details }
        </span>
      ),
    }, {
      label: this.props.t("Owners.IDNumber"),
      shouldRender: !owner.is_company,
      render: () => (
        owner.id_card_number
      ),
    }, {
      label: this.props.t("Owners.BusinessRegistration"),
      shouldRender: owner.is_company,
      render: () => {
        const expiration = owner.br_expiry_date ? (<div><strong>{ this.props.t("Owners.BRExpiryDate") }:</strong> { DateUtils.formatDate(owner.br_expiry_date) }</div>) : null;
        const cert_num = owner.br_cert_number ? (<div><strong>{ this.props.t("Owners.BRNumber") }:</strong> { owner.br_cert_number }</div>): null;
        const cert_url = owner.br_cert_url ? (<a href={ owner.br_cert_url } target="_blank">{ this.props.t("actions.View") }</a>) : null
        return (
          <div>
            { expiration }
            { cert_num }
            { cert_url }
          </div>
        )
      },
    }, {
      label: this.props.t("Properties.Title"),
      shouldRender: true,
      render: () => (
        <ul>
          {
            owner.ownerships.map((ownership) => (
              <li style={{ marginBottom: 10 }} key={ ownership.id.toString() }>
                <Link
                  key={ ownership.id }
                  to={ `/properties/${ownership.id}` }
                >
                  { ownership.name }
                  <br></br>
                  { ownership.address }
                </Link>
              </li>
            ))
          }
        </ul>
      ),
    }]
    return (
      <Card
        type="inner"
        style={{ marginBottom: "20px" }}
        title={
          <div>
            <Tag
              style={{ marginRight: "10px" }}
              color={ owner.is_company ? 'blue' : 'pink' }
            >
              { owner.is_company ? this.props.t("Owners.Company") : this.props.t("Owners.Individual") }
            </Tag>
            { owner.name }
          </div>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            icon="edit"
            onClick={ () => { this.props.onClickEdit(owner.id) } }
          >
            { this.props.t("Owners.actions.UpdateOwner") }
          </Button>
        </div>
        <Descriptions
          title=""
          layout="vertical"
          bordered
          size="small"
        >
          {
            descriptions.filter(({ shouldRender }) => shouldRender).map((description, index) => (
              <Descriptions.Item
                label={ <strong>{ description.label }</strong> }
                key={ index.toString() }
              >
                { description.render() }
              </Descriptions.Item>
            ))
          }
        </Descriptions>
      </Card>
    )
  }
}

export default withTranslation('common')(OwnerCard);
