import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Alert,
  Button,
  Icon,
  Row,
  Spin,
  Tabs,
  Timeline,
} from 'antd';

import Highlighter from 'react-highlight-words';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import * as OwnershipUtils from '@utils/ownership';
import * as OwnershipActions from '@actions/ownerships';
import OwnershipSettings from '@components/Ownerships/Settings';
import Documents from '@components/Documents';
import AccountEntries from '@components/AccountEntries';
import Owners from '@components/Owners';
import OwnershipManagers from '@components/OwnershipManagers'
import Tasks from '@components/Tasks';
import Tenancies from '@components/Tenancies';

const MAX_ALERTS_HEIGHT = 40 * 3;

class OwnershipDetail extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.props.fetchOwnership(props.ownershipId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ownershipId !== this.props.ownershipId) {
      this.props.fetchOwnership(this.props.ownershipId);
    }
  }

  renderActivityLog(logs) {
    if (!logs || logs.length === 0) {
      return (
        <Row type="flex" justify="center">
          No recent activity
        </Row>
      )
    }

    return (
      <Timeline>
        {
          logs.map((log) => {
            const user = (<strong style={{ paddingRight: '5px' }}>{ log.user_display_name }</strong>);
            let color = "blue";
            if (log.note.toLowerCase().includes("added") || log.note.toLowerCase().includes("created")) {
              color = "green";
            } else if (log.note.toLowerCase().includes("removed") || log.note.toLowerCase().includes("deleted")) {
              color = "red";
            }
            return (
              <Timeline.Item
                key={ `ActivityLog_${log.id}` }
                color={ color }
              >
                { user } { log.note }.
                <br />
                { moment(log.created_at).fromNow() }
              </Timeline.Item>
            )
          })
        }
      </Timeline>
    )
  }

  renderTabs(ownership) {
    const tabsData = [
      {
        key: "accounts",
        renderTabElement: () => (<span><Icon type="dollar" />{ this.props.t("OwnershipDetailSections.Accounts") }</span>),
        renderTabDetail: (ownership) => (<AccountEntries ownershipId={ ownership.id }/>),
      },
      {
        key: "tenancies",
        renderTabElement: () => (<span><Icon type="key" />{ this.props.t("Tenancies.Title") }</span>),
        renderTabDetail: (ownership) => (<Tenancies ownershipId={ ownership.id } />),
      },
      {
        key: "documents",
        renderTabElement: () => (<span><Icon type="file" />{ this.props.t("Documents.Title") }</span>),
        renderTabDetail: (ownership) => (<Documents ownershipId={ ownership.id } />),
      },
      {
        key: "tasks",
        renderTabElement: () => (<span><Icon type="file-done" />{ this.props.t("Tasks.Title") }</span>),
        renderTabDetail: (ownership) => (<Tasks ownershipId={ ownership.id } />),
      },
      {
        key: "owners",
        renderTabElement: () => (<span><Icon type="smile" />{ this.props.t("Owners.Title") }</span>),
        renderTabDetail: (ownership) => (<Owners ownershipId={ ownership.id } />),
      },
      {
        key: "team",
        renderTabElement: () => (<span><Icon type="team" />{ this.props.t("OwnershipDetailSections.Team.Title") }</span>),
        renderTabDetail: (ownership) => (<OwnershipManagers ownershipId={ ownership.id } />),
      },
      {
        key: "settings",
        renderTabElement: () => (<span><Icon type="setting" />{ this.props.t("OwnershipDetailSections.Settings.Title") }</span>),
        renderTabDetail: (ownership) => (<OwnershipSettings ownership={ ownership } />),
      },
      {
        key: "activity_log",
        renderTabElement: () => (<span><Icon type="interaction" />{ this.props.t("OwnershipDetailSections.Activity") }</span>),
        renderTabDetail: (ownership) => this.renderActivityLog(ownership.activity_logs)
      },
    ]


    const detailHeight = 194 + Math.min(40 * ownership.alerts.length, MAX_ALERTS_HEIGHT);
    const style = {
      overflowY: "auto",
      padding: 4,
      height: `calc(100vh - ${detailHeight}px)`
    }

    return (
      <Tabs defaultActiveKey="accounts" size='large' style={{ marginTop: 0, marginBottom: 14 }}>
        {
          tabsData.map(({ key, renderTabElement, renderTabDetail }) => {
            return (
              <Tabs.TabPane
                key={key}
                tab={renderTabElement()}
                >
                <div style={style}>
                  { renderTabDetail(ownership) }
                </div>
              </Tabs.TabPane>
            )
          })
        }
      </Tabs>
    )
  }

  render() {
    const ownership = this.props.ownershipsData[this.props.ownershipId];
    if (OwnershipUtils.isNotDefined(ownership)) {
      return null;
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'baseline' }}>
          <h1 style={{ paddingRight: '10px' }}>{ ownership.name }</h1>
          <h3>{ ownership.address }</h3>
            <Button
              style={{ right: 0, position: 'absolute' }}
              shape="circle"
              icon="close"
              onClick={ this.props.onClose }
            />
        </div>
        <Row style={{ maxHeight: MAX_ALERTS_HEIGHT, overflowY: 'scroll' }}>
          {
            ownership.alerts.map((alert, index) => {
              const type = alert.type;
              return (
                <Alert
                  key={ index.toString() }
                  type={ type }
                  message={ alert.message }
                  showIcon
                  banner
                />
              )
            })
          }
        </Row>
      { ownership.isFetching ? (<Spin size="large" />) : null }
      {
        ownership.fetchOwnershipError ?
          (
            <Alert
              type="error"
              message={ "Unable to fetch property data" }
              description={
                <p>
                  There was an issue retrieving your property data.
                  <a onClick={ () => this.props.fetchOwnership(ownership.id) }>
                    Try again.
                  </a>
                </p>
              }
              showIcon
            />
        ) : null
      }
      { this.renderTabs(ownership) }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ownershipsData: state.ownershipsReducer.ownerships,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOwnership: (ownershipId) => {
      return dispatch(OwnershipActions.fetchOwnership(ownershipId)).catch((error) => {});
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OwnershipDetail));
