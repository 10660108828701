import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/browser';

import Mixpanel from '@utils/mixpanel';
import * as ArrayUtils from '@utils/array';

import appReducer from '@reducers'
import { initialState as accountEntriesInitialState } from '@reducers/accountEntries';
import { initialState as documentsInitialState } from '@reducers/documents';
import { initialState as listingsInitialState } from '@reducers/listings';
import { initialState as constantsInitialState } from '@reducers/constants';
import { initialState as organizationInitialState } from '@reducers/organization';
import BaseLayout from '@views'

import {
  I18nextProvider,
  withTranslation,
} from "react-i18next";
import i18next from "i18next";
import { ConfigProvider } from "antd";

import common_zh from "@translations/zh/common.json";
import common_en from "@translations/en/common.json";

import en_US from "antd/es/locale/en_US";
import zh_TW from "antd/es/locale/zh_TW";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    zh: {
      common: common_zh
    },
  },
});

const localeMap = {
  en: en_US,
  zh: zh_TW,
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://08c4d47a23734f029e03716b24e50cde@o130536.ingest.sentry.io/1550379",
  });
}

class AntdApp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const locale = localeMap[this.props.i18n.language] || en_US;
    return (
      <ConfigProvider locale={ locale }>
        <Router>
          <BaseLayout />
        </Router>
      </ConfigProvider>
    )
  }
}

const AntdAppWrapper = withTranslation('common')(AntdApp);

class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={ store }>
        <I18nextProvider i18n={i18next}>
          <AntdAppWrapper />
        </I18nextProvider>
      </Provider>
    )
  }
}

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeCreator = (initialState = {}) => {
  return createStore(
    appReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware,
      ),
    )
  );
}
/* eslint-enable */

document.addEventListener('DOMContentLoaded', () => {
  const serverData = JSON.parse(document.getElementById('server_data').getAttribute('data'));
  const usersReducerData = serverData['auth_data'];
  const me = usersReducerData.me;

  const initReducerState = {
    usersReducer: {
      ...usersReducerData,
    },
    accountEntriesReducer: {
      ...accountEntriesInitialState,
      AccountEntryTypeOptions: serverData['AccountEntryTypeOptions'] || {},
      AccountEntryStatusOptions: serverData['AccountEntryStatusOptions'] || {},
    },
    documentsReducer: {
      ...documentsInitialState,
      DocumentTypeOptions: ArrayUtils.arrayToObject(serverData['DocumentTypeOptions'], (obj) => { return obj.value }) || {},
    },
    listingsReducer: {
      ...listingsInitialState,
      ListingEventTypeOptions: serverData['ListingEventTypeOptions'] || {},
    },
    constantsReducer: {
      ...constantsInitialState,
      neighbourhoodsById: {
        ...ArrayUtils.arrayToObject(serverData['NeighbourhoodOptions'] || []),
      },
      repairsDataTagsById: {
        ...ArrayUtils.arrayToObject(serverData['RepairsDataTagOptions'] || []),
      },
    },
    organizationReducer: {
      ...organizationInitialState,
      ...(serverData['OrganizationDetails'] || { id: 1, name: "MyPropty" }),
    },
  }

  if (usersReducerData.is_authenticated) {
    Mixpanel.identify(me.id);
    Mixpanel.people.set({
      $email: me.email,
      $first_name: me.first_name,
      $last_name: me.last_name,
    });
  }

  if (me.language_preference !== "en") {
    i18next.changeLanguage(me.language_preference);
  }

  ReactDOM.render(
    <App store={ storeCreator(initReducerState) }/>,
    document.body.appendChild(document.createElement('div')),
  )
})
