import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Modal,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as PortfolioActions from '@actions/portfolio';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingTeamMembers: false,
    teamMembersById: {},
    errorFetchingTeamMembers: null,
    ...state,
  };
}

class InviteForm extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number.isRequired,
    ownershipOwnershipManagerIds: PropTypes.arrayOf(PropTypes.number),
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
    ownershipOwnershipManagerIds: [],
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingTeamMembers: true });
      this.props.fetchPortfolioTeamMembers().then((responseData) => {
        this.setState({
          isFetchingTeamMembers: false,
          teamMembersById: {
            ...ArrayUtils.arrayToObject(responseData.users),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingTeamMembers: false,
          errorFetchingTeamMembers: error,
        });
      });
    }
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { ownershipId }  = this.props;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("OwnershipDetailSections.Team.User") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("user_id", {
              rules: [
                { required: true, message: `${ this.props.t("OwnershipDetailSections.Team.messages.Form.PleaseSelectUser") }!` },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("OwnershipDetailSections.Team.messages.Form.PleaseSelectUser") }
                loading={ this.state.isFetchingTeamMembers }
                notFoundContent={
                  this.state.isFetchingTeamMembers ? <Spin size="small" /> : null
                }
              >
                {
                  Object.values(this.state.teamMembersById).map((teamMember) => (
                    <Select.Option
                      key={ teamMember.id.toString() }
                      disabled={ this.props.ownershipOwnershipManagerIds.includes(teamMember.id) }
                    >
                      { teamMember.display_name }. { teamMember.email }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("OwnershipDetailSections.Team.Role") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator('role', {
            rules: [
              { required: false, message: this.props.t("OwnershipDetailSections.Team.messages.Form.PleaseInputUserRole") },
            ],
          })(
            <Input />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { visible, confirmLoading, onOk, onCancel } = this.props;

    return (
      <Modal
        visible={visible}
        title={ this.props.t("OwnershipDetailSections.Team.actions.InviteUser") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ onCancel }
        onOk={ onOk }
        confirmLoading={confirmLoading}
      >
        { this.renderForm() }
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchPortfolioTeamMembers: (params) => {
      return (dispatch(PortfolioActions.fetchPortfolioTeamMembers(params)));
    },
  };
}

const ConnectedInviteForm = connect(mapStateToProps, mapDispatchToProps)(InviteForm);

const WrappedInviteForm = Form.create({
  name: "tasks_creation_form_in_modal"
})(ConnectedInviteForm);

export default withTranslation('common')(WrappedInviteForm);
