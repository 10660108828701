import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchDocuments = (params = {}) => {
  return Api.get(
    'documents',
    ActionTypes.FETCH_DOCUMENTS,
    params,
  );
}

export const createDocument = (ownershipId, params = {}) => {
  return Api.postUpload(
    `documents`,
    ActionTypes.CREATE_DOCUMENT,
    {
      ownership_id: ownershipId,
      ...params,
    },
    {
      ownershipId,
    },
  )
}

export const updateDocument = (documentId, params = {}) => {
  return Api.put(
    `documents/${documentId}`,
    ActionTypes.UPDATE_DOCUMENT,
    params,
    {},
  )
}

export const destroyDocument = (documentId, ownershipId) => {
  return Api.del(
    `documents/${documentId}`,
    ActionTypes.DESTROY_DOCUMENT,
    {},
    {
      documentId,
      ownershipId,
    }
  )
}
