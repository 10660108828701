import _ from 'underscore';

export const arrayToObject = (array, keyAccess = (val) => { return val.id }) => {
  return array.reduce((obj, item) => {
    obj[keyAccess(item)] = item;
    return obj;
  }, {});
};


export const arraysEqual = (arr1, arr2) => {
  return _.isEqual(arr1, arr2);
}
