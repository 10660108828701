import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import {
  Checkbox,
  Divider,
  Modal,
} from 'antd';

const checkedOptions = {
  owners: [
    { value: 'owner_name', ttextKey: 'Owners.OwnerName' }
  ],
  tenancy: [
    { value: 'tenancy_tenant_name', ttextKey: 'Tenancies.TenantName' },
    { value: 'tenancy_contract_period', ttextKey: 'Tenancies.TenancyPeriod' },
    { value: 'tenancy_security_deposit', ttextKey: 'shared.SecurityDeposit' },
    { value: 'tenancy_terms', ttextKey: 'Tenancies.TenancyTerms.Title' },
    { value: 'tenancy_notes', ttextKey: 'shared.Notes' },
  ],
  accounts: [
    { value: 'account_entry_invoice_number', ttextKey: 'AccountEntries.DebitNoteNumber' },
    { value: 'account_entry_line_items', ttextKey: 'AccountEntries.LineItems' },
    { value: 'account_payments', ttextKey: 'AccountEntries.CompletedPayments' },
  ],
  ownership: [
    { value: 'bank_details', ttextKey: 'AccountEntries.BankDetails' },
    { value: 'property_name', ttextKey: 'Properties.PropertyName' },
    { value: 'property_address', ttextKey: 'Properties.PropertyAddress' },
  ],
};

class DownloadEntriesModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    title: "",
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedLists: {
        owners: [],
        tenancy: [],
        accounts: [],
        ownership: [],
      },
    }
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onOk = this.onOk.bind(this);
  }

  onChangeCheckbox(listName, checkedList) {
    this.setState({
      checkedLists: {
        ...this.state.checkedLists,
        [listName]: checkedList,
      },
    });
  };

  onOk() {
    this.props.onOk(this.state.checkedLists)
  }

  render() {
    const translateOptionLabel = (item) => { return { value: item.value, label: this.props.t(item.ttextKey) } };
    return (
      <Modal
        visible={ this.props.visible }
        title={ this.props.title }
        onCancel={ this.props.onCancel }
        onOk={ this.onOk }
        confirmLoading={ this.props.confirmLoading }
      >
        <Divider orientation="left">{ this.props.t("AccountEntries.DownloadResultsModal.OwnerParticulars") }</Divider>
        <Checkbox.Group
          options={ checkedOptions.owners.map(translateOptionLabel) }
          value={ this.state.checkedLists.owners }
          onChange={ (checkedList) => this.onChangeCheckbox('owners', checkedList) }
        />
        <Divider orientation="left">{ this.props.t("AccountEntries.DownloadResultsModal.TenancyDetailsIfApplicable") }</Divider>
        <Checkbox.Group
          options={ checkedOptions.tenancy.map(translateOptionLabel) }
          value={ this.state.checkedLists.tenancy }
          onChange={ (checkedList) => this.onChangeCheckbox('tenancy', checkedList) }
        />
        <Divider orientation="left">{ this.props.t("AccountEntries.DownloadResultsModal.Accounts") }</Divider>
        <Checkbox.Group
          options={ checkedOptions.accounts.map(translateOptionLabel) }
          value={ this.state.checkedLists.accounts }
          onChange={ (checkedList) => this.onChangeCheckbox('accounts', checkedList) }
        />
        <Divider orientation="left">{ this.props.t("AccountEntries.DownloadResultsModal.PropertyDetails") }</Divider>
        <Checkbox.Group
          options={ checkedOptions.ownership.map(translateOptionLabel) }
          value={ this.state.checkedLists.ownership }
          onChange={ (checkedList) => this.onChangeCheckbox('ownership', checkedList) }
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(DownloadEntriesModal));
