import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '@components/LayoutStyle.scss';
import Tasks from '@components/Tasks';

export default class TasksMainView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-full-view">
        <Tasks />
      </div>
    )
  }
}
