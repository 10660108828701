import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Form,
  Icon,
  Input,
  Modal,
  Switch,
} from 'antd';

import { withTranslation } from 'react-i18next';

import QuillEditor from '@components/QuillEditor'

class UpdateSettingsForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    ownership: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.form.setFieldsValue({
      invoice_remarks: value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      return
    }
  }

  render() {
    const { visible, onOk, onCancel, confirmLoading, form, ownership } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        visible={ visible }
        width="70%"
        title={ this.props.t("OwnershipDetailSections.Settings.actions.UpdateSettings") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ onCancel }
        onOk={ onOk }
        confirmLoading={ confirmLoading }
      >
        { this.props.children }
        <Form layout="vertical">
          <Form.Item
            hasFeedback
            label={ this.props.t("Properties.PropertyName")}
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('name', {
              initialValue: ownership.name,
              rules: [
                { required: true, message: 'Please input a property name' },
              ],
            })(
              <Input />
            )}
          </Form.Item>
          { false && (<div>
          <Form.Item
            label="Send Invoices"
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('send_invoices', {
              valuePropName: 'checked',
              initialValue: ownership.send_invoices || false,
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Send Payment Reminders"
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('send_payment_reminders', {
              valuePropName: 'checked',
              initialValue: ownership.send_payment_reminders || false,
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Send Receipts"
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('send_receipts', {
              valuePropName: 'checked',
              initialValue: ownership.send_receipts || false,
            })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </Form.Item>
          </div>)}
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.BankName") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('bank_name', {
              initialValue: ownership.bank_name || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.BankAddress") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('bank_address', {
              initialValue: ownership.bank_address || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.BankSwift") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('bank_swift_code', {
              initialValue: ownership.bank_swift_code || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.BankAccountName") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('bank_account_name', {
              initialValue: ownership.bank_account_name || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.BankAccountNumber") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('bank_account_number', {
              initialValue: ownership.bank_account_number || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.IncludeSignature")}
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('include_signature_for_autogenerated', {
              initialValue: ownership.include_signature_for_autogenerated,
            })(
              <Checkbox.Group
                options={[
                  { label: this.props.t("OwnershipDetailSections.Settings.IncludeSignatureDebitNote"), value: 'Invoice' },
                  { label: this.props.t("OwnershipDetailSections.Settings.IncludeSignatureReceipt"), value: 'Receipt' },
                ]}
              />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.DebitNoteFormat") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('invoice_number_generator', {
              initialValue: ownership.invoice_number_generator || "",
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item
            label={ this.props.t("OwnershipDetailSections.Settings.DebitNoteRemarks") }
            style={{ margin: 0, paddingBottom: 14 }}
            {...formItemLayout}
          >
            {getFieldDecorator('invoice_remarks', {
              initialValue: ownership.invoice_remarks || "",
            })(
              <Input.TextArea style={{ display: 'none' }} />
            )}
            <QuillEditor
              value={ this.props.form.getFieldValue('invoice_remarks') }
              onChange={ this.handleChange }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedUpdateSettingsForm = Form.create({ name: 'ownership_settings_update_form_in_modal' })(UpdateSettingsForm);

export default withTranslation('common')(WrappedUpdateSettingsForm);
