import * as ArrayUtils from '@utils/array';
import * as ActionTypes from '@actions/types';

export const initialState = {
  isFetchingAccountEntries: false,
  accountEntries: {},
  errorFetchingAccountEntries: null,
  AccountEntryTypeOptions: {},
}

export default (state = initialState, action) => {
  let accountEntry, accountEntries;
  switch (action.type) {
    case `${ActionTypes.FETCH_ACCOUNT_ENTRIES}_${ActionTypes.CALL_API_REQUEST}`:
      return {
        ...state,
        isFetchingAccountEntries: true,
        errorFetchingAccountEntries: null,
      };
    case `${ActionTypes.FETCH_ACCOUNT_ENTRIES}_${ActionTypes.CALL_API_SUCCESS}`:
    case `${ActionTypes.DESTROY_ACCOUNT_PAYMENT}_${ActionTypes.CALL_API_SUCCESS}`:
      return {
        ...state,
        isFetchingAccountEntries: false,
        accountEntries: {
          ...state.accountEntries,
          ...ArrayUtils.arrayToObject(action.responseData.account_entries),
        },
        errorFetchingAccountEntries: null,
      };
    case `${ActionTypes.FETCH_ACCOUNT_ENTRIES}_${ActionTypes.CALL_API_ERROR}`:
      return {
        ...state,
        isFetchingAccountEntries: false,
        errorFetchingAccountEntries: action.error,
      };
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_REQUEST}`:
      accountEntry = state.accountEntries[action.metaData.accountEntryId];
      if (!accountEntry) { return state; }
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId]: {
            ...accountEntry,
            isFetching: true,
          },
        },
      }
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_SUCCESS}`:
      accountEntry = { ...action.responseData.account_entry };
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId] : {
            ...accountEntry,
          },
        },
      };
    case `${ActionTypes.FETCH_ACCOUNT_ENTRY}_${ActionTypes.CALL_API_ERROR}`:
      accountEntry = state.accountEntries[action.metaData.accountEntryId];
      if (!accountEntry) { return state; }
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId]: {
            ...accountEntry,
            isFetching: false,
            error: action.error,
          },
        },
      };
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_REQUEST}`:
      accountEntry = state.accountEntries[action.metaData.accountEntryId];
      if (!accountEntry) { return state; }
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId]: {
            ...accountEntry,
            isUpdating: true,
          },
        },
      }
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_SUCCESS}`:
      accountEntry = { ...action.responseData.account_entry };
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId] : {
            ...accountEntry,
          },
        },
      };
    case `${ActionTypes.ACCOUNT_ENTRY_MARK_AS_VOID}_${ActionTypes.CALL_API_ERROR}`:
      accountEntry = state.accountEntries[action.metaData.accountEntryId];
      if (!accountEntry) { return state; }
      return {
        ...state,
        accountEntries: {
          ...state.accountEntries,
          [action.metaData.accountEntryId]: {
            ...accountEntry,
            isUpdating: false,
            updateError: action.error,
          },
        },
      };
    default:
      return state;
  }
}
