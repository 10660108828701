import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Col,
  Descriptions,
  Row,
  Spin,
} from 'antd';
import { withTranslation } from 'react-i18next';
import dompurify from 'dompurify';

import * as DateUtils from '@utils/date';

class TaskShow extends React.Component {
  static propTypes = {
    task: PropTypes.object,
    deleteTaskAttachment: PropTypes.func,
  }

  static defaultProps = {
    deleteTaskAttachment: (attachmentId) => {},
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { task } = this.props;
    return (
      <div>
        {
          task.isUpdating && (
            <Spin size="large"></Spin>
          )
        }
        <div style={{ marginBottom: '30px', backgroundColor: 'white', padding: '20px' }}>
          <Descriptions
            title={ this.props.t("Tasks.TaskDetails") }
            bordered
          >
            <Descriptions.Item label={ <strong>{ this.props.t("Tasks.CreatedOn") }</strong> }>
              { DateUtils.formatDate(task.created_at) }
            </Descriptions.Item>
            <Descriptions.Item label={ <strong>{ this.props.t("shared.Description") }</strong> }>
              <div
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(task.description),
                }}
              />
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
          {
            task.files.map((file, index) => (
              <div key={ index.toString()} style={{ display: 'inline-block', textAlign: 'center', padding: 14 }}>
                <a
                  target="_blank"
                  href={ file.attachment_url }
                >
                  <img src={ file.attachment_preview_url } alt="" style={{ height: '200px', width: 'auto' }}/>
                </a>
                <br></br>
                {
                  (task.status !== "completed") && (
                    <a
                      onClick={ () => this.props.deleteTaskAttachment(file.attachment_id) }
                    >
                      { this.props.t("actions.Delete") }
                    </a>
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TaskShow));
