import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const updateUserProfile = (values = {}) => {
  return Api.postUpload(
    'users/update_profile',
    ActionTypes.UPDATE_USER_PROFILE,
    values,
  )
}

export const updateUserPassword = (values = {}) => {
  return Api.post(
    'users/update_password',
    ActionTypes.UPDATE_USER_PASSWORD,
    values,
  )
}
