export const OwnershipStatusOptions = {
  "error": {
    value: "error",
    ttextKey: "Properties.StatusOptions.error",
    color: "red",
  },
  "warning": {
    value: "warning",
    ttextKey: "Properties.StatusOptions.warning",
    color: "orange",
  },
  "ok": {
    value:" ok",
    ttextKey: "Properties.StatusOptions.ok",
    color: "green",
  },
}

export const OwnershipUsageOptions = {
  "residential": {
    value: "residential",
    text: "Residential",
  },
  "office": {
    value: "office",
    text: "Office",
  },
  "shop": {
    value: "shop",
    text: "Shop"
  },
  "industrial": {
    value: "industrial",
    text: "Industrial",
  },
  "carpark": {
    value: "carpark",
    text: "Carpark",
  },
}
