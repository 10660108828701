import React from 'react';
import { connect } from 'react-redux';

import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Icon,
  message,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';

import { withTranslation } from 'react-i18next';

import '@components/LayoutStyle.scss';
import * as FormatterUtils from '@utils/formatter';;
import * as UserActions from '@actions/users';
import UserProfileEditForm from '@components/Profile/UserProfileEditForm';
import UserPasswordUpdateForm from '@components/Profile/UserPasswordUpdateForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isUpdating: false,

    // modals
    isEditing: false,

    ...state,
  }
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onUpdateUserProfile = this.onUpdateUserProfile.bind(this);
    this.onUpdateUserPassword = this.onUpdateUserPassword.bind(this);
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  saveUpdatePasswordFormRef = formRef => {
    this.updatePasswordFormRef = formRef;
  }

  async onUpdateUserProfile() {
    const { form } = this.updateFormRef.props;
    try {
      let values = await form.validateFields();
      this.setState({ isUpdating: true });
      let responseData = await this.props.updateUserProfile(values);
      this.setState({ isUpdating: false, isEditing: false });
      message.success(this.props.t("Profile.messages.UpdateProfile.Success"));
      return responseData;
    } catch (error) {
      this.setState({ isUpdating: false });
      message.error(this.props.t("Profile.messages.UpdateProfile.Fail"));
      return null;
    }
  }

  async onUpdateUserPassword() {
    const { form } = this.updatePasswordFormRef.props;
    let values;
    try {
      values = await form.validateFields();
    } catch (error) {
      return
    }

    try {
      this.setState({ isUpdatingPassword: true });
      let responseData = await this.props.updateUserPassword(values);
      this.setState({ isUpdatingPassword: false, isEditingPassword: false });
      message.success(this.props.t("Profile.messages.UpdatePassword.Success"));
      return responseData;
    } catch (error) {
      this.setState({ isUpdatingPassword: false });
      message.error(error.response.data.message);
      return null;
    }
  }

  renderProfileInfo(me) {
    const details = [{
      label: this.props.t("Profile.Settings.FirstName"),
      render: () => me.first_name,
    }, {
      label: this.props.t("Profile.Settings.LastName"),
      render: () => me.last_name,
    }, {
      label: this.props.t("Profile.Settings.Phone"),
      render: () => FormatterUtils.phoneNumber(me.phone_number),
    }, {
      label: this.props.t("Profile.Settings.Email"),
      render: () => me.email,
    }]
    return (
      <Descriptions
        title={ this.props.t("Profile.Title") }
        bordered
      >
        {
          details.map((item, index) => (
            <Descriptions.Item
              key={ index.toString() }
              label={ <strong>{ item.label }</strong> }
              span={ 3 }
            >
              { item.render() }
            </Descriptions.Item>
          ))
        }
      </Descriptions>
    )
  }

  render() {
    const { me } = this.props;

    return (
      <div className="main-full-view">
        <Row type="flex" align="middle" justify="space-around">
          <Col span={ 4 }>
            <Row type="flex" justify="center">
              <Avatar
                icon="user"
                src={ me.avatar_url }
                size={ 128 }
              />
            </Row>
          </Col>
          <Col span={ 14 }>
            <Row type="flex" justify="space-between">
              <h1>{ me.display_name }</h1>
              <Button
                type="primary" icon="edit"
                onClick={ () => this.setState({ isEditing: true }) }
              >
                { this.props.t("Profile.actions.UpdateProfile") }
              </Button>
              <UserProfileEditForm
                wrappedComponentRef={ this.saveUpdateFormRef }
                visible={ this.state.isEditing }
                confirmLoading={ this.state.isUpdating }
                onCancel={ () => this.setState({ isEditing: false }) }
                onOk={ () => this.onUpdateUserProfile() }
                me={ me }
              />
            </Row>
            { this.renderProfileInfo(me) }
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="space-around" style={{ paddingTop: 30 }}>
          <Col offset={ 7 } span={ 14 }>
            <h3>{ this.props.t("Profile.Preferences") }</h3>
            <table style={{ 'borderCollapse': 'separate', borderSpacing: '0 20px' }}>
              <tbody>
                <tr>
                  <td>
                    <p style={{ display: 'inline', paddingRight: 5, fontSize: 18 }}>
                      { this.props.t("Profile.SubscribeReports") }
                    </p>
                    <Tooltip title={ this.props.t("Profile.SubscribeReportsHelper") }>
                      <Icon type="info-circle" style={{ fontSize: 18 }} />
                    </Tooltip>
                  </td>
                  <td>
                    <Switch
                      style={{ marginLeft: 50 }}
                      loading={ this.state.isUpdating }
                      onChange={ () => this.props.updateUserProfile({
                        subscribe_weekly_reports: `${!me.subscribe_weekly_reports}`,
                      })}
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      checked={ me.subscribe_weekly_reports || false }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ display: 'inline', paddingRight: 5, fontSize: 18 }}>
                      { this.props.t("Profile.Language") }
                    </p>
                    <Tooltip title={ this.props.t("Profile.LanguageHelper") }>
                      <Icon type="info-circle" style={{ fontSize: 18 }} />
                    </Tooltip>
                  </td>
                  <td>
                    <Select
                      style={{ marginLeft: 50, width: 120 }}
                      value={ me.language_preference || "en" }
                      loading={ this.state.isUpdating }
                      onChange={ async (value) => {
                        try {
                          let me = await this.props.updateUserProfile({
                            language_preference: value,
                          })
                          this.props.i18n.changeLanguage(value);
                        } catch (error) {
                          message.error(this.props.t("Profile.messages.UpdateLanguagePreference.Fail"));
                        }
                      }}
                    >
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="zh">中文繁體</Select.Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ marginTop: 40 }}>
              <h3>{ this.props.t("Profile.OtherActions") }</h3>
              <a onClick={ () => this.setState({ isEditingPassword: true }) }>
                { this.props.t("Profile.actions.UpdatePassword") }
              </a>
              <UserPasswordUpdateForm
                wrappedComponentRef={ this.saveUpdatePasswordFormRef }
                visible={ this.state.isEditingPassword }
                confirmLoading={ this.state.isUpdatingPassword }
                onCancel={ () => this.setState({ isEditingPassword: false }) }
                onOk={ this.onUpdateUserPassword }
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.usersReducer.me,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (values) => {
      return dispatch(UserActions.updateUserProfile(values));
    },
    updateUserPassword: (values) => {
      return dispatch(UserActions.updateUserPassword(values));
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Profile));
