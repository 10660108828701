import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
} from "antd";
import moment from "moment";
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import { TaskCategoryOptions } from "@constants/models/Tasks";
import * as OwnershipActions from '@actions/ownerships';
import QuillEditor from '@components/QuillEditor'
import AsyncUpload from '@components/AsyncUpload';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnerships: false,
    ownershipsById: {},
    errorFetchingOwnerships: null,
    fileDataMap: {},
    ...state,
  };
}

class CreateForm extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onCancel = this.onCancel.bind(this);
    this.onOk = this.onOk.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.updateFileDataMap = this.updateFileDataMap.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwnerships: true });
      let params = (this.props.ownershipId) ? { ownership_id: this.props.ownershipId } : {};
      this.props.fetchOwnershipsWithManagers(params).then((responseData) => {
        this.setState({
          isFetchingOwnerships: false,
          ownershipsById: {
            ...ArrayUtils.arrayToObject(responseData.ownerships),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnerships: false,
          errorFetchingOwnerships: error,
        })
      });
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onCancel() {
    this.props.onCancel();
  }

  onOk() {
    this.props.form.setFieldsValue({
      files: Object.values(this.state.fileDataMap).map((fileMetaData) => fileMetaData.blobSignedId),
    });
    this.props.onOk();
  }

  handleChangeQuill(key, value) {
    this.props.form.setFieldsValue({
      [key]: value,
    })
  }

  updateFileDataMap(fileDataMap) {
    this.setState({ fileDataMap });
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
            {getFieldDecorator("ownership_id", {
              rules: [
                { required: true, message: `${ this.props.t("Properties.messages.Form.PleaseSelectProperty") }!` },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                placeholder={ this.props.t("Properties.messages.Form.PleaseSelectProperty") }
                loading={ this.state.isFetchingOwnerships }
                showSearch={ (Object.values(this.state.ownershipsById).length > 5) }
                filterOption={ (inputValue, option) => {
                  const ownershipId = parseInt(option.key);
                  const ownership = this.state.ownershipsById[ownershipId];
                  if (ownership.name.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  if (ownership.address.toLowerCase().includes(inputValue.toLowerCase())) {
                    return true;
                  }
                  return false;
                }}
                notFoundContent={
                  this.state.isFetchingOwnerships ? <Spin size="small" /> : null
                }
                onSelect={ (key) => {
                  const ownerships = Object.values(this.state.ownershipsById).filter((ownership) => {
                    return ownership.id.toString() === key;
                  });
                  if (ownerships.length !== 1) {
                    return null;
                  }
                  this.setState({ ownershipManagers: ownerships[0].ownership_managers })
                  this.props.form.setFieldsValue({ assigned_to_user_id: undefined })
                }}
              >
                {
                  Object.values(this.state.ownershipsById).map((ownership) => (
                    <Select.Option
                      key={ ownership.id.toString() }
                    >
                      { ownership.name }. { ownership.address }
                    </Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.TaskName") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
          >
          {getFieldDecorator("name", {
            initialValue: null,
            rules: [{ required: true, message: `${ this.props.t("Tasks.messages.Form.PleaseInputTaskName") }` }]
          })(<Input placeholder={ this.props.t("Tasks.TaskName") } />)}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.Category") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("category", {
            rules: [
              { required: true, message: this.props.t("Tasks.messages.Form.PleaseSelectCategory") }
            ]
          })(
            <Select
              style={{ width: "100%" }}
              placeholder={ this.props.t("Tasks.messages.Form.PleaseSelectCategory") }
            >
              {Object.values(TaskCategoryOptions).map(taskCategory => (
                <Select.Option key={taskCategory.value}>
                  {taskCategory.text}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.AssignedTo") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("assigned_to_user_id", {
          })(
            <Select
              style={{ width: "100%" }}
              placeholder={ this.props.t("Tasks.messages.Form.PleaseSelectAssignee") }
              loading={ this.state.isFetchingOwnerships }
              notFoundContent={
                this.state.isFetchingOwnerships ? <Spin size="small" /> : <span>{ this.props.t("Properties.messages.Form.PleaseSelectProperty") }</span>
              }
            >
              {(this.state.ownershipManagers || []).map(manager => (
                <Select.Option key={manager.user_id}>
                  {manager.display_name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.MyProptyAssistance") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("assign_to_mypropty", {
            valuePropName: "checked",
            initialValue: false,
          })(<Checkbox>Request Support</Checkbox>)}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Description") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("description", {
            initialValue: null
          })(<Input.TextArea style={{ display: 'none' }} />)}
          <QuillEditor
            value={ this.props.form.getFieldValue("description") }
            onChange={ (value) => this.handleChangeQuill("description", value) }
          />
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.Deadline") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("deadline")(<DatePicker format={"YYYY/MM/DD"} />)}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Tasks.Attachments") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          <div className="dropbox">
            {getFieldDecorator("files", {
              getValueFromEvent: this.normFile,
              rules: [
                { required: false, message: `${ this.props.t("Tasks.messages.PleaseSelectAttachments") }!` },
              ],
            })(
              <AsyncUpload
                name="files"
                multiple={ true }
                fileDataMap={ this.state.fileDataMap }
                updateFileDataMap={ this.updateFileDataMap }
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </AsyncUpload>
            )}
          </div>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { visible, confirmLoading } = this.props;

    return (
      <Modal
        width="80%"
        visible={visible}
        title={ this.props.t("Tasks.actions.CreateTask") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.onCancel }
        onOk={ this.onOk }
        confirmLoading={confirmLoading}
      >
        { this.renderForm() }
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipsWithManagers: (params) => {
      return (dispatch(OwnershipActions.fetchOwnershipsWithManagers(params)));
    },
  };
}

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

const WrappedCreateForm = Form.create({
  name: "tasks_creation_form_in_modal"
})(ConnectedCreateForm);

export default withTranslation('common')(WrappedCreateForm);
