import * as ActionTypes from '@actions/types';

export const initialState = {
  // id
  // name
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
}
