// NOTE: This is mostly a duplicate of the EditableTable defined in antd docs
// diff:
// pagination { onChange: this.cancel } => { false }
// EditableCell - can allow for number/currency/else
// operation/Operation => action/Action
// columns are now defined using props.columns
// age => amount (for the cell inputType)

import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
} from 'antd';

import { withTranslation } from 'react-i18next';

const NEW_ITEM_KEY = 'new_';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    if (this.props.inputType === 'currency') {
      return (
        <InputNumber
          style={{ width: '100%', minWidth: '100px' }}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      )
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  static propTypes = {
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    onSaveRecord: PropTypes.func.isRequired,
    onDeleteRecord: PropTypes.func.isRequired,
    canAdd: PropTypes.bool,
    title: PropTypes.node,
  }
  static defaultProps = {
    dataSource: [],
    columns: [],
    onSaveRecord: (key, row) => {},
    onDeleteRecord: (key) => {},
    canAdd: false,
    title: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      editingKey: null,
      isCreatingLineItem: false,
    };

    this.columns = [...props.columns, {
      title: this.props.t("shared.Action"),
      dataIndex: 'action',
      render: (text, record) => {
        const { editingKey } = this.state;
        const editable = this.isEditing(record);
        return editable ? (
          <span>
            <EditableContext.Consumer>
              {form => (
                <a
                  onClick={() => this.save(form, record.id)}
                  style={{ marginRight: 8 }}
                >
                  Save
                </a>
              )}
            </EditableContext.Consumer>
            <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <a
              disabled={editingKey !== null}
              onClick={() => this.edit(record.id)}
              style={{ marginRight: 8 }}
            >
              Edit
            </a>
            <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(record.id)}>
              <a
                disabled={editingKey !== null}
              >
                Delete
              </a>
            </Popconfirm>
          </span>
        );
      },
    }];
  }

  isEditing = record => record.id === this.state.editingKey;

  cancel = (key) => {
    if (key === NEW_ITEM_KEY) {
      this.setState({
        isCreatingLineItem: false,
      });
    }
    this.setState({ editingKey: null });
  };

  async save(form, key) {
    let row;
    try {
      row = await form.validateFields();
    } catch(error) {
      return
    }
    try {
      const responseData = await this.props.onSaveRecord(key, row);
      this.setState({
        editingKey: null,
        isCreatingLineItem: false,
      });
    } catch(error) {
      return
    }
  }

  async delete(key) {
    try {
      const responseData = await this.props.onDeleteRecord(key);
    } catch(error) {
      return
    }
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    let newItem = {}
    columns.forEach((col) => newItem[col.key] = '');
    newItem['id'] = NEW_ITEM_KEY;

    const editingItems = (this.state.isCreatingLineItem) ? [newItem] : [];
    const dataSource = [
      ...this.props.dataSource,
      ...editingItems,
    ]
    return (
      <div>
        { this.props.title }
        {
          this.props.canAdd ? (
            <Button
              type="primary" icon="plus"
              onClick={ () => this.setState({ isCreatingLineItem: true, editingKey: newItem['id'] }) }
              disabled={ this.state.isCreatingLineItem }
            >
              { this.props.t("actions.Add") }
            </Button>
          ) : null
        }
        <EditableContext.Provider value={this.props.form}>
          <Table
            rowKey={ (item) => item.id.toString() }
            components={components}
            bordered
            dataSource={ dataSource }
            columns={ columns }
            rowClassName="editable-row"
            loading={ this.props.loading }
            pagination={ false }
          />
        </EditableContext.Provider>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);
export default withTranslation('common')(EditableFormTable);
