import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchTasks = (params = {}) => {
  return Api.get(
    'tasks',
    ActionTypes.FETCH_TASKS,
    params,
  )
}

export const createTask = (ownershipId, task = {}) => {
  return Api.postUpload(
    'tasks',
    ActionTypes.CREATE_TASK,
    {
      ownership_id: ownershipId,
      ...task
    },{
      ownershipId,
    }
  )
}

export const updateTask = (taskId, ownershipId, params = {}) => {
  return Api.putUpload(
    `tasks/${taskId}`,
    ActionTypes.UPDATE_TASK,
    params,
    {
      taskId,
      ownershipId,
    }
  )
}

export const destroyTask = (taskId, params = {}) => {
  return Api.del(
    `tasks/${taskId}`,
    ActionTypes.DESTROY_TASK,
    params,
    {}
  )
}

export const deleteTaskAttachment = (taskId, ownershipId, attachmentId) => {
  return Api.del(
    `tasks/${taskId}/delete_attachment`,
    ActionTypes.DELETE_TASK_ATTACHMENT,
    {
      attachment_id: attachmentId,
    },{
      taskId,
      ownershipId,
    }
  )
}
