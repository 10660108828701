import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Spin,
  Upload,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as OwnershipActions from '@actions/ownerships';

const RESET_STATE = (state = {}) => {
  return {
    isFetchingOwnerships: false,
    ownershipsById: {},
    errorFetchingOwnerships: null,
    fileList: [],
    ...state,
  };
}

class CreateForm extends React.Component {
  static propTypes = {
    ownershipId: PropTypes.number,
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    // form: PropTypes.
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.onCancel = this.onCancel.bind(this);
    this.onOk = this.onOk.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      this.setState(RESET_STATE());
      return
    }
    if (!prevProps.visible && this.props.visible) {
      this.setState({ isFetchingOwnerships: true });
      let params = (this.props.ownershipId) ? { ownership_id: this.props.ownershipId } : {};
      this.props.fetchOwnershipsWithManagers(params).then((responseData) => {
        this.setState({
          isFetchingOwnerships: false,
          ownershipsById: {
            ...ArrayUtils.arrayToObject(responseData.ownerships),
          },
        });
      }).catch((error) => {
        this.setState({
          isFetchingOwnerships: false,
          errorFetchingOwnerships: error,
        })
      });
    }
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  onCancel() {
    this.props.onCancel();
  }

  async onOk() {
    const { fileList } = this.state;
    this.props.form.setFieldsValue({
      file: (fileList.length > 0) ? fileList[0] : null,
    })
    let isFormValid = await this.props.onOk();
    if (isFormValid && isFormValid.response) {
      this.setState({
        fileList: [],
      });
    }
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { ownershipId }  = this.props;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 17, offset: 1 },
    };

    return (
      <Form layout="vertical">
        <Form.Item
          label={ this.props.t("Properties.Property") }
          style={{ margin: 0, paddingBottom: 14 }}
          {...formItemLayout}
        >
          {getFieldDecorator("ownership_id", {
            rules: [{
              required: true,
              message: `${ this.props.t("Properties.messages.Form.PleaseSelectProperty") }!`,
            }],
          })(
            <Select
              style={{ width: "100%" }}
              placeholder={ this.props.t("Properties.messages.Form.PleaseSelectProperty") }
              loading={ this.state.isFetchingOwnerships }
              showSearch={ (Object.values(this.state.ownershipsById).length > 5) }
              filterOption={ (inputValue, option) => {
                const ownershipId = parseInt(option.key);
                const ownership = this.state.ownershipsById[ownershipId];
                if (ownership.name.toLowerCase().includes(inputValue.toLowerCase())) {
                  return true;
                }
                if (ownership.address.toLowerCase().includes(inputValue.toLowerCase())) {
                  return true;
                }
                return false;
              }}
              notFoundContent={
                this.state.isFetchingOwnerships ? <Spin size="small" /> : null
              }
            >
              {
                Object.values(this.state.ownershipsById).map((ownership) => (
                  <Select.Option
                    key={ ownership.id.toString() }
                  >
                    { ownership.name }. { ownership.address }
                  </Select.Option>
                ))
              }
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentType") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('doc_type', {
            rules: [{
              required: true,
              message: `${ this.props.t("Documents.messages.Form.PleaseSelectDocumentType") }!`,
            }],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={ this.props.t("Documents.messages.Form.PleaseSelectDocumentType") }
            >
              {
                Object.values(this.props.DocumentTypeOptions).sort((a, b) => (a.text.localeCompare(b.text))).map((docTypeOption) => (
                  <Select.Option
                    key={ docTypeOption.value }
                  >
                    { docTypeOption.text }
                  </Select.Option>
                ))
              }
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentName") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('doc_name', {
            rules: [{
              required: false,
              message: `${ this.props.t("Documents.messages.Form.PleaseInputDocumentName") }!`,
            }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.DocumentDate") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('date', {
            rules: [{
              required: false,
              message: `${ this.props.t("Documents.messages.Form.PleaseSelectDocumentDate") }!`,
            }],
          })(
            <DatePicker format={"YYYY/MM/DD"} />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("shared.Description") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          {getFieldDecorator('description', {
            rules: [
            ],
          })(
            <Input.TextArea rows={ 4 } placeholder={ this.props.t("shared.Description") } />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Documents.Attachment") }
          style={{ margin: 0, paddingBottom: 14 }}
          { ...formItemLayout }
        >
          <div className="dropbox">
            {getFieldDecorator('file', {
              getValueFromEvent: this.normFile,
              rules: [{
                required: true,
                message: `${ this.props.t("Documents.messages.Form.PleaseSelectDocument") }!`,
              }],
            })(
              <Upload
                multiple={ false }
                onRemove={
                  (file) => {
                    this.setState({ fileList: [] });
                  }
                }
                beforeUpload={
                  (file) => {
                    this.setState({
                      fileList: [file],
                    });
                    return false;
                  }
                }
                fileList={ this.state.fileList }
                name="file"
              >
                <Button>
                  <Icon type="upload" /> { this.props.t("actions.ClickToUpload") }
                </Button>
              </Upload>
            )}
          </div>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { visible, confirmLoading } = this.props;

    return (
      <Modal
        visible={ visible }
        title={ this.props.t("Documents.actions.AddDocument") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ this.onCancel }
        onOk={ this.onOk }
        confirmLoading={ confirmLoading }
      >
        { this.renderForm() }
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    DocumentTypeOptions: state.documentsReducer.DocumentTypeOptions,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOwnershipsWithManagers: (params) => {
      return (dispatch(OwnershipActions.fetchOwnershipsWithManagers(params)));
    },
  };
}

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

const WrappedCreateForm = Form.create({
  name: "document_create_form_in_modal"
})(ConnectedCreateForm);

export default withTranslation('common')(WrappedCreateForm);
