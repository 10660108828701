import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  message,
} from 'antd';
import { withTranslation } from 'react-i18next';

import * as ArrayUtils from '@utils/array';
import * as ListingsActions from '@actions/listings';

import { ListingStatusOptions } from '@constants/models/Listing';
import ListingsList from '@components/Listings/List';
import FilterForm from '@components/Listings/FilterForm';
// import BroadcastForm from '@components/Listings/BroadcastForm';
import WhatsappBroadcastForm from '@components/Listings/WhatsappBroadcastForm';
import CreateForm from '@components/Listings/CreateForm';
import UpdateForm from '@components/Listings/UpdateForm';
import CloseListingForm from '@components/Listings/CloseListingForm';

const RESET_STATE = (state = {}) => {
  return {
    // data
    isCreating: false,
    isFetching: false,
    itemsById: {},
    errorCreating: null,
    errorFetching: null,

    // modal
    selectedItemId: null,
    broadcastModalVisible: false,
    updateModalVisible: false,
    closeListingModalVisible: false,
    createModalVisible: false,

    // search params
    filteredInfo: {
      status: ListingStatusOptions.available.value,
    },
    ...state,
  }
}

class Listings extends React.Component {
  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchListings = this.fetchListings.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.onBroadcastSubmit = this.onBroadcastSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onCloseListingSubmit = this.onCloseListingSubmit.bind(this);
    this.handleFilterFormSubmit = this.handleFilterFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchListings();
  }

  async fetchListings(params = {}) {
    this.setState({ isFetching: true });
    try {
      let responseData = await this.props.fetchListings({
        filter: this.state.filteredInfo,
        ...params,
      });
      this.setState({
        isFetching: false,
        itemsById: {
          ...ArrayUtils.arrayToObject(responseData.listings),
        },
        errorFetching: null,
      })
      return responseData;
    } catch(error) {
      this.setState({
        isFetching: false,
        itemsById: {},
        errorFetching: error,
      })
      return
    }
  }

  handleFilterFormSubmit(filteredInfo) {
    this.setState({
      filteredInfo,
    }, this.fetchListings);
  }

  async onCreateSubmit() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      const ownershipId = this.props.ownershipId ? this.props.ownershipId : values["ownership_id"];
      if (!ownershipId) {
        return
      }
      this.setState({ isCreating: true });
      let responseData = await this.props.createListing(ownershipId, values);
      const newListing = responseData.listing;
      this.setState({
        isCreating: false,
        createModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [newListing.id]: newListing,
        },
      });
      message.success(this.props.t("Listings.messages.CreateListing.Success"));
      return
    } catch(error) {
      this.setState({
        isCreating: false,
        errorCreating: error,
      });
      return
    }
  }

  getSelectedListing() {
    return this.state.selectedItemId ? this.state.itemsById[this.state.selectedItemId] : null;
  }

  async onBroadcastSubmit(selectedPropertyAgentIds, remarksOption) {
    if (selectedPropertyAgentIds.length === 0) {
      return
    }
    const { form } = this.broadcastFormRef.props;
    let selectedListing = this.getSelectedListing();
    if (!selectedListing) {
      return
    }
    try {
      let values = await form.validateFields();
      values = {
        property_agent_ids: selectedPropertyAgentIds,
        remarks_option: remarksOption,
      }
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isBroadcasting: true,
          },
        },
      });
      let responseData = await this.props.broadcastListing(selectedListing.id, values);
      const updatedListing = responseData.listing;
      this.setState({
        selectedItemId: null,
        broadcastModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedListing.id]: updatedListing,
        },
      });
      message.success(this.props.t("Listings.messages.BroadcastListing.Success"));
      return
    } catch(error) {
      selectedListing = this.getSelectedListing();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isBroadcasting: false,
            errorBroadcasting: error,
          },
        },
      })
      message.error(this.props.t("Listings.messages.BroadcastListing.Fail"));
      return
    }
  }

  async onUpdateSubmit() {
    const { form } = this.updateFormRef.props;
    let selectedListing = this.getSelectedListing();
    if (!selectedListing) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.updateListing(selectedListing.id, values);
      const updatedListing = responseData.listing;
      this.setState({
        selectedItemId: null,
        updateModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedListing.id]: updatedListing,
        },
      });
      message.success(this.props.t("Listings.messages.UpdateListing.Success"));
      return
    } catch(error) {
      selectedListing = this.getSelectedListing();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      message.error(this.props.t("Listings.messages.UpdateListing.Fail"));
      return
    }
  }

  async onCloseListingSubmit() {
    const { form } = this.closeListingFormRef.props;
    let selectedListing = this.getSelectedListing();
    if (!selectedListing) {
      return
    }
    try {
      let values = await form.validateFields();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isUpdating: true,
          },
        },
      });
      let responseData = await this.props.closeListing(selectedListing.id, values);
      const updatedListing = responseData.listing;
      this.setState({
        selectedItemId: null,
        closeListingModalVisible: false,
        itemsById: {
          ...this.state.itemsById,
          [updatedListing.id]: updatedListing,
        },
      });
      message.success(this.props.t("Listings.messages.CloseListing.Success"));
      return
    } catch(error) {
      selectedListing = this.getSelectedListing();
      this.setState({
        itemsById: {
          ...this.state.itemsById,
          [selectedListing.id]: {
            ...selectedListing,
            isUpdating: false,
            errorUpdating: error,
          },
        },
      })
      message.error(this.props.t("Listings.messages.CloseListing.Fail"));
      return
    }
  }

  saveBroadcastFormRef = formRef => {
    this.broadcastFormRef = formRef;
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  saveUpdateFormRef = formRef => {
    this.updateFormRef = formRef;
  }

  saveCloseListingFormRef = formRef => {
    this.closeListingFormRef = formRef;
  }

  render() {
    const selectedListing = this.getSelectedListing();
    return (
      <div>
        <WhatsappBroadcastForm
          wrappedComponentRef={ this.saveBroadcastFormRef }
          visible={ !!selectedListing && this.state.broadcastModalVisible }
          listing={ selectedListing }
          onCancel={ () => this.setState({ selectedItemId: null, broadcastModalVisible: false }) }
          onOk={ this.onBroadcastSubmit }
          confirmLoading={ selectedListing && selectedListing.isBroadcasting }
        />
        <CreateForm
          wrappedComponentRef={ this.saveCreateFormRef }
          visible={ this.state.createModalVisible }
          onCancel={ () => this.setState({ createModalVisible: false })}
          onOk={ this.onCreateSubmit }
          confirmLoading={ this.state.isCreating }
        />
        <UpdateForm
          wrappedComponentRef={ this.saveUpdateFormRef }
          visible={ !!selectedListing && this.state.updateModalVisible }
          listing={ selectedListing }
          onCancel={ () => this.setState({ selectedItemId: null, updateModalVisible: false }) }
          onOk={ this.onUpdateSubmit }
          confirmLoading={ selectedListing && selectedListing.isUpdating }
        />
        <CloseListingForm
          wrappedComponentRef={ this.saveCloseListingFormRef }
          visible={ !!selectedListing && this.state.closeListingModalVisible }
          listing={ selectedListing }
          onCancel={ () => this.setState({ selectedItemId: null, closeListingModalVisible: false }) }
          onOk={ this.onCloseListingSubmit }
          confirmLoading={ selectedListing && selectedListing.isUpdating }
        />
        <FilterForm
          filteredInfo={ this.state.filteredInfo }
          loading={ this.state.isFetching }
          onSubmit={ this.handleFilterFormSubmit }
        />
        <Button
          type="primary" icon="plus"
          style={{ marginBottom: 14 }}
          onClick={ () => this.setState({ createModalVisible: true })}
        >
          { this.props.t("Listings.actions.CreateListing") }
        </Button>
        <ListingsList
          loading={ this.state.isFetching }
          dataSource={ Object.values(this.state.itemsById) }
          onClickBroadcast={ (record) => {
            this.setState({
              createModalVisible: false,
              selectedItemId: record.id,
              broadcastModalVisible: true,
            })
          }}
          onClickUpdate={ (record) => {
            this.setState({
              selectedItemId: record.id,
              updateModalVisible: true,
            })
          }}
          onClickCloseListing={ (record) => {
            this.setState({
              selectedItemId: record.id,
              closeListingModalVisible: true,
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchListings: (params = {}) => {
      return dispatch(ListingsActions.fetchListings(params))
    },
    createListing: (ownershipId, params = {}) => {
      const { images, available_date, ...rest } = params;
      return dispatch(ListingsActions.createListing(ownershipId, {
        ...rest,
        available_date: available_date ? available_date.format('YYYY-MM-DD') : null,
        'images[]': images,
      }));
    },
    broadcastListing: (listingId, params = {}) => {
      return dispatch(ListingsActions.broadcastListing(listingId, params));
    },
    updateListing: (listingId, params = {}) => {
      const { images, available_date, ...rest } = params;
      return dispatch(ListingsActions.updateListing(listingId, {
        ...rest,
        available_date: available_date ? available_date.format('YYYY-MM-DD') : null,
        'images[]': images,
      }));
    },
    closeListing: (listingId, params = {}) => {
      const { closed_at, ...rest } = params;
      return dispatch(ListingsActions.closeListing(listingId, {
        ...rest,
        closed_at: closed_at ? closed_at.format('YYYY-MM-DD') : null,
      }));
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Listings));
