import { DirectUpload } from "@rails/activestorage"

class Uploader {
  constructor({ file, onError, onSuccess, onProgress }) {
    this.file = file;
    this.onError = onError;
    this.onSuccess = onSuccess;
    this.onProgress = onProgress;
    this.upload = new DirectUpload(this.file, '/rails/active_storage/direct_uploads', this)
  }

  uploadFile() {
    this.upload.create((error, blob) => {
      if (error) {
        this.onError(error)
      } else {
        this.onSuccess("ok", this.file, blob);
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.onProgress(event, this.file);
  }
}

export default Uploader;
