import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';

import qs from 'qs';
import { withTranslation } from 'react-i18next';

import * as PortfolioActions from '@actions/portfolio';
import * as DateUtils from '@utils/date';
import DownloadTenanciesModal from '@components/Portfolio/DownloadTenanciesModal';

const RESET_STATE = (state = {}) => {
  return {
    // datasource
    isFetching: false,
    data: [],
    error: null,

    // form
    time_ahead: "2",

    downloadModalVisible: false,

    ...state,
  }
}

class OwnershipWithTransitionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = RESET_STATE();
    this.fetchOwnershipsWithTransition = this.fetchOwnershipsWithTransition.bind(this);
    this.handleChangeTimePeriod = this.handleChangeTimePeriod.bind(this);
  }

  componentDidMount() {
    this.fetchOwnershipsWithTransition();
  }

  handleChangeTimePeriod(value) {
    this.setState({
      time_ahead: value,
    }, this.fetchOwnershipsWithTransition)
  }

  async fetchOwnershipsWithTransition(params = {}) {
    params = {
      ...params,
      time_ahead: this.state.time_ahead,
    };
    try {
      this.setState({ isFetching: true });
      let responseData = await this.props.fetchOwnershipsWithTransition(params);
      this.setState({
        isFetching: false,
        data: responseData.transition_ownerships,
      });
      return responseData;
    } catch (error) {
      this.setState({
        isFetching: false,
        error,
      });
      return
    }
  }

  renderTable() {
    if (this.state.data.length === 0) {
      if (this.state.isFetching) {
        return (
          <Row type="flex" justify="center">
            <Spin size="large" />
          </Row>
        )
      }
      return (
        <Row type="flex" justify="center">
          { this.props.t("Portfolio.TenancyTransition.NoVacantExpiringTenancies") }
        </Row>
      )
    }

    let columns = [{
      title: this.props.t("Properties.Property"),
      dataIndex: 'name',
      key: 'name',
      render: (name, record)  => (
        <Link to={ `/properties/${record.id}` }>
          { name }
        </Link>
      ),
    }, {
      title: this.props.t("Properties.Address"),
      dataIndex: 'address',
      key: 'address',
    }, {
      title: this.props.t("Portfolio.TenancyTransition.CurrentEndDate"),
      dataIndex: 'expiring_tenancy_end_date',
      key: 'expiring_tenancy_end_date',
      render: (value) => {
        if (!value) {
          return null;
        }
        return DateUtils.formatDate(value);
      },
    }, {
      title: this.props.t("Portfolio.TenancyTransition.NextStartDate"),
      dataIndex: 'next_tenancy_start_date',
      key: 'next_tenancy_start_date',
      render: (value) => {
        if (!value) {
          return null;
        }
        return DateUtils.formatDate(value);
      },
    }];

    return (
      <Table
        rowKey="id"
        columns={ columns }
        loading={ this.state.isFetching }
        dataSource={ this.state.data }
      />
    )
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div>
            <h3 style={{ display: 'inline-flex', marginRight: 20 }}>
              { this.props.t("Portfolio.TenancyTransition.VacantExpiringTenanciesIn") }
            </h3>
            <Select
              value={ this.state.time_ahead }
              style={{ width: 120 }}
              onChange={ (value) => this.handleChangeTimePeriod(value) }
            >
              {
                ["2","3","4","5"].map((item, index) => (
                  <Select.Option key={ item }>
                    { item } { this.props.t("shared.months") }
                  </Select.Option>
                ))
              }
            </Select>
            <Button
              icon="download"
              style={{ marginLeft: 8 }}
              onClick={ () => this.setState({ downloadModalVisible: true }) }
            >
              { this.props.t("actions.DownloadResults") }
            </Button>
          </div>
          <Button
            icon="download"
            type="link"
            onClick={ () => {
              const downloadQs = qs.stringify({
                fieldsToExport: ['owner_name'],
              }, { arrayFormat: 'brackets' });
              const downloadAllTenanciesUrl = `/tenancies.xlsx?${downloadQs}`;
              window.open(downloadAllTenanciesUrl, '_blank')
            }}
          >
            { this.props.t("actions.DownloadAll") }
          </Button>
        </div>
        <DownloadTenanciesModal
          visible={ this.state.downloadModalVisible }
          title={ `Download Tenancies` }
          onCancel={ () => this.setState({ downloadModalVisible: false }) }
          onOk={ (values) => {
            const downloadQs = qs.stringify({
              time_ahead: this.state.time_ahead,
              fieldsToExport: Object.values(values).flat(),
            }, { arrayFormat: 'brackets' });
            const downloadAllUrl = `/portfolio/ownerships_with_transition_tenancy.xlsx?${downloadQs}`;
            window.open(downloadAllUrl, '_blank')
            this.setState({ downloadModalVisible: false })
          }}
        />
        { this.renderTable() }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOwnershipsWithTransition: (params = {}) => {
      return dispatch(PortfolioActions.fetchOwnershipsWithTransition(params));
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OwnershipWithTransitionContainer));
