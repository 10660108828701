import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tag,
} from 'antd';

import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';

import '@components/LayoutStyle.scss';
import '@components/FilterForm.scss';
import {
  ThemeColors,
  LayoutStyle,
} from '@constants/Theme';
import {
  OwnershipStatusOptions,
} from '@constants/models/Ownership';
import * as ArrayUtils from '@utils/array';
import * as OwnershipUtils from '@utils/ownership';
import * as OwnershipsActions from '@actions/ownerships';
import * as OwnersActions from '@actions/owners';
import OwnershipCard from '@components/OwnershipCard';
import OwnershipCreateForm from '@components/Ownerships/CreateForm';
import OwnershipDetail from '@components/OwnershipDetail';

const calculateStatus = (alerts) => {
  let statusOption = OwnershipStatusOptions.ok;
  if (!alerts) {
    return statusOption;
  }
  if (alerts.some((alert) => alert.type === 'error')) {
    statusOption = OwnershipStatusOptions.error;
  } else if (alerts.some((alert) => alert.type === 'warning')) {
    statusOption = OwnershipStatusOptions.warning;
  }
  return statusOption;
}

class Properties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateOwnershipForm: false,
      filteredInfo: {
        name: '',
        address: '',
        selectedStatusOptions: [],
        selectedOwners: [],
      },
      owners: {
        isFetching: false,
        errorFetching: null,
        itemsById: {},
      },
    }
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onCreateOwnership = this.onCreateOwnership.bind(this);
    this.fetchOwners = this.fetchOwners.bind(this);
  }

  componentDidMount() {
    this.props.fetchOwnerships();
    this.fetchOwners();
  }

  async fetchOwners(params = {}) {
    this.setState({
      owners: {
        ...this.state.owners,
        isFetching: true,
      },
    })
    try {
      let responseData = await this.props.fetchOwners({
        ...params,
      });
      this.setState({
        owners: {
          ...this.state.owners,
          isFetching: false,
          errorFetching: null,
          itemsById: {
            ...ArrayUtils.arrayToObject(responseData.owners),
          },
        },
      });
      return responseData;
    } catch (error) {
      this.setState({
        owners: {
          isFetching: false,
          errorFetching: null,
          itemsById: {},
        },
      });
      return
    }
  }

  renderOwnershipDetail() {
    return (
      <Route path="/properties/:ownershipId" render={({match}) => {
        const { ownershipId } = match.params;

        return (
          <OwnershipDetail
            ownershipId={ parseInt(ownershipId) }
            onClose={ () => this.props.history.push("/properties") }
          />
        )
        }}
      />
    )
  }

  getFilteredResults(ownerships) {
    const { filteredInfo } = this.state;
    return ownerships.filter((ownership) => {
      if (OwnershipUtils.isNotDefined(ownership)) {
        return false;
      }
      if (filteredInfo && filteredInfo.name) {
        if (!ownership.name.toLowerCase().includes(filteredInfo.name.toLowerCase())) {
          return false;
        }
      }
      if (filteredInfo && filteredInfo.address) {
        if (!ownership.address.toLowerCase().includes(filteredInfo.address.toLowerCase())) {
          return false;
        }
      }
      if (filteredInfo && filteredInfo.selectedStatusOptions && filteredInfo.selectedStatusOptions.length > 0) {
        const statusOption = calculateStatus(ownership.alerts);
        if (!filteredInfo.selectedStatusOptions.includes(statusOption.value)) {
          return false;
        }
      }
      if (filteredInfo && filteredInfo.selectedOwners && filteredInfo.selectedOwners.length > 0) {
        const owners = filteredInfo.selectedOwners.map((ownerId) => this.state.owners.itemsById[ownerId]);
        const ownershipIds = owners.map((owner) => owner.ownerships.map((ownership) => ownership.id)).flat()
        if (!ownershipIds.includes(ownership.id)) {
          return false;
        }
      }
      return true;
    });
  }

  renderOwnershipsCards(filteredOwnerships) {
    const { filteredInfo } = this.state;
    if ((filteredOwnerships.length === 0) && !this.props.isFetchingOwnershipsData) {
      return (
        <Empty />
      )
    }
    return filteredOwnerships.sort((ownershipA, ownershipB) => {
      return ownershipA.name.localeCompare(ownershipB.name);
    }).map((ownership, index) => {
      const statusOption = calculateStatus(ownership.alerts);
      return (
        <Route
          key={ `${index}` }
          path={`/properties/${ownership.id}`}
          children={({ match }) => (
            <OwnershipCard
              onClick={() => {
                this.props.history.push(`${this.props.match.path}/${ownership.id}`)
              }}
              style={{
                backgroundColor: (match) ? ThemeColors.babyBlue : 'white',
              }}
              title={
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[ (filteredInfo && filteredInfo.name) ? filteredInfo.name : '' ]}
                  autoEscape
                  textToHighlight={ ownership.name }
                />
              }
              status={ <Tag color={ statusOption.color }>{ this.props.t(statusOption.ttextKey) }</Tag> }
              ownership={ ownership }
            />
          )}
          >
        </Route>
      )
    })
  }

  onChangeFilter(key, value) {
    this.setState({
      filteredInfo: {
        ...this.state.filteredInfo,
        [key]: value,
      },
    });
  }

  renderFilterOwnershipsForm() {
    const formItems = [
      {
        label: this.props.t("Properties.Property"),
        span: 6,
        render: () => (
          <Input
            placeholder={ this.props.t("Properties.PropertyName") }
            onChange={ (e) => this.onChangeFilter('name', e.target.value) }
          />
        ),
      }, {
        label: this.props.t("Properties.Address"),
        span: 6,
        render: () => (
          <Input
            placeholder={ this.props.t("Properties.PropertyAddress") }
            onChange={ (e) => this.onChangeFilter('address', e.target.value) }
          />
        ),
      }, {
        label: this.props.t("shared.Status"),
        span: 6,
        render: () => (
          <Select
            mode="multiple"
            style={{ width: '100%', minWidth: '150px' }}
            placeholder={ this.props.t("shared.AllStatuses") }
            value={ this.state.filteredInfo.selectedStatusOptions }
            onChange={ (values) => this.onChangeFilter('selectedStatusOptions', values) }
          >
            {
              Object.values(OwnershipStatusOptions).map((statusOption) => (
                <Select.Option key={ statusOption.value }>
                  { this.props.t(statusOption.ttextKey) }
                </Select.Option>
              ))
            }
          </Select>
        ),
      }, {
        label: this.props.t("Owners.Title"),
        span: 6,
        render: () => (
          <Select
            mode="multiple"
            style={{ width: '100%', minWidth: '200px' }}
            placeholder={ this.props.t("shared.AllOwners") }
            value={ this.state.filteredInfo.selectedOwners }
            onChange={ (values) => this.onChangeFilter('selectedOwners', values) }
            loading={ this.state.owners && this.state.owners.isFetching }
            filterOption={ (inputValue, option) => {
              const ownerId = parseInt(option.key);
              const owner = this.state.owners.itemsById[ownerId];
              return (owner.name.toLowerCase().includes(inputValue.toLowerCase()))
            }}
          >
            {
              Object.values(this.state.owners.itemsById).map((owner) => (
                <Select.Option key={ owner.id }>
                  { owner.name }
                </Select.Option>
              ))
            }
          </Select>
        ),
      }
    ]
    return (
      <Form
        className="ant-advanced-search-form"
        style={{ marginBottom: 10 }}
      >
        <Row
          gutter={ 24 }
          justify="space-between"
        >
          {
            formItems.map((item, index) => (
              <Col span={ item.span || 8 } key={ index.toString() }>
                <Form.Item
                  label={ item.label }
                  style={{ margin: 0 }}
                >
                  { item.render() }
                </Form.Item>
              </Col>
            ))
          }
        </Row>
      </Form>
    )
  }

  async onCreateOwnership() {
    const { form } = this.createFormRef.props;
    try {
      let values = await form.validateFields();
      let responseData = await this.props.createOwnership(values);
      this.setState({ showCreateOwnershipForm: false })
      message.success("Successfully added a new property");
      let ownershipId = responseData.ownership.id;
      this.props.history.push(`/properties/${ownershipId}`);
      return responseData;
    } catch (error) {
      return
    }
  }

  saveCreateFormRef = formRef => {
    this.createFormRef = formRef;
  }

  render() {
    const allOwnerships = Object.values(this.props.ownershipsData);
    const filteredOwnerships = this.getFilteredResults(allOwnerships);

    return (
      <div className="main-full-view">
        { this.renderFilterOwnershipsForm() }
        {
          this.props.isFetchingOwnershipsData && (
            <Row type="flex" justify="center">
              <Spin size="large" />
            </Row>
          )
        }
        {
          this.props.errorFetchingOwnershipsData && (
            <Row>
              <Alert
                type="error"
                message={ "Unable to fetch properties" }
                description={
                  <p>
                    There was an issue retrieving your property data.
                    <a onClick={ () => this.props.fetchOwnerships() }>
                      Try again.
                    </a>
                  </p>
                }
                showIcon
              />
            </Row>
          )
        }
        <div style={{ display: 'flex', position: 'relative', maxHeight: 'calc(100% - 60px)',  }}>
          <div
            style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 60px)', height: 'auto', width: "300px" }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                type="primary" icon="plus"
                onClick={ () => this.setState({ showCreateOwnershipForm: true }) }
              >
                { this.props.t("Properties.actions.AddProperty") }
              </Button>
              <OwnershipCreateForm
                wrappedComponentRef={ this.saveCreateFormRef }
                visible={ this.state.showCreateOwnershipForm }
                confirmLoading={ this.props.isCreatingOwnership }
                onCancel={ () => this.setState({ showCreateOwnershipForm: false }) }
                onOk={ this.onCreateOwnership }
              />
              {
                (filteredOwnerships.length !== allOwnerships.length) && (allOwnerships.length > 1) && (
                  <span>Showing { filteredOwnerships.length } of { allOwnerships.length }</span>
                )
              }
            </div>
            { this.renderOwnershipsCards(filteredOwnerships) }
          </div>
          <div
            span={ 16 }
            style={{ width: 'calc(100% - 300px)', height: '100%', flexGrow: 1, padding: '0 10px 0 20px' }}
          >
            { this.renderOwnershipDetail() }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingOwnershipsData: state.ownershipsReducer.isFetching,
    ownershipsData: state.ownershipsReducer.ownerships,
    errorFetchingOwnershipsData: state.ownershipsReducer.error,
    isCreatingOwnership: state.ownershipsReducer.isCreating,
    errorCreatingOwnership: state.ownershipsReducer.creatingError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOwnerships: () => {
      return dispatch(OwnershipsActions.fetchOwnerships()).catch((error) => {});
    },
    createOwnership: (values = {}) => {
      return dispatch(OwnershipsActions.createOwnership(values));
    },
    fetchOwners: (params = {}) => {
      return dispatch(OwnersActions.fetchOwners(params))
    },
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Properties));
