import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Collapse,
  Spin,
  Tag,
} from 'antd';

import { withTranslation } from 'react-i18next';

import {
  ListingTypeOptions,
} from '@constants/models/Listing';
import ListingShow from '@components/Listings/Show';

class ListingsList extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { onClickBroadcast, onClickUpdate, onClickCloseListing, loading } = this.props;
    if (loading) {
      return (
        <div>
          <Spin size="large" />
        </div>
      )
    }
    return (
      <div>
        <Collapse
          bordered={ false }
          expandIconPosition='right'
        >
          {
            this.props.dataSource.sort((listingA, listingB) => {
              return listingA.ownership.name.localeCompare(listingB.ownership.name);
            }).map((listing, index) => (
              <Collapse.Panel
                key={ index.toString() }
                header={
                  <div>
                    <Tag
                      style={{ marginRight: "10px" }}
                      color={ ListingTypeOptions[listing.listing_type].color }
                    >
                      { this.props.t(ListingTypeOptions[listing.listing_type].ttextKey) }
                    </Tag>
                    <strong>{ listing.ownership.name }.</strong> { listing.ownership.address }
                  </div>
                }
              >
                <ListingShow
                  listing={ listing }
                  onClickBroadcast={ onClickBroadcast }
                  onClickUpdate={ onClickUpdate }
                  onClickCloseListing={ onClickCloseListing }
                />
              </Collapse.Panel>
            ))
          }
        </Collapse>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ListingsList));
