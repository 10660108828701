import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const fetchSummary = (params = {}) => {
  return Api.get(
    'portfolio/summary',
    ActionTypes.FETCH_PORTFOLIO_SUMMARY,
    params,
  );
}

export const fetchNetCashflow = (params = {}) => {
  return Api.get(
    'portfolio/net_cash_flow',
    ActionTypes.FETCH_NET_CASH_FLOW,
    params,
  );
}

export const fetchImportantDates = (params = {}) => {
  return Api.get(
    'portfolio/important_dates',
    ActionTypes.FETCH_PORTFOLIO_IMPORTANT_DATES,
    params,
  )
}

export const fetchOwnershipsWithTransition = (params = {}) => {
  return Api.get(
    `portfolio/ownerships_with_transition_tenancy`,
    ActionTypes.FETCH_OWNERSHIPS_WITH_TRANSITION,
    params,
  );
}

export const fetchPortfolioTeamMembers = (params = {}) => {
  return Api.get(
    `portfolio/team_members`,
    ActionTypes.FETCH_PORTFOLIO_TEAM_MEMBERS,
    params,
  );
}
