import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Input,
  Modal,
  Select,
} from 'antd';
import { withTranslation } from 'react-i18next';

import { OwnershipUsageOptions } from '@constants/models/Ownership';

class CreateForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  }

  static defaultProps = {
    visible: false,
    confirmLoading: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible && !this.props.visible) {
      this.props.form.resetFields();
      return
    }
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical">
        <Form.Item
          hasFeedback
          label={ this.props.t("Properties.PropertyName") }
        >
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input the nickname of the property!' }],
          })(
            <Input
              placeholder="E.g. Centrestage 10C"
            />
          )}
        </Form.Item>
        <Form.Item
          label={ this.props.t("Properties.PropertyType") }
        >
          {getFieldDecorator("usage_type", {
            rules: [
              { required: true, message: "Please select a property type!" }
            ]
          })(
            <Select
              style={{ width: "100%" }}
              placeholder="Residential"
            >
              {Object.values(OwnershipUsageOptions).map(ownershipUsage => (
                <Select.Option key={ownershipUsage.value}>
                  {ownershipUsage.text}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          label={ this.props.t("Properties.Address") }
        >
          {getFieldDecorator('address', {
            validate: [{
              trigger: 'onChange',
              rules: [
                { required: true, message: 'Please input the property address!' },
              ],
            }, {
              trigger: 'onBlur',
              rules: [
                { min: 10, message: 'Please input a valid property address!' },
              ],
            }],
          })(
            <Input.TextArea
              rows={ 5 }
              placeholder={`E.g.
Unit C, 10/F, Tower 1,
Centrestage, 108 Hollywood Road,
Sheung Wan, Hong Kong`}
            />
          )}
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { visible, onCancel, onOk, confirmLoading } = this.props;
    return (
      <Modal
        visible={ visible }
        title={ this.props.t("Properties.actions.AddProperty") }
        okText={ this.props.t("actions.Submit") }
        onCancel={ onCancel }
        onOk={ onOk }
        confirmLoading={ confirmLoading }
      >
      { this.renderForm() }
      </Modal>
    )
  }
}

const WrappedCreateForm = Form.create({ name: 'ownership_create_form_in_modal' })(CreateForm);

export default withTranslation('common')(WrappedCreateForm);
