import moment from 'moment';

export const formatDate = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format('Do MMM YYYY')
}

export const formatDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return "";
  }
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}
