import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Col,
  Form,
  Row,
  Select,
} from 'antd';
import { withTranslation } from 'react-i18next';

import '@components/FilterForm.scss';

class FilterForm extends React.Component {
  static propTypes = {
    filteredInfo: PropTypes.shape({
    }).isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loading: false,
    onSubmit: (filteredInfo) => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: { ...this.props.filteredInfo },
    };
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filteredInfo !== this.props.filteredInfo) {
      this.setState({
        filteredInfo: this.props.filteredInfo,
      });
    }
  }

  isDirty() {
    const keys = Object.keys(this.state.filteredInfo);
    return keys.some((key) => this.props.filteredInfo[key] !== this.state.filteredInfo[key]);
  }

  onChangeFilter(key, value) {
    this.setState({
      filteredInfo: {
        ...this.state.filteredInfo,
        [key]: value,
      }
    });
  }

  onSubmit() {
    this.props.onSubmit(this.state.filteredInfo);
  }

  getFormItems() {
    const items = [{
      label: this.props.t("RepairsData.Tags"),
      shouldRender: true,
      span: 8,
      renderChildren: () => (
        <Select
          mode="multiple"
          placeholder={ this.props.t("RepairsData.SelectTags") }
          value={ this.state.filteredInfo.selectedRepairsDataTags }
          onChange={ (values) => this.onChangeFilter('selectedRepairsDataTags', values) }
        >
          {
            Object.values(this.props.options).map((tagOption) => (
              <Select.Option key={ tagOption.id }>
                { tagOption.name }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }, {
      label: null,
      shouldRender: true,
      span: 8,
      renderChildren: () => (
        <Button
          type="primary"
          icon="search"
          disabled={ !this.isDirty() }
          loading={ this.props.loading }
          onClick={ this.onSubmit }
        >
          { this.props.t("actions.Search")}
        </Button>
      ),
    }];
    return items.filter(({ shouldRender }) => { return shouldRender }).map((item, index) => {
      return (
        <Col span={ item.span || 8 } key={ index.toString() }>
          <Form.Item
            label={ item.label }
            style={{ marginBottom: 0 }}
          >
            { item.renderChildren() }
          </Form.Item>
        </Col>
      )
    });
  }

  render() {
    return (
      <div style={{ margin: 0, padding: '0 0 10px' }}>
        <Form className="ant-advanced-search-form">
          <Row
            gutter={ 24 }
            justify="space-between"
          >
            { this.getFormItems() }
          </Row>
        </Form>
      </div>
    )
  }
}

export default withTranslation('common')(FilterForm);
