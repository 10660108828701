import * as Api from '@actions/api';
import * as ActionTypes from '@actions/types';

export const destroyOwnershipManager = (ownershipManagerId) => {
  return Api.del(
    `ownership_managers/${ownershipManagerId}`,
    ActionTypes.DESTROY_OWNERSHIP_MANAGER,
    {},
    {
      // metaData
    }
  )
}
