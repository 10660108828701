import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '@components/LayoutStyle.scss';
import Listings from '@components/Listings';

class ListingsRoot extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="main-full-view">
        <Listings />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingsRoot);
